import React, { useState } from 'react';
import { View, TouchableOpacity, ScrollView } from "react-native";
import { Button, Checkbox, Text, TextInput } from '../../Components';
import type { MyPlayerProps } from '../../ProfileManager/api/types';
import Colors from '../../constants/colors';
import { WalletApi, WalletHelpers } from '../api';
import type { AccountProps } from '../../types';
const validator = require('us-bank-account-validator');

type ManualACHAddProps = {
    player:MyPlayerProps,
    onAddAccount:(account:AccountProps) => void,
    onCancel:() => void
}
const ManualACHAdd = ({ player, onAddAccount, onCancel }:ManualACHAddProps) => {
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState<string>();
    const [ ach_account, setACHAccount ] = useState({
        account: WalletHelpers.getEmptyACHAccount(),
        ach_details: WalletHelpers.getEmptyACHDetail()
    })
    const [ disclaimer, setDisclaimer ] = useState(false);

    const { account, ach_details } = ach_account;
    const bank_valid = ach_details.bank_name ? true : false;
    const routing_valid = validator.routingNumber(ach_details.routing_number);
    const account_valid = validator.accountNumber(ach_details.account_number);
    const valid = bank_valid && routing_valid && account_valid && disclaimer ? true : false

    const handleAddAccount = async() => {
        if(!valid || loading){ return }
        setLoading(true);
        try {
            const resp = await WalletApi.addACHAccount(account, ach_details)
            setACHAccount({ account: resp.account, ach_details: resp.ach_detail })
            onAddAccount({ ...resp.account, ach_detail: resp.ach_detail })
        } catch (e) {
            setError('Sorry, we were not able to add this account.  Please try again later.')
        }
        
        setLoading(false);
    }

    return (
        <View style={{ flex:1 }}>
            <View style={{ padding:20 }}>
                <Text size={26} color={Colors.brand.midnight} weight='bold'>MANUAL ACH ACCOUNT</Text>
                <Text style={{ marginTop:10 }} size={16} color={Colors.brand.midnight} weight='regular'>Complete the form below to add a new ACH account.  Once submitted, we will send 2 small deposits to the account to confirm ownership. This process can take up to 3 business days.</Text>
            </View>
            <ScrollView style={{ flex:1, padding:20 }}>
                <View style={{ marginTop:10 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='semibold'>Name on account</Text>
                    <TextInput 
                        style={{ marginTop:10 }}
                        value={`${player.first_name} ${player.last_name}`} 
                        editable={false}
                        onChangeText={() => console.log('ehy')}
                    />
                </View>
                <View style={{ marginTop: 16 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='semibold'>Bank Name</Text>
                    <TextInput 
                        style={{ marginTop:10 }}
                        keyboardType='default' 
                        placeholderTextColor={Colors.shades.shade600}
                        placeholder={'Bank Name'} 
                        value={ach_details.bank_name}
                        autoFocus={true} 
                        onChangeText={(e) => setACHAccount({ ...ach_account, ach_details: { ...ach_details, bank_name: e } })}
                    />
                    {!bank_valid ?
                    <Text style={{ marginTop:5 }} size={14} color={Colors.utility.warning}>Bank Name Is Not Valid</Text>
                    :<></>}
                </View>
                <View style={{ marginTop: 16 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='semibold'>Routing Number</Text>
                    <TextInput 
                        style={{ marginTop:10 }}
                        placeholderTextColor={Colors.shades.shade600}
                        keyboardType='number-pad'
                        placeholder={'xxxxxx911'}
                        value={ach_details.routing_number}
                        onChangeText={(e) => setACHAccount({ ...ach_account, ach_details: { ...ach_details, routing_number: e } })}
                    />
                    {!routing_valid.isValid ?
                    <Text style={{ marginTop:5 }} size={14} color={Colors.utility.warning}>Routing Number Is Not Valid</Text>
                    :<></>}
                </View>
                <View style={{ marginTop:16 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='semibold'>Account Number</Text>
                    <TextInput 
                        style={{ marginTop:10 }}
                        placeholderTextColor={Colors.shades.shade600}
                        keyboardType='number-pad'
                        value={ach_details.account_number}
                        placeholder={'xxxxxxxxx0000'}
                        onChangeText={(e) => setACHAccount({ ...ach_account, ach_details: { ...ach_details, account_number: e } })}
                    />
                    {!account_valid.isValid ?
                    <Text style={{ marginTop:5 }} size={14} color={Colors.utility.warning}>Account Number Is Not Valid</Text>
                    :<></>}
                </View>
            </ScrollView>
            <View>
                <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', paddingLeft:20, paddingRight:20 }} onPress={() => setDisclaimer(!disclaimer)}>
                    <Checkbox size={30} checked={disclaimer} disabled onSelect={() => console.log('')}/>
                    <Text style={{ marginLeft:10 }} size={14} color={Colors.brand.midnight}>I understand that by continuing, we will send 2 small deposits to this bank account to confirm ownership. Once received, I will enter the amounts here.</Text>
                </TouchableOpacity>
            </View>
            {error?
            <View style={{ padding:10 }}>
                <Text size={16} color={Colors.utility.warning} textAlign='center' weight='semibold'>{error}</Text>
            </View>
            :<></>}
            {account.status == 'pending' ?
            <View style={{ flexDirection:'row', padding:20 }}>
                <Button
                    title='CANCEL'
                    style={{ flex:1, marginRight:5, opacity:loading?0.5:1 }}
                    disabled={loading}
                    title_color={Colors.brand.electric}
                    padding={15}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    onPress={() => onCancel()}
                />
                <Button
                    title='ADD ACCOUNT'
                    style={{ flex:3, opacity:valid&&!loading&&!error?1:0.5 }}
                    disabled={!valid || loading || error?true:false}
                    loading={loading}
                    title_color={Colors.shades.white}
                    padding={15}
                    backgroundColor={Colors.utility.success}
                    onPress={() => handleAddAccount()}
                />
            </View>
            : account.status == 'verify' ?
            <View style={{ flexDirection:'row', padding:20 }}>
                <Button
                    title='CLOSE'
                    style={{ flex:1, marginRight:5 }}
                    title_color={Colors.brand.electric}
                    padding={15}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    onPress={() => onCancel()}
                />
                <Button
                    title='VERIFY TRANSACTIONS SENT'
                    style={{ flex:3 }}
                    disabled
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.brand.slate}
                    padding={15}
                    onPress={() => console.log('')}
                />
            </View>
            :<></>}
        </View>
    )
}

export default ManualACHAdd