import React from 'react';
import { PayPalButtons } from "@paypal/react-paypal-js";
import { View } from "react-native";
import type { AccountProps, ItemOrderProps, ItemProps, TransactionProps } from '../../types';
import { CheckoutApi, CheckoutHelpers } from '../api';

type PayPalOrderCaptureProps = {
    paypal_account:AccountProps,
    items:ItemProps[],
    region?:string,
    coordinates?:any,
    draft_item_order:ItemOrderProps,
    button_height?:number,
    order_valid?:boolean,
    onOrderUpdate:(item_order:ItemOrderProps, transaction:TransactionProps) => void,
    onCancel:() => void
}

const PayPalOrderCapture = ({ draft_item_order, region, coordinates, items, paypal_account, button_height, order_valid, onOrderUpdate, onCancel }:PayPalOrderCaptureProps) => {

    const createOrder = async() => {
        try {
            const draft_transaction = CheckoutHelpers.genTransactionFromItemOrder(draft_item_order, items, paypal_account?.account_id)
            const { transaction } = await CheckoutApi.createOrder({ ...draft_item_order, region, location: coordinates }, draft_transaction)
            console.log(transaction)
            return transaction.external_id as string //This should be the paypal order Id   
        } catch (e) {
            console.log(e)
            return ''
        }
    }

    const onApprove = async(data:any) => {
        const resp = await CheckoutApi.capturePayPalTransaction(data.orderID);
        onOrderUpdate(resp.item_order, resp.transaction)
    }

    const handleCancel = async(data:any) => {
        await CheckoutApi.cancelTransaction(undefined, data.orderID)
        onCancel()

    }
    
    let height = 55
    if(button_height && button_height > 24 && button_height < 56){ height = button_height }

    return (
        <View style={{ flex:1 }}>
            <PayPalButtons
                disabled={!order_valid ? true : false}
                style={{ layout: 'horizontal', shape:'pill', height }}
                createOrder={createOrder}
                onError={(e) => console.log(e)}
                onApprove={onApprove}
                onCancel={handleCancel}
            />
        </View>
    )
}

export default PayPalOrderCapture