import axios from "axios"
import { Platform } from 'react-native';
import Geolocation from '@react-native-community/geolocation';

import { APIOverrides } from "../../ApiOverrides";
import type { LocationProps, PlayerAddressProps } from "../../types";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment-mini";

let LOCATION_API = ''
let AUTH_SVC_API = ''

export { LocationApi, LocationHelpers }

const LocationApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        LOCATION_API = endpoints['LOCATION_API'] as string;
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
    },
    getLocations: async() => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/locations/all`)
        return resp.data.locations
    },
    getPermission: async():Promise<'denied'|'prompt'|'granted'> => {
        if(Platform.OS == 'web' && navigator?.permissions){
            let permissions = await navigator.permissions.query({ name: "geolocation" })
            return permissions.state
        } else {
            return 'denied'
        }
    },
    cache_location: async(loc:any):Promise<void> => {
        await AsyncStorage.setItem('cache_location', JSON.stringify({ ...loc, cached_at:moment() }))
    },
    getCachedLocation: async():Promise<any> => {
        let cached_location = await AsyncStorage.getItem('cache_location')
        if(cached_location){ 
            let parsed_loc = JSON.parse(cached_location) 
            let diff = moment().diff(parsed_loc.cached_at, 'minutes')
            console.log(diff)
            if(diff > 10){ return undefined }
            return parsed_loc
        }
        return undefined
    },
    getPosition: async():Promise<any> => {
        return new Promise((resolve, reject) => {
            Geolocation.getCurrentPosition((position) => {
                resolve({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    altitude: position.coords.altitude,
                    altitudeAccuracy: position.coords.altitudeAccuracy,
                    speed: position.coords.speed,
                    accuracy: position.coords.accuracy
                })
            }, (error) => {
                reject(error)
            })
        })            
    },
    reverseCoordLookup : async(latitude:number, longitude:number) => {
        const resp = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${LOCATION_API}`, {
            headers: {
                'Authorization': undefined,
                'x-api-key':undefined,
                'sessionid':undefined,
                'distinctid':undefined
            }
        })
        return resp.data.results
    }
}


const LocationHelpers = {
    getAddressFromGeoCodeResults: (addresses:any[], locations:LocationProps[]):{ player_address: PlayerAddressProps, region: string, location?:LocationProps } | undefined => {
        let player_address:PlayerAddressProps = {
            player_address_id: '',
            player_id: '',
            street: '',
            city: '',
            state: '',
            zip:'',
            create_datetime: '', last_update_datetime: '',
            country: 'US',
            type: 'billing',
            status: 'active'
        }

        let street_address = addresses.find(r => r.types.includes('premise'))?.address_components
        if(!street_address){ return undefined }
        street_address = addresses.find(r => r.types.includes('street_address'))?.address_components
        if(!street_address){ return undefined }

        let street_number_component = street_address.find((sa:any) => sa.types.includes('street_number'))
        let route_component = street_address.find((sa:any) => sa.types.includes('route'))
        let city_component = street_address.find((sa:any) => sa.types.includes('locality'))
        let state_component = street_address.find((sa:any) => sa.types.includes('administrative_area_level_1'))
        let zip_component = street_address.find((sa:any) => sa.types.includes('postal_code'))

        if(!street_number_component || !route_component || !city_component || !state_component || !zip_component){ return undefined }

        player_address.street = `${street_number_component.long_name} ${route_component.long_name}`
        player_address.city = city_component.long_name
        player_address.state = state_component.short_name
        player_address.zip = zip_component.long_name
       
        const my_location = locations.find(l => l.value == state_component.short_name)

        return { player_address, region: state_component.long_name, location:my_location }
    } 
}