 // Set your cloud name and unsigned upload preset here:
 const YOUR_CLOUD_NAME = "hoabts6mc";
 const YOUR_UNSIGNED_UPLOAD_PRESET = "be_auth_svc";
 import React, { useRef } from "react";
import { TouchableOpacity } from "react-native";


type CldUploaderProps = {
  onFinishUpload : (obj:any) => void
    onStartUpload?: () => void,
    onCancelUpload?: () => void,
  children?:any,
  public_id: string
}
const ImageUploader = ({ onStartUpload, onCancelUpload, onFinishUpload, public_id, children }:CldUploaderProps) => {
    const upload_ref:any = useRef()

  const processFile = async (e:any) => {
    if(onStartUpload){ onStartUpload() }
    var file = e.target.files[0];
    if(!file && onCancelUpload){ onCancelUpload()}
    var POST_URL =
      "https://api.cloudinary.com/v1_1/" + YOUR_CLOUD_NAME + "/auto/upload";

    var XUniqueUploadId = +new Date();

    processFile();

    function processFile() {
      var size = file.size;
      var sliceSize = 20000000;
      var start = 0;

      setTimeout(loop, 3);

      function loop() {
        var end = start + sliceSize;

        if (end > size) {
          end = size;
        }
        var s = slice(file, start, end);
        send(s, start, end - 1, size);
        if (end < size) {
          start += sliceSize;
          setTimeout(loop, 3);
        }
      }
    }

    const send = (piece:any, start:any, end:any, size:any) => {

      var formdata = new FormData();

      formdata.append("file", piece);
      formdata.append("cloud_name", YOUR_CLOUD_NAME);
      formdata.append("upload_preset", YOUR_UNSIGNED_UPLOAD_PRESET);
      formdata.append("public_id", public_id);

      var xhr = new XMLHttpRequest();
      xhr.open("POST", POST_URL, false);
      xhr.setRequestHeader("X-Unique-Upload-Id", XUniqueUploadId as any);
      xhr.setRequestHeader(
        "Content-Range",
        "bytes " + start + "-" + end + "/" + size
      );

      xhr.onload = function () {
        // do something to response
        onFinishUpload(JSON.parse(this.responseText))
      };

      xhr.send(formdata);
    }

    function slice(file:any, start:any, end:any) {
      var slice = file.mozSlice
        ? file.mozSlice
        : file.webkitSlice
        ? file.webkitSlice
        : file.slice
        ? file.slice
        : noop;

      return slice.bind(file)(start, end);
    }

    function noop() {}
  };

  const handleClick = () => {
    if(!upload_ref?.current) { return console.log('no ref') }
    upload_ref.current.click() 
  }

    return (
         <TouchableOpacity style={{ padding:10 }} onPress={() => handleClick()}>
            <input ref={upload_ref as any} style={{ display:'none' }} type="file"  onChange={processFile} onAbort={() => onCancelUpload ? onCancelUpload() : console.log('cancelled')}/>
            {children}
        </TouchableOpacity>
    );

}

export default ImageUploader;
