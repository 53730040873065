import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator, FlatList, TouchableOpacity } from 'react-native';
import { ItemOrderApi, WalletApi, WalletHelpers } from './api';
import Colors from '../constants/colors';
import WalletSetup from './components/WalletSetup';
import type { MyPlayerProps, PlayerBalanceProps } from '../ProfileManager/api/types';
import type { AccountProps, CodeRequestProps, ItemOrderProps, ItemProps, PlayerAddressProps, PlayerDepositLimitProps, PlayerReferralProps, PlayerWithdrawLimitProps, PromoProps, PublicPlayerProps, RewardOptionProps, WalletSettingsProps } from '../types';
import MyBalance from './components/MyBalance';
import ManualCardAdd from './components/ManualCardAdd';
import DepositCard from './components/DepositCard';
import WithdrawCard from './components/WithdrawCard';
import AccountManager from './components/AccountManager';
import ManageAccountCard from './components/ManageAccountCard';
import AccountSnoozeManager from './components/AccountSnoozeManager';
import AccountLimitManager from './components/AccountLimitManager';
import RemoveAccountCard from './components/RemoveAccount';
import AddressManager from './components/AddressManager';
import VerifyACHAccount from './components/VerifyACHAccount';
import LinkAccountManager from './components/LinkAccountManager';
import ManualACHAdd from './components/ManualACHAdd';
import FundAccountCard from './components/FundAccountCard';
import { Icons, Text } from '../Components';
import { view_styles } from '../constants/styles';
import WalletActionSelector from './components/WalletActionSelector';
import ACHAddCard from './components/ACHAddCard';
import OrderManager from './components/OrderManager';
import WalletSettings from './components/WalletSettings';
import type { BEEventProps } from '../Observer/api/types';

type MyWalletProps = {
    account_id?:string,
    new_balance?:PlayerBalanceProps,
    height?:number,
    onClose:() => void,
    onEvent:(event:BEEventProps) => void
    onTransact: (item_order:ItemOrderProps, account_id?:string) => void
}


const MyWallet = ({ height, new_balance, account_id, onTransact, onClose, onEvent }:MyWalletProps) => {
    const [ wallet_size, setWalletSize ] = useState({ height:0, width:0 });
    const [ view, setView ] = useState<{
        active_view:string,
        on_next_view?:string
    }>({
        active_view: 'wallet'
    });
    const [ walkthrough, setWalkthrough ] = useState<{
        enabled:boolean,
        active_step?:string,
        next_step?:string,
        steps:any[],
        step_action?:string,
    }>({
        enabled:false,
        active_step: 'welcome',
        steps:[]
    });
    const [ selected_account, setSelectedAccount ] = useState<AccountProps>();
    const [ wallet, setWallet ] = useState<{
        loaded:boolean,
        player_addresses:PlayerAddressProps[],
        wallet_settings?:WalletSettingsProps,
        player?:MyPlayerProps,
        my_orders: {
            item_orders:ItemOrderProps[],
            items: ItemProps[]
        },
        my_referral: {
            player_referral?:PlayerReferralProps,
            promo?:PromoProps
        },
        code_details: {
            code_request?:CodeRequestProps,
            referrer?:PublicPlayerProps,
            promo?:PromoProps,
            player_referral?:PlayerReferralProps,
            reward_option?:RewardOptionProps
        },
        player_balance?:PlayerBalanceProps,
        deposit_limit?:PlayerDepositLimitProps,
        withdraw_limit?:PlayerWithdrawLimitProps,
        accounts:AccountProps[],
    }>({
        loaded: false,
        accounts: [],
        my_referral: {},
        my_orders: { item_orders: [], items:[] },
        player_addresses:[],
        code_details: {}
    })
    const { active_view } = view;
    const { my_referral, loaded, player, wallet_settings, player_balance, deposit_limit, code_details, withdraw_limit, accounts, player_addresses, my_orders } = wallet
    const ibt_placeholder_account = accounts.find(a => a.ibt_placeholder);

    useEffect(() => {
        if(!loaded){ getData() }
        if(loaded && new_balance){ setWallet({ ...wallet, player_balance:new_balance }) }
    },[new_balance])

    useEffect(() => {
        if(onEvent){ onEvent({ event_name: 'wallet_view', event_data: { active_view, account_id:selected_account?.account_id }, level: 2 }) }
    },[active_view])

    useEffect(() => {
        if(!walkthrough.enabled){ return }
        if(onEvent){ onEvent({ event_name: 'wallet_walkthrough', event_data: { active_step: walkthrough.active_step, step_action: walkthrough.step_action }, level:1 }) }
    },[walkthrough])

    const getData = async() => {
        WalletApi.setEnvironment();
        const p = await WalletApi.getMyDetails();
        const pb = await WalletApi.getMyBalance();
        const ws = await WalletApi.getMySettings();
        const dl = await WalletApi.getMyDepositLimit();
        const wl = await WalletApi.getMyWithdrawLimit();
        const pa = await WalletApi.getMyAddresses();
        const accts = await WalletApi.getMyAccounts();
        const cd = await WalletApi.getMyCodeDetails();
        const mr = await WalletApi.getMyReferralDetails();
        if(account_id){ setSelectedAccount(accts.find(a => a.account_id == account_id)) }

        const { item_orders, items } = await ItemOrderApi.getMyItemOrders(0);

         
        const steps = WalletHelpers.genWalkthroughSteps(ws, accts, { item_orders, items })
        setWalkthrough({ enabled:!ws.pin?true:false, active_step: 'welcome', steps, next_step: 'my_balance' })
        if(!ws.pin && onEvent){ onEvent({ event_name: 'launch_wallet_wizard', event_data:{}, level:1 })}

        setWallet({
            ...wallet,
            loaded:true,
            player:p,
            wallet_settings:ws,
            player_balance: pb,
            deposit_limit: dl,
            code_details: { ...cd },
            my_referral: mr,
            my_orders:{
                item_orders,
                items
            },
            player_addresses: pa,
            withdraw_limit:wl,
            accounts:accts
        })

    }

    const getStepColor = (step:any, active?:boolean) => {
        if(step.complete){ 
            if(active){ return Colors.highlights.highlight400 }
            return Colors.highlights.highlight400Faded 
        } else {
            if(active){ return Colors.brand.electric }
            return Colors.shades.shade600
        }
    }
    const handleSelectAccount = async(account:AccountProps) => {
        setSelectedAccount(account)
        if(account.status == 'verify'){ return setView({active_view:'verify'}) }
        setView({active_view:'manage_account'})
    }

    const renderWalkthroughProgress = (data:{ item:any, index:number }) => {
        const active = data.item.step == walkthrough.active_step ? true : false

        return (
            <TouchableOpacity 
                style={{ 
                    padding:10, 
                    width: (wallet_size.width - 20) / walkthrough.steps.length, 
                    backgroundColor:getStepColor(data.item, active),
                    borderRightWidth:data.index != walkthrough.steps.length -1 ? 1: 0,
                    borderRightColor:Colors.shades.shade100,
                    borderTopRightRadius: data.index == walkthrough.steps.length - 1 ? 22 : 0,
                    borderBottomRightRadius: data.index == walkthrough.steps.length - 1 ? 22 : 0,
                    borderBottomLeftRadius: data.index == 0 ? 22: 0,
                    borderTopLeftRadius: data.index == 0 ? 22: 0

                }} 
                onPress={() => {
                    if(walkthrough.active_step == 'welcome'){ return }
                    setWalkthrough({ ...walkthrough, active_step: data.item.step })
                }}>
            </TouchableOpacity>
        )
    }

    if(!loaded || !player || !wallet_settings || !player_balance || !deposit_limit || !withdraw_limit){
        return (
            <View style={{ flex:1, height, padding:20 }}>
                <Text size={16} color={Colors.brand.midnight} weight='bold' textAlign='center'>LOADING YOUR WALLET</Text>
                <ActivityIndicator size='large' color={Colors.brand.midnight} style={{ alignSelf:'center', marginTop:30 }} />
            </View>
        )
    }

    if(walkthrough.enabled){
        return (
            <View style={{ flex:1, height }}>
                <View style={{ padding:10, marginRight:10, marginLeft:10, justifyContent:'center', alignItems:'center' }} onLayout={(ev) => {
                    const { height, width } = ev.nativeEvent.layout;
                    setWalletSize({ height, width })
                }}>
                    <FlatList
                        data={walkthrough.steps.sort((a,b) => a.priority - b.priority)}
                        renderItem={renderWalkthroughProgress}
                        keyExtractor={(item) => item.step}
                        horizontal
                    />
                    <View style={{ position:'absolute', right:-4, top:0, bottom:0, justifyContent:'center', alignItems:'center' }}>
                        <View style={{ height:30, width:30, backgroundColor:Colors.shades.white, borderRadius:100, justifyContent:'center', alignItems:'center', ...view_styles.float }}>
                            <Icons.GiftIcon size={18} color={Colors.incentive.gold}/>
                        </View>
                    </View>
                </View>
                {walkthrough.active_step == 'welcome' ?
                <WalletSetup
                    player={player}
                    wallet_settings={wallet_settings}
                    onClose={() => {
                        if(!wallet_settings.pin){ return onClose() }
                        setWalkthrough({ ...walkthrough, enabled:false })
                        if(onEvent){ onEvent({ event_name:'close_wallet_walkthrough', event_data: {}, level:1 }) }
                    }}
                    onNext={() => {
                        if(WalletHelpers.isWalletAuthenticated(wallet_settings)){
                            setWalkthrough({ ...walkthrough, active_step:walkthrough.next_step, next_step:'fund_account' })
                        }
                    }}
                    onSettingsUpdate={(ws) => {
                        setWallet({ ...wallet, wallet_settings: ws })
                        if(WalletHelpers.isWalletAuthenticated(ws)){
                            const steps = WalletHelpers.genWalkthroughSteps(ws, accounts, my_orders)
                            setWalkthrough({ ...walkthrough, steps })
                        }
                    }}
                />
                :walkthrough.active_step == 'my_balance' ?
                <MyBalance
                    player={player}
                    player_balance={player_balance}
                    onClose={() => {
                        setWalkthrough({ ...walkthrough, enabled:false })
                        if(onEvent){ onEvent({ event_name:'close_wallet_walkthrough', event_data: {}, level:1 }) }
                    }}
                    onNext={() => {
                        setWalkthrough({ ...walkthrough, active_step: walkthrough.next_step, next_step: 'deposit' })
                    }}
                />
                :walkthrough.active_step == 'fund_account' ?
                <View style={{ flex:1 }}>
                    {!walkthrough.step_action ?
                    <FundAccountCard
                        player={player}
                        accounts={accounts}
                        walkthrough
                        code_details={code_details}
                        onManualACH={() => setWalkthrough({ ...walkthrough, step_action:'manual_ach' })}
                        onClose={() => {
                            setWalkthrough({ ...walkthrough, enabled:false })
                            if(onEvent){ onEvent({ event_name:'close_wallet_walkthrough', event_data: {}, level:1 }) }
                        }}
                        onCard={(account) => {
                            if(account){
                                setSelectedAccount(account)
                                return setWalkthrough({ ...walkthrough, active_step:'deposit' })
                            }
                            setWalkthrough({ ...walkthrough, step_action:'manual_card' })
                        }}
                        onSelectAccount={(acct) => {
                            setSelectedAccount(acct);
                            setWalkthrough({ ...walkthrough, active_step:'deposit' })
                        }}
                        onNext={() => {
                            let default_account = accounts.find(a => a.default_account)
                            if(!default_account){
                                default_account = accounts.find(a => a.account_type == 'paypal');
                            }
                            if(default_account){
                                setSelectedAccount(default_account)
                                setWalkthrough({ ...walkthrough, active_step: walkthrough.next_step })
                            }
                        }}
                    />
                    :walkthrough.step_action == 'manual_ach' ?
                    <ManualACHAdd
                        player={player}
                        onAddAccount={(acct) => {
                            setWallet({ ...wallet, accounts: accounts.concat(acct) })
                            setWalkthrough({ ...walkthrough, step_action: undefined })
                        }}
                        onCancel={() => setWalkthrough({ ...walkthrough, step_action: undefined })}
                    />
                    :walkthrough.step_action == 'link_ach' && ibt_placeholder_account ?
                    <LinkAccountManager
                        player_addresses={player_addresses}
                        ibt_placeholer_account={ibt_placeholder_account}
                        onComplete={(account) => {
                            setWallet({ ...wallet, accounts: accounts.filter(a => a.account_id != account.account_id).concat(account) })
                            setWalkthrough({ ...walkthrough, step_action: undefined })
                        }}
                        onCancel={() => setWalkthrough({ ...walkthrough, step_action: undefined })}
                    />
                    :walkthrough.step_action == 'manual_card' ?
                    <ManualCardAdd
                        player={player}
                        onAddAccount={(acct) => {
                            setWallet({ ...wallet, accounts: accounts.concat(acct) })
                            setSelectedAccount(acct)
                            setWalkthrough({ ...walkthrough, step_action: undefined, active_step:'deposit' })
                        }}
                        onCancel={() => setWalkthrough({ ...walkthrough, step_action: undefined })}
                    />
                :<></>}
                </View>
                : walkthrough.active_step == 'withdraw'?
                <View style={{ flex:1 }}>
                    {!walkthrough.step_action ?
                    <ACHAddCard
                    player={player}
                    accounts={accounts}
                    onManualACH={() => {
                        setWalkthrough({ ...walkthrough, step_action: 'manual_ach' })
                    }}
                    onClose={() => {
                        setWalkthrough({ ...walkthrough, enabled:false })
                    }}
                    onNext={() => {
                        setWalkthrough({ ...walkthrough, enabled:false })
                    }}
                    onLinkACH={() => {
                        setWalkthrough({ ...walkthrough, step_action: 'link_ach' })
                    }}
                />
                :<></>}
                {walkthrough.step_action == 'manual_ach' ?
                    <ManualACHAdd
                        player={player}
                        onAddAccount={(acct) => setWallet({ ...wallet, accounts: accounts.concat(acct) })}
                        onCancel={() => setWalkthrough({ ...walkthrough, step_action: undefined })}
                    />
                :walkthrough.step_action == 'link_ach' && ibt_placeholder_account ?
                <LinkAccountManager
                    ibt_placeholer_account={ibt_placeholder_account}
                    player_addresses={player_addresses}
                    onComplete={(acct) => {
                        setWallet({ ...wallet, accounts: accounts.filter(a => a.account_id != acct.account_id).concat(acct) })
                        setWalkthrough({ ...walkthrough, step_action:undefined });
                    }}
                    onCancel={() => setWalkthrough({ ...walkthrough, step_action:undefined })}
                />
                :<></>}
                </View>
                
                : walkthrough.active_step == 'deposit' && selected_account ?
                <DepositCard
                    player={player}
                    wallet_settings={wallet_settings}
                    deposit_limit={deposit_limit}
                    code_details={code_details}
                    account={selected_account}
                    onCancel={() => {
                        setWalkthrough({ ...walkthrough, active_step:'fund_account' })
                    }}
                    onTransact={(item_order) => onTransact(item_order, selected_account.account_id)}
                />
                :<></>}
            </View>
        )
    }

    return (
        <View style={{ flex:1, height }}>
            {!WalletHelpers.isWalletAuthenticated(wallet_settings) ?
            <WalletSetup
                player={player}
                wallet_settings={wallet_settings}
                onClose={() => onClose()}
                onSettingsUpdate={(ws) => {
                    setWallet({ ...wallet, wallet_settings: ws })
                    if(WalletHelpers.isWalletAuthenticated(ws)){
                        setView({ active_view: 'wallet' })
                    }
                }}
            />
            :active_view == 'wallet' ?
            <WalletActionSelector
                walkthrough_steps={walkthrough.steps}
                player={player}
                player_balance={player_balance}
                accounts={accounts}
                onStartWalkthrough={(walkthrough, account) => {
                    if(account){ setSelectedAccount(account) }
                    setWalkthrough(walkthrough)
                }}
                onActionSelect={(action, account) => {
                    setView({ active_view: action.action })
                    if(account){ setSelectedAccount(account) }
                }}
                onClose={onClose}
            />
            :active_view == 'my_accounts' ?
            <AccountManager
                accounts={accounts}
                onSelectInstantBankTransfer={() => {
                    setView({ active_view: 'add_ibt' });
                }}
                onClose={() => setView({ active_view: 'wallet' })}
                onSelectManualACH={() => setView({active_view:'add_ach'})}
                onSelectManualCard={() => setView({active_view:'add_card'})}
                onSelectAccount={(acct) => handleSelectAccount(acct)}
            />
            :active_view == 'addresses' ?
            <AddressManager
                player={player}
                player_addresses={player_addresses}
                onAddAddress={(addy) => setWallet({ ...wallet, player_addresses: player_addresses.concat(addy) })}
                onRemoveAddress={(addy) => setWallet({ ...wallet, player_addresses: player_addresses.filter(pa => pa.player_address_id != addy.player_address_id) })}
                onSelect={() => console.log('hey')}
                onCancel={() => console.log('cancel')}
            />
            :active_view == 'order_history' ?
            <OrderManager
                onClose={() => setView({ active_view:'wallet' })}

            />
            :active_view == 'wallet_settings' ?
            <WalletSettings
                player={player}
                withdraw_limit={withdraw_limit}
                deposit_limit={deposit_limit}
                my_referral={my_referral}
                onPremium={() => console.log('go premium')}
                onClose={() => setView({active_view:'wallet'})}
            />
            : active_view == 'ach_add' ?
            <ACHAddCard
                player={player}
                accounts={accounts}
                onManualACH={() => setView({active_view:'add_ach'})}
                onClose={() => setView({ active_view: 'wallet' })}
                onNext={(account) => {
                    setSelectedAccount(account);
                    if(account.status == 'verify'){ return setView({active_view:'verify'}) }
                    return setView({active_view:'wallet'})
                }}
                onLinkACH={() => setView({active_view:'add_ibt'})}
            />
            :active_view == 'account_limit' && selected_account ?
            <AccountLimitManager
                account={selected_account}
                onCancel={() => console.log('hey')}
                onSetLimit={() => console.log('HEY AGAIN')}
            />
            :active_view == 'account_remove' && selected_account ?
            <RemoveAccountCard
                account={selected_account}
                onCancel={() => console.log('hey')}
                onRemove={() => console.log('HEY AGAIN')}
            />
            :active_view == 'account_snooze' && selected_account ?
            <AccountSnoozeManager
                account={selected_account}
                onCancel={() => console.log('hey')}
                onSnooze={() => console.log('HEY AGAIN')}
            />
            :active_view == 'manage_account' && selected_account ?
            <ManageAccountCard
                player={player}
                wallet_settings={wallet_settings}
                player_balance={player_balance}
                account={selected_account}
                deposit_limit={deposit_limit}
                withdraw_limit={withdraw_limit}
                onAccountUpdate={(acct) => setWallet({ ...wallet, accounts: accounts.filter(a => a.account_id != acct.account_id) })}
                onClose={() => setView({active_view:'my_accounts'})}
                onTransact={onTransact}
            />
            :active_view == 'deposit' && selected_account ?
            <DepositCard
                player={player}
                wallet_settings={wallet_settings}
                deposit_limit={deposit_limit}
                account={selected_account}
                code_details={code_details}
                onCancel={() => {
                    return setView({active_view:'wallet'})
                    //return setActiveView('manage_account')
                }}
                onTransact={(item_order) => onTransact(item_order, selected_account.account_id)}
            />
            :active_view == 'withdraw' && selected_account ?
            <WithdrawCard
                player={player}
                player_balance={player_balance}
                wallet_settings={wallet_settings}
                withdraw_limit={withdraw_limit}
                deposit_limit={deposit_limit}
                account={selected_account}
                onTransact={(item_order) => onTransact(item_order, selected_account.account_id)}
                onCancel={() => setView({active_view:'my_accounts'})}
            />
            :active_view == 'verify' && selected_account ?
            <VerifyACHAccount
                account={selected_account}
                onClose={() => {
                    setView({active_view:'my_accounts'})
                    setSelectedAccount(undefined); 
                }}
                onUpdateAccount={(acct) => {
                    setSelectedAccount(acct)
                    setWallet({ ...wallet, accounts: accounts.filter(a => a.account_id != acct.account_id).concat(acct) })
                }}
            />
            :active_view == 'add_ibt' && ibt_placeholder_account?
            <LinkAccountManager
                ibt_placeholer_account={ibt_placeholder_account}
                player_addresses={player_addresses}
                onComplete={(acct) => {
                    setWallet({ ...wallet, accounts: accounts.filter(a => a.account_id != acct.account_id).concat(acct) })
                    setView({active_view:'my_accounts'});
                }}
                onCancel={() => setView({active_view:'my_accounts'})}
            />
            :active_view == 'add_ach'?
            <ManualACHAdd
                player={player}
                onAddAccount={(acct) => setWallet({ ...wallet, accounts: accounts.concat(acct) })}
                onCancel={() => setView({active_view:'my_accounts'})}
            />
            :active_view == 'add_card'?
            <ManualCardAdd
                player={player}
                onAddAccount={(acct) => {
                    setWallet({ ...wallet, accounts: accounts.concat(acct) })
                    setView({active_view:'my_accounts'})
                }}
                onCancel={() => setView({active_view:'my_accounts'})}
            />
            :active_view == 'add' ?
            <FundAccountCard
                player={player}
                accounts={accounts}
                code_details={code_details}
                onCard={(account) => {
                    console.log(account)
                    if(account){
                        setSelectedAccount(account)
                        return setWalkthrough({ ...walkthrough, step_action: 'deposit' })
                    }
                    setView({active_view:'add_card'})
                }}
                onManualACH={() => setView({active_view:'add_ach'})}
                onSelectAccount={(acct) => {
                    setSelectedAccount(acct);
                    setView({active_view:'deposit'})
                }}
                onNext={() => console.log('next')}
                onClose={() => console.log('')}
                walkthrough
            />
            :active_view == 'balance' ?
            <MyBalance
                player={player}
                balance_account={accounts.find(a => a.account_type == 'bettoredge')}
                player_balance={player_balance}
                walkthrough
                onNext={() => console.log('')}
                onClose={() => console.log('CLOSE')}
            />
            :<></>}
        </View>
    )
}

export default MyWallet