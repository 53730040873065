import React, { useState } from 'react';
import { View, TouchableOpacity } from "react-native"
import { Button, Icons, Text, TextInput } from '../../Components';
import Colors from '../../constants/colors';
import { AuthenticateApi, AuthenticateHelpers } from '../api';
import type { AuthenticatedProps } from '../api/types';


type LoginFormProps = {
    visible:boolean,
    width:number,
    onTryAuthenticate:(attribute:string, result:'success'|'fail') => void,
    onRegister: () => void,
    onAuthenticated: (auth_response:AuthenticatedProps) => void
}


const attribute_options = ['username', 'email', 'phone']
const LoginForm = ({ width, visible, onRegister, onAuthenticated, onTryAuthenticate }:LoginFormProps) => {
    const [ authenticated, setAuthenticated ] = useState(false);
    const [ auth_check, setAuthCheck ] = useState<{
        loading:boolean,
        attribute: 'username'|'email'|'phone',
        value:string
    }>({
        loading:false,
        attribute:'username',
        value:''
    });
    const [ halted, setHalted ] = useState(false);
    const [ login_try, setLoginTry ] = useState<{
        pass_token: string,
        loading:boolean,
        visible:boolean,
        verification_session_id?:string,
        type: 'token' | 'password',
        error?:string
    }>({
        pass_token: '',
        loading:false,
        visible:false,
        type: 'password'
    })
    const [ auth_check_error, setAuthCheckError ] = useState<string>();
    
    const { loading, attribute, value } = auth_check
    const { pass_token, type, verification_session_id } = login_try;

    const handleAuthCheck = async() => {
        if(loading){ return } //No mashy mashy
        console.log(authenticated)
        setAuthCheck({ ...auth_check, loading:true })
        const { result, message, verification_session_id, next } = await AuthenticateApi.authCheck(attribute, value);
        if(result == 'fail'){
            setAuthCheckError(message)
            onTryAuthenticate(attribute, 'fail')
        } else {
            onTryAuthenticate(attribute, 'success')
            setLoginTry({
                pass_token: '',
                verification_session_id: verification_session_id,
                visible:true,
                loading:false,
                type: next ?? 'password'
            })
        }
        setAuthCheck({ ...auth_check, loading:false })
    }

    const handleForgot = async() => {
        const resp = await AuthenticateApi.authForgot(attribute, value)
        if(resp.result == 'fail'){
            setAuthCheckError(resp.message)
        } else {
            setLoginTry({
                pass_token: '',
                verification_session_id: resp.verification_session_id,
                visible:true,
                loading:false,
                type: resp.next ?? 'password'
            })
        }
    }

    const handleLogin = async() => {
        setLoginTry({ ...login_try, loading:true })
        const { result, player_id, new_player, message, access_token, refresh_token, expire_datetime } = await AuthenticateApi.authLogin(attribute, value, pass_token, verification_session_id)
        if(result == 'fail' || !access_token || !refresh_token || !player_id){
            setLoginTry({ ...login_try, error:message, loading:false })
            setHalted(true)
            return setTimeout(() => {
                setHalted(false);
            }, 5000);
        }
        setLoginTry({ ...login_try, loading:false, error:undefined })
        setAuthenticated(true)
        return onAuthenticated({
            player_id,
            access_token,
            refresh_token,
            expire_datetime,
            new_player
        })
    }

    const getAttributeIcon = () => {
        switch(attribute){
            case 'email': return <Icons.EmailIcon size={20} color={Colors.brand.midnight}/>
            case 'phone': return <Icons.PhoneIcon size={20} color={Colors.brand.midnight} />
            case 'username': return <Icons.UserIcon size={20} color={Colors.brand.midnight} />
            default: return <Icons.UserIcon size={20} color={Colors.brand.midnight} />
        }
    }

    const { valid } = AuthenticateHelpers.isAttributeValid(attribute, value);
    const login_valid = pass_token.length > 0 && !halted ? true : false;

    if(!visible){ return <></> }

    return (
        <View style={{ flex:1, width }}>
            {!login_try.visible ?
            <View style={{ flex:1 }}>
                <View style={{ padding:20 }}>
                    <View>
                        <Text size={18} color={Colors.brand.midnight} weight='bold'>Login Using My:</Text>
                    </View>
                    <View nativeID='attribute_toggle' style={{ marginTop:10, flexDirection:'row', borderRadius:22, borderWidth:1, backgroundColor:Colors.shades.shade400, borderColor:Colors.shades.shade600 }}>
                        {attribute_options.map((a,i) => {
                            const selected = a == attribute ? true : false
                            return (
                                <Button
                                    style={{ flex:1 }}
                                    title={a.toUpperCase()}
                                    borderRadiusOverride={{
                                        borderTopLeftRadius: i == 0 ? 22 : 0,
                                        borderBottomLeftRadius: i == 0 ? 22 : 0,
                                        borderTopRightRadius: i == attribute_options.length -1 ? 22 : 0,
                                        borderBottomRightRadius: i == attribute_options.length -1 ? 22 : 0,
                                    }}
                                    title_color={selected ? Colors.shades.white : Colors.brand.electric}
                                    padding={15}
                                    title_weight={selected ? 'bold' : 'regular'}
                                    backgroundColor={selected ? Colors.brand.electric : 'transparent'}
                                    onPress={() => setAuthCheck({ attribute:a as 'username'|'email'|'phone', value: '', loading:false })}
                                />
                            )
                        })}
                    </View>
                </View>
                <View style={{ flex:1 }}>
                <View style={{ backgroundColor:Colors.shades.shade100, borderRadius:8, padding:30 }}>
                    <View style={{  marginBottom:10 }}>
                        <Text size={18} color={Colors.brand.midnight} weight='bold'>ENTER {attribute.toUpperCase()}</Text>
                        <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular'>Please enter your {attribute} to continue.</Text>
                    </View>
                    <View style={{ marginTop:5, flexDirection:'row', alignItems:'center', borderRadius:22, borderWidth:1, borderColor:Colors.shades.shade600 }}>
                        <View style={{ paddingLeft:10, paddingRight:15 }}>
                            {getAttributeIcon()}
                        </View>
                        <TextInput
                            style={{ flex:1 }}
                            value={value}
                            placeholder={attribute.toUpperCase()}
                            autoFocus={true}
                            placeholderTextColor={Colors.brand.slate}
                            onChangeText={(text) => {
                                setAuthCheck({ ...auth_check, value: text })
                            }}
                        />
                        <View style={{ padding:10 }}>
                            { valid ?
                            <Icons.CheckCirlceIcon color={Colors.utility.success} size={14} />
                            :
                            <Icons.AlertIcon color={Colors.utility.warning} size={14} />
                            }
                        </View>
                    </View>
                    {auth_check_error ?
                        <TouchableOpacity style={{ marginTop:10, padding:10, backgroundColor:Colors.shades.white, borderRadius:8 }} onPress={() => onRegister()}>
                            <Text size={14} color={Colors.utility.error} weight='semibold' textAlign='center'>{auth_check_error}</Text>
                            <Text style={{ marginTop:5 }} size={14} color={Colors.brand.electric} weight='bold' textAlign='center'>SIGN UP HERE</Text>
                        </TouchableOpacity>
                    :<></>}
                    </View>
                </View>
                <View style={{ padding:20 }}>
                    <Button
                        title='LOGIN'
                        style={{ opacity:!valid||loading?0.5:1 }}
                        title_color={Colors.shades.white}
                        loading={loading}
                        backgroundColor={Colors.brand.electric}
                        disabled={!valid||loading}
                        padding={15}
                        onPress={() => handleAuthCheck()}
                    />
                </View>
                <View style={{ padding:15, backgroundColor:Colors.shades.shade100 }}>
                    <Button
                        title='Are You New?  Sign Up Here'
                        title_color={Colors.brand.electric}
                        backgroundColor='transparent'
                        onPress={() => onRegister()}
                    />
                </View>
            </View>
            :
            <View style={{ flex:1 }}>
                <View style={{ flex:1 }}>
                <View style={{  borderRadius:8, padding:30 }}>
                    <View style={{  marginBottom:10 }}>
                        <Text size={18} color={Colors.brand.midnight} weight='bold'>MY {attribute.toUpperCase()}</Text>
                    </View>   
                    <View style={{ flexDirection:'row', alignItems:'center', borderRadius:22, borderWidth:1, borderColor:Colors.shades.shade600 }}>
                        <View style={{ paddingLeft:10, paddingRight:15 }}>
                            {getAttributeIcon()}
                        </View>
                        <TextInput
                            value={value}
                            editable={false}
                            style={{ flex:1, backgroundColor:Colors.shades.shade100 }}
                            placeholderTextColor={Colors.brand.slate}
                            onChangeText={() => console.log('')}
                        />
                        <View style={{ padding:10 }}>
                            <Icons.CheckCirlceIcon color={Colors.utility.success} size={14} />
                        </View>
                    </View>
                    </View>
                    <View style={{ borderRadius:8, padding:30, backgroundColor:Colors.shades.shade100 }}>
                        <View style={{  marginBottom:10 }}>
                            <Text size={18} color={Colors.brand.midnight} weight='bold'>ENTER {type.toUpperCase()}</Text>
                            {type == 'token' ?
                            <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular'>We sent a one-time token to {value}.  Please enter it below.</Text>
                            :
                            <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular'>Please enter the password associated with the {attribute} {value}.</Text>
                            }
                        </View>
                        <View style={{ flexDirection:'row', alignItems:'center', borderRadius:22, borderWidth:1, borderColor:Colors.shades.shade600 }}>
                            <View style={{ paddingLeft:10, paddingRight:15 }}>
                                <Icons.LockClosedIcon
                                    size={20}
                                    color={Colors.brand.midnight}
                                />
                            </View>
                            <TextInput
                                style={{ flex:1 }}
                                key={type}
                                value={pass_token}
                                placeholder={`Enter ${type}`}
                                autoFocus={true}
                                secureTextEntry
                                textContentType={type=='password'?'password':'oneTimeCode'}
                                placeholderTextColor={Colors.brand.slate}
                                onChangeText={(text) => {
                                    setLoginTry({
                                        ...login_try,
                                        pass_token: text
                                    })
                                }}
                            />
                            <View style={{ padding:10 }}>
                                { login_valid ?
                                <Icons.CheckCirlceIcon color={Colors.utility.success} size={14} />
                                :
                                <Icons.AlertIcon color={Colors.utility.warning} size={14} />
                                }
                            </View>
                        </View>
                        {login_try.error ?
                        <TouchableOpacity style={{ marginTop:10, padding:10, backgroundColor:Colors.shades.white, borderRadius:8 }} onPress={() => handleForgot()}>
                            <Text size={14} color={Colors.utility.error} weight='semibold' textAlign='center'>{login_try.error}</Text>
                            <Text style={{ marginTop:5 }} size={14} color={Colors.brand.electric} weight='bold' textAlign='center'>SEND ME A CODE INSTEAD</Text>
                        </TouchableOpacity>
                        :<></>}
                    </View>
                </View>
                <View style={{ padding:20, flexDirection:'row' }}>
                    <Button
                        title='BACK'
                        style={{ marginRight:5, flex:1 }}
                        title_color={Colors.brand.electric}
                        backgroundColor={Colors.shades.white}
                        borderWidth={1}
                        borderColor={Colors.brand.electric}
                        padding={15}
                        onPress={() => {
                            setLoginTry({ ...login_try, pass_token: '', visible:false });
                        }}
                    />
                    <Button
                        title={halted?'Try again in 5 seconds':'LOGIN'}
                        style={{ flex:3, opacity:!login_valid||loading?0.5:1 }}
                        title_color={Colors.shades.white}
                        loading={login_try.loading}
                        backgroundColor={Colors.brand.electric}
                        disabled={!login_valid||loading}
                        padding={15}
                        onPress={() => handleLogin()}
                    />
                </View>
            </View>
        }
        </View>
    )
}

export default LoginForm