import axios from 'axios';
import type { MyPlayerProps, PasswordStateProps, PlayerBalanceProps, SettingProps } from './types';
import { APIOverrides } from '../../ApiOverrides';
import type { CodeRequestProps, PlayerReferralProps, PlayerSettingProps, PromoProps, PublicPlayerProps, RewardOptionProps } from '../../types';

let AUTH_SVC_API = '';
let VOUCH_ID = '';
let VOUCHED_CALLBACK_URL = '';
let VOUCHED_SANDBOX = true;

const ProfileApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
        VOUCH_ID = endpoints['VOUCH_ID'] as string;
        VOUCHED_CALLBACK_URL = endpoints['VOUCHED_CALLBACK_URL'] as string;
        VOUCHED_SANDBOX = endpoints['VOUCHED_SANDBOX'] as boolean;
    },
    getVouchKeys:() => {
        return { VOUCH_ID, VOUCHED_CALLBACK_URL, VOUCHED_SANDBOX }
    },
    isAttributeValid: async(attribute:string, attribute_value:string) => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/attribute/valid`, { attribute, attribute_value })
        return resp.data.valid
    },
    updatePlayerPic: async(profile_pic:any):Promise<MyPlayerProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/pic/update`, { profile_pic })
        return resp.data.player
    },
    updatePlayerDOB: async(dob:any):Promise<MyPlayerProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/dob/update`, { dob })
        return resp.data.player
    },
    updatePlayerName: async(first_name:string, last_name:string):Promise<MyPlayerProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/name/update`, { first_name, last_name })
        return resp.data.player
    },
    updatePlayerUsername: async(username:string):Promise<MyPlayerProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/username/update`, { username })
        return resp.data.player
    },
    updatePlayerEmail: async(email:string):Promise<void> => {
        await axios.post(`${AUTH_SVC_API}/v1/players/player/email/update`, { email })
        return
    },
    confirmPlayerEmail: async(token:string):Promise<MyPlayerProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/email/confirm`, { token })
        return resp.data.player
    },
    updatePlayerPhone: async(phone:string):Promise<void> => {
        await axios.post(`${AUTH_SVC_API}/v1/players/player/phone/update`, { phone })
        return
    },
    confirmPlayerPhone: async(token:string):Promise<MyPlayerProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/phone/confirm`, { token })
        return resp.data.player
    },
    getMyDetails : async():Promise<MyPlayerProps> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/me`)
        return resp.data.player
    },
    getMyBalance: async():Promise<PlayerBalanceProps> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/balance/me`)
        return resp.data.player_balance
    },
    getMyCodeDetails: async():Promise<{ code_request?:CodeRequestProps, promo?:PromoProps, player_referral?:PlayerReferralProps, referrer?:PublicPlayerProps, reward_option?:RewardOptionProps }> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/promos/request/me`)
        return resp.data
    },
    getMySettings: async():Promise<{ settings:SettingProps[], player_settings:PlayerSettingProps[] }> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/settings/me`)
        console.log(resp.data)
        return resp.data
    },
    updatePlayerSetting: async(player_setting:PlayerSettingProps):Promise<PlayerSettingProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/settings/player/update`, { player_setting })
        console.log(resp.data)
        return resp.data.player_setting
    },
    redeemCode: async(code_request_id:string):Promise<{ code_request?:CodeRequestProps, promo?:PromoProps, player_referral?:PlayerReferralProps, referrer?:PublicPlayerProps, reward_option?:RewardOptionProps  }> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/promos/request/redeem`, { code_request_id })
        return resp.data
    },
    tryCodeRequest: async(referral_code:string):Promise<{ result:'success'|'failed', error_message?:string, code_request?:CodeRequestProps, promo?:PromoProps, player_referral?:PlayerReferralProps, referrer?:PublicPlayerProps, reward_option?:RewardOptionProps }> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/promos/request/try`, { referral_code })
        console.log(resp.data)
        return resp.data
    },
    setPassword: async(password:string, re_password:string, current_password?:string):Promise<MyPlayerProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/password/create`, { password, re_password, current_password })
        return resp.data.player
    },
    forgotUsername : async(attribute:string, attribute_value:string):Promise<void> => {
        await axios.post(`${AUTH_SVC_API}/v1/players/player/forgot/username`, { attribute, attribute_value })
        return
    },
    forgotPassword : async(attribute:string, attribute_value:string):Promise<void> => {
        await axios.post(`${AUTH_SVC_API}/v1/players/player/forgot/password`, { attribute, attribute_value })
        return
    },
    resetPassword : async(password:string, repassword:string, verify_code:string):Promise<void> => {
        await axios.post(`${AUTH_SVC_API}/v1/players/player/reset/password`, { password, repassword, verify_code })
        return
    },
}

const ProfileHelpers = {
    /**
 * Takes a phone number string and returns a formatted verison of the phone number with - between numbers
 * @param phone 
 * @returns formatted phone string
 */
formatPhone : (phone?:string) => {
    if(!phone){ return '' }
    let splitPhone = phone.split('')
    if(splitPhone.length === 0){ return '' }
    if(splitPhone.length > 10 && splitPhone[0] == '1'){ splitPhone.splice(0,1) }
    let newPhone = ''
    splitPhone.map((c, i) => {
        newPhone += c
        if(i === 2 && splitPhone.length > 3){ newPhone += '-' }
        if(i === 5 && splitPhone.length > 6){ newPhone += '-' }
    })
    return newPhone
},
isEmailValid : (email:string):{ valid:boolean, error?:string } => {
    if(email === ''){ return { valid:false, error:'Please enter valid email' } }
    // function that returns true if value is email, false otherwise
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRex.test(email)) {
        return { valid:true };
      }
      return { valid:false, error:'Must be a valid email' };
},
/**
 * Removes - from a formatted phone string
 * @param formatted_phone 
 * @returns unformatted phone string
 */
unformatPhone : (formatted_phone:string) => {
    let splitPhone = formatted_phone.split('-')
    let newPhone = ''
    splitPhone.map(c => {
        newPhone += c
    })
    return newPhone
},
 fullUnformatPhone : (formatted_phone:string) => {
    let unformatted_phone = ProfileHelpers.unformatPhone(formatted_phone)
    let no_par = unformatted_phone.split('(')
    unformatted_phone = no_par.join('')
    no_par = unformatted_phone.split(')')
    unformatted_phone = no_par.join('')
    let no_plus = unformatted_phone.split('+1')
    unformatted_phone = no_plus.join('')
    let no_space = unformatted_phone.split(' ')
    unformatted_phone = no_space.join('')
    return unformatted_phone
},

isAttributeValid : (attribute:string, value:string, optional?:boolean):{ valid:boolean, error?:string } => {
    if(optional){ return { valid:true } }
    switch(attribute){
        case 'phone':
            return ProfileHelpers.isPhoneValid(value)
        case 'email':
            return ProfileHelpers.isEmailValid(value)
        default:
            if(!value || value == ''){ return { valid:false, error: 'Attribute must not be blank' } }
            return { valid:true }
    }
},
/**
 * Checks if phone number is a vali US number
 * @param phone 
 * @returns 
 */
isPhoneValid : (phone:string):{ valid: boolean, error?:string } => {
    if(!phone || phone === '') { return { valid: false, error:'Phone number must be entered' } }
    let phoneasnum = parseInt(phone)
    if(isNaN(phoneasnum)){ return { valid:false, error:'Phone number must be a US 10 digit number' } }
    if(phone.length === 10){ return { valid:true } }
    return { valid:false, error: 'Phone number must be a US 10 digit number' }
},
isPasswordValid : (password:string, validState:PasswordStateProps):PasswordStateProps => {
    if(!password || password === ''){ return { ...validState, state:'not_started' } }
    switch(validState.type){
        case 'min_character':
            if(password.length < 8){ return { ...validState, state:'invalid', error:`Password is only ${password.length} characters` } }
            return { ...validState, state:'valid' }
        case 'case':
            if(ProfileHelpers.doesStringContainUpperAndLowercase(password)){ return { ...validState, state:'valid' } }
            return { ...validState, state:'invalid', error:'Password does not contain upper and lower case' }
        case 'number':
            if(ProfileHelpers.doesStringContainDigit(password)){ return { ...validState, state:'valid' } }
            return { ...validState, state:'invalid', error:'Password does not contain a number' }
        case 'special':
            if(ProfileHelpers.doesStringContainSpecialCharacter(password)) { return { ...validState, state:'valid' } }
            return { ...validState, state:'invalid', error: 'Password does not contain a special character' }
        default: return validState
    }
},

/**
 * Regex to check if string contains upper and lowercase letter
 * @param text 
 * @returns 
 */
doesStringContainUpperAndLowercase : (text:string) => {
    if(/^(?=.*[a-z])(?=.*[A-Z])/.test(text)){ return true }
    return false
},

/**
 * Regex to check if string contains a number
 * @param text 
 * @returns 
 */
doesStringContainDigit : (text:string) => {
    if(/^(?=.*\d)/.test(text)){ return true }
    return false
},

/**
 * Regext to check if string contains a special character
 * @param value 
 * @returns 
 */
doesStringContainSpecialCharacter : (value:string) => {
    var reg = /^(?=.*[~!@#$%^&*()?.,';`])/;
    if(reg.test(value)) {
        return true
    }
    return false
  },


/**
 * Checks if two password strings match
 * @param account 
 * @returns 
 */
doPasswordsMatch : (password:string, re_password:string):PasswordStateProps => {
    if(!re_password){ return { attribute:'re_password', state:'not_started' } }
    if(password == re_password){ return { attribute:'re_password', state:'valid' } }
    return { attribute: 're_password', state:'invalid', error: 'Passwords do not match' }
},
getReferralDescription : (promo:PromoProps) => {
    switch(promo.type){
        case 'promo_code': return `Get ${promo.amount} promo bucks after you verify your account`
        case 'promo_sweepstakes': return `Have a chance at UP TO 500 promo bucks after you verify your account`
        case 'referral_code': return `Get ${promo.amount} promo bucks after you verify your account. Your referrer will get the same after you make your first deposit`
        case 'referral_sweepstakes': return `Have a chance at UP TO 500 promo bucks after you verify your account. Your referrer will get the same after you make your first deposit`
        default: return ''
    }
} ,
isChangeCodeAllowed :(code_request?:CodeRequestProps ) => {
    if(!code_request){ return true }
    if(['fulfilled','closed'].includes(code_request.status)){ return false }
    return true
}

}

export { ProfileApi, ProfileHelpers }