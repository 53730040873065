import React, { useEffect, useState } from 'react';
import { FlatList, TouchableOpacity, View, ScrollView } from 'react-native';
import type { AccountProps, AccountSnoozeProps } from '../../types';
import { WalletApi } from '../api';
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import moment from 'moment-mini';

const duration_options = [1,7,14,30]

type AccountSnoozeManagerProps = {
    account:AccountProps,
    onCancel: () => void
    onSnooze: (account_snooze?:AccountSnoozeProps) => void
}

const AccountSnoozeManager = ({ account, onCancel, onSnooze } : AccountSnoozeManagerProps) => {
    const [ loading, setLoading ] = useState(false);
    const [ dropdown_visible, setDropdownVisible ] = useState(false);
    const [ disclaimer, setDisclaimer ] = useState(false);
    const [ active_duration, setActiveDuration ] = useState<number | undefined>(undefined);
    const [ account_snooze, setAccountSnooze ] = useState<AccountSnoozeProps>()
    /**
     * Effect whenever the account_id changes. Need to get the data from server
     */
    useEffect(() => {
        if(!account){ return }
        getDataFromServer(account.account_id)
    },[account])

    useEffect(() => {
        if(!account_snooze){ return }
        if(account_snooze.status == 'active'){
            //update
        }
    },[account_snooze])

    /**
     * Get data from the server
     * @param account_id 
     */
    const getDataFromServer = async(account_id:string) => {
        setLoading(true);
        const as = await WalletApi.getSnoozeByAccountId(account_id)
        setAccountSnooze(as)
        onSnooze(as)
        setLoading(false)
    }

    const handleSetSnooze = async() => {
        if(account_snooze || !active_duration || loading){ return }
        let snooze:AccountSnoozeProps = {
            account_id:account.account_id,
            account_snooze_id:'',
            snooze_days: active_duration,
            status: 'active',
            create_datetime: '',
            last_update_datetime: '',
            end_datetime: ''
        }
        setLoading(true)
        const new_snooze = await WalletApi.createAccountSnooze(snooze)
        setAccountSnooze(new_snooze)
        setLoading(false)
    }

    const renderOptions = (data: { item:number, index:number }) => {
        return (
            <TouchableOpacity style={{ padding:10 }} onPress={() => {
                setActiveDuration(data.item);
                setDropdownVisible(false);
            }}>
                <Text color={Colors.brand.midnight} weight='semibold' size={14}>{data.item} days</Text>
            </TouchableOpacity>
        )
    }
    const valid = active_duration ? true : false;
    return (
        <View style={{ flex:1 }}>
            <View style={{ padding:20 }}>
                <Text size={30} color={Colors.brand.midnight} weight='bold'>Snooze Account</Text>
                <Text style={{ marginTop:15 }} size={16} color={Colors.brand.midnight} weight='regular'>Take a break from the real money features of BettorEdge using this account.</Text>
            </View>
            <ScrollView style={{ flex:1, padding:16}}>
                <Text size={16} color={Colors.brand.cobalt} weight='semibold'>Amount</Text>
                <TouchableOpacity 
                onPress={() => setDropdownVisible(!dropdown_visible)}
                style={{marginTop:8, flexDirection:'row', alignItems:'center', justifyContent:'space-between', padding:15, backgroundColor:Colors.shades.shade100}}>
                    <Text color={Colors.brand.cobalt} size={14} weight='semibold'>{active_duration?`${active_duration} Days`:'Select Duration'}</Text>
                    <Icons.ChevronIcon direction='down' color={Colors.brand.cobalt} size={8} />
                </TouchableOpacity>
                {dropdown_visible ?
                <View style={{ borderBottomRightRadius:8, borderBottomLeftRadius:8, padding:10, borderWidth:1, borderTopWidth:1, borderColor:Colors.shades.shade600 }}>
                    <FlatList
                        data={duration_options}
                        renderItem={renderOptions}
                    />
                </View>
                :<></>}
            </ScrollView>
            { valid ?
            <View nativeID='snooze_disclaimer' style={{ margin:10 }}>
                <TouchableOpacity style={{ flexDirection:'row', padding:5 }} onPress={() => setDisclaimer(!disclaimer)}>
                    <View style={{ justifyContent:'center', marginRight:10, alignItems:'center', height:25, width:25, borderRadius:4, backgroundColor: disclaimer ? Colors.brand.midnight : 'transparent', borderWidth:1, borderColor:Colors.brand.midnight }}>
                        {disclaimer ?
                        <Icons.CheckIcon color={Colors.shades.white} size={14} />
                        :<></>}
                    </View>
                    <Text size={14} color={Colors.brand.midnight} weight='regular'>I confirm I wwould like to snooze my account for {active_duration} days. This cannot be undone!</Text>
                </TouchableOpacity>
            </View>
            :<></>}
            <View nativeID='action_row' style={{ flexDirection:'row', padding:20 }}>
            <Button
                    style={{ flex:1, marginRight:5, opacity: 1 }}
                    title={'CLOSE'}
                    title_color={Colors.brand.electric}
                    borderColor={Colors.brand.electric}
                    borderWidth={1}
                    padding={15}
                    onPress={() => onCancel()}
                />
                <Button
                    style={{ flex:3, opacity: valid&&disclaimer?1:0.5 }}
                    title={'SAVE SNOOZE'}
                    disabled={!valid||!disclaimer}
                    loading={loading}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.brand.electric}
                    padding={15}
                    onPress={() => handleSetSnooze()}
                />
            </View>

            {account_snooze ?
            <View style={{ position:'absolute', top:0, right:0, left:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'center', alignItems:'center' }}>
                <View style={{ backgroundColor:Colors.shades.white, borderRadius:8, padding:20}}>
                    <View style={{ padding:10, backgroundColor:Colors.brand.cyan, borderRadius:8, alignSelf:'center' }}>
                        <Icons.SnoozeIcon size={24} color={Colors.shades.white} />
                    </View>
                    <Text style={{ padding:10 }} size={18} weight='bold' textAlign='center'>Account is Snoozing</Text>
                    <Text size={14} color={Colors.brand.midnight} weight='semibold' textAlign='center'>Snooze ends {moment(account_snooze.end_datetime).fromNow()}</Text>
                    <TouchableOpacity style={{  marginTop:10, padding:10, borderWidth:1, borderColor:Colors.brand.electric, borderRadius:8 }} onPress={() => onCancel()}>
                        <Text size={14} color={Colors.brand.electric} textAlign='center'>Go Back</Text>
                    </TouchableOpacity>
                </View>
            </View>
            :<></>}
        </View>
    )
}

export default AccountSnoozeManager