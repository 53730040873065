import React from 'react';
import { View, TouchableOpacity, Image } from "react-native";
import { Button, Checkbox, Icons, Text, TextInput } from '../../Components';
import type { AccountProps, ItemProps, PlayerAddressProps } from '../../types';
import Colors from '../../constants/colors';
import { CheckoutApi, CheckoutHelpers } from '../api';
import moment from 'moment-mini';

type AccountSummaryCardProps = {
    selected_account?:AccountProps,
    accounts:AccountProps[],
    cvv?:string,
    items:ItemProps[],
    player_address?:PlayerAddressProps,
    billing_address_required?:boolean,
    onRequestNewAccount:() => void,
    onCVVChange:(new_cvv:string) => void,
    onChangeAddress:() => void
    onAccountsUpdate:(accounts:AccountProps[]) => void,
    onSelectAccount:(account:AccountProps) => void
}
const AccountSummaryCard = ({ accounts, cvv, items, selected_account, billing_address_required, player_address, onRequestNewAccount, onCVVChange, onAccountsUpdate, onSelectAccount, onChangeAddress }:AccountSummaryCardProps) => {

    const my_accounts = CheckoutHelpers.filterMyPurchaseAccounts(accounts, items);


    const getLabel = (account:AccountProps) => {
        switch(account.account_type){
            case 'ach':
                if(account.ibt_placeholder){
                    return (
                        <View style={{ flexDirection:'row', alignItems:'center', padding:4 }}>
                            <Image
                                source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1695821528/1630344814441_wjq98b.png' }}
                                style={{ width:50, height:30 }}
                                resizeMode='cover'
                            />
                        </View>
                    )
                }
                if(account.ach_detail?.payment_provider == 'nuvei'){ 
                    return (
                        <View style={{ flexDirection:'row', alignItems:'center', padding:4 }}>
                            <Icons.BankInstantIcon size={22} color={Colors.brand.midnight}/>
                            <Text style={{ marginLeft:3 }} size={12} color={Colors.brand.midnight} weight='semibold'>{account.ach_detail?.bank_name}</Text>
                        </View>
                    ) 
                }
                return (
                    <View style={{ flexDirection:'row', alignItems:'center', padding:4 }}>
                        <Icons.BankIcon size={22} color={Colors.brand.midnight}/>
                        <Text style={{ marginLeft:3 }} size={12} color={Colors.brand.midnight} weight='semibold'>{account.ach_detail?.bank_name}</Text>
                    </View>
                ) 
            case 'card':
                return renderCardIcon(account.card_detail?.card_brand)
            case 'paypal':
                return (
                    <View style={{ flexDirection:'row', alignItems:'center', padding:4, backgroundColor:Colors.company.paypal }}>
                        <Icons.PayPalIcon size={50} color={Colors.brand.midnight}/>
                    </View>
                )
            case 'bettoredge':
                return (
                    <View style={{ flexDirection:'row', alignItems:'center', padding:4 }}>
                        <Icons.BettorEdgeIcon size={10} color={Colors.brand.midnight}/>
                        <Text style={{ marginLeft:5 }} size={14} color={Colors.brand.midnight} weight='semibold'>Wallet</Text>
                    </View>
                )
            default: return <></>

        }
    }

    const renderCardIcon = (brand?:string) => {
        switch(brand?.toLowerCase()){
            case 'amex':
                return <Icons.AmexCardIcon size={50} />
            case 'visa':
                return <Icons.VisaCardIcon size={50} />
            case 'mastercard':
                return <Icons.MasterCardIcon size={50} />
            case 'discover':
                return <Icons.DiscoverCardIcon size={50} />
            default:
                return <Icons.CreditCardIcon size={50} color={Colors.brand.midnight}/>
        }
    }


    const renderAccounts = (data: { item:AccountProps, index:number }) => {
        const selected = data.item.account_id == selected_account?.account_id ? true : false
        return (
            <TouchableOpacity 
                style={{ margin:3, flexDirection:'row', backgroundColor:selected?Colors.highlights.highlight400Faded:'transparent', borderWidth:selected?2:1, borderColor:selected?Colors.brand.electric:Colors.shades.shade600, borderRadius:4 }}
                onPress={() => onSelectAccount(data.item)}>
                {getLabel(data.item)}
            </TouchableOpacity>
        )
    }
    
    const handleUpdateDefault = async(account_id:string) => {
        const updated_accounts = await CheckoutApi.updateDefaultAccount(account_id);
        onAccountsUpdate(updated_accounts)
    }

    return (
        <View style={{  padding:5 }}>
            <View style={{ padding:5, paddingTop:10, paddingBottom:10, flexDirection:'row', alignItems:'center' }}>
                <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='semibold'>Select Account</Text>
                <Button
                    title='Add New Account'
                    title_color={Colors.brand.electric}
                    backgroundColor='transparent'
                    onPress={() => onRequestNewAccount()}
                />
            </View>
            <View nativeID='account_selector' style={{ flexDirection:'row', flexWrap:'wrap' }}>
                {my_accounts.map((a,i) => {
                    return renderAccounts({ item: a, index:i })
                })}
            </View>
            {selected_account ?
            <View style={{ padding:10, marginTop:5 }}>
                <View style={{ flexDirection:'row', alignItems:'center' }}>
                    <View style={{ flex:1, marginRight:5 }}>
                        <Text size={14} weight='bold'>{selected_account.account_label}</Text>
                        {selected_account.account_type == 'card' && selected_account.card_detail ?
                            <Text style={{ marginTop:3 }} size={12} color={Colors.brand.slate}>EXP: {moment(selected_account.card_detail.expiration_date).format('MM/YYYY')}</Text>
                        :<></>}   
                    </View>
                    <View style={{ flexDirection:'row', alignItems:'center' }}>
                        <Text style={{ marginRight:5 }} size={14} color={Colors.brand.midnight}>Default</Text>
                        <Checkbox
                            checked={selected_account.default_account ? true : false}
                            onSelect={() => handleUpdateDefault(selected_account.account_id)}
                            size={20}
                        />
                    </View>
                </View>
                {selected_account.account_type == 'card' ?
                <View style={{ flexDirection:'row', alignItems:'center', marginTop:10 }}>
                    <Text style={{ flex:1 }} color={Colors.brand.midnight} size={12}>Enter CVV</Text>
                    <TextInput
                        style={{ textAlign:'center', width:100, padding:10, borderRadius:4 }}
                        placeholderTextColor={Colors.brand.slate}
                        placeholder='123'
                        value={cvv}
                        onChangeText={(text) => onCVVChange(text)}
                    />
                </View>
                :<></>}
                {billing_address_required ?
                <View style={{ marginTop:15, flexDirection:'row', alignItems:'center' }}>
                    {player_address ?
                    <View style={{ flex:1 }}>
                        <Text size={12} color={Colors.brand.midnight}>{player_address.street}</Text>
                        <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight}>{player_address.city}, {player_address.state} {player_address.zip}</Text>
                    </View>
                    :<View style={{flex:1}} />}
                    <Button
                        title={player_address ? 'CHANGE':'ADD BILLING ADDRESS'}
                        title_color={Colors.brand.electric}
                        padding={5}
                        onPress={() => onChangeAddress()}
                    />
                </View>
                :<></>}
            </View>
            :<></>}

        </View>
    )
}

export default AccountSummaryCard