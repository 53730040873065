import axios from "axios";
import { APIOverrides } from "../../ApiOverrides";
import type { AccountProps, ItemOrderProps, ItemProps, LocationProps, PlayerAddressProps, TransactionDetailProps, TransactionProps } from "../../types";
import type { MyPlayerProps } from "../../ProfileManager/api/types";
import moment from "moment-mini";


let WALLET_SVC_API = ''
let AUTH_SVC_API = ''
let PAYPAL_CLIENT_ID = ''

export { CheckoutApi, CheckoutHelpers }

const CheckoutApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        WALLET_SVC_API = endpoints['WALLET_SVC_API'] as string;
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
        PAYPAL_CLIENT_ID = endpoints['PAYPAL_CLIENT_ID'] as string;
    },
    getPayPalClientId: () => {
        return PAYPAL_CLIENT_ID
    },
    getMyDetails: async():Promise<MyPlayerProps> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/me`)
        return resp.data.player
    },
    getMyAddresses: async():Promise<PlayerAddressProps[]> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/addresses/me`)
        return resp.data.player_addresses
    },
    updateAccountDefaultAddress: async(account_id:string, player_address_id?:string):Promise<AccountProps> => {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/accounts/account/address/default`, { account_id, player_address_id })
        return resp.data.account
    },
    getAddressById: async(player_address_id:string):Promise<PlayerAddressProps> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/addresses/address/${player_address_id}`)
        return resp.data.player_address
    },
    getMyAccounts: async():Promise<AccountProps[]> => {
        const resp = await axios.get(`${WALLET_SVC_API}/v1/accounts/me`);
        return resp.data.accounts
    },
    getAccountById: async(account_id:string):Promise<AccountProps> => {
        const resp = await axios.get(`${WALLET_SVC_API}/v1/accounts/account/${account_id}`)
        return resp.data.account
    },
    updateDefaultAccount:async(account_id:string):Promise<AccountProps[]> => {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/accounts/account/default`, { account_id })
        return resp.data.accounts
    },
    getItemById: async(item_id:string):Promise<ItemProps> => {
        const resp = await axios.get(`${WALLET_SVC_API}/v1/items/item/${item_id}`)
        return resp.data.item
    },
    getItemsByIds: async(item_ids:string[]):Promise<ItemProps[]> => {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/items/bulk/get`, { item_ids })
        return resp.data.items
    },
    getTransactionById: async(transaction_id:string):Promise<{ transaction:TransactionProps, transaction_details:TransactionDetailProps[] }> => {
        const resp = await axios.get(`${WALLET_SVC_API}/v1/transactions/transaction/${transaction_id}`)
        return resp.data
    },
    cancelTransaction: async(transaction_id?:string, external_id?:string):Promise<{ item_order:ItemOrderProps, transaction:TransactionProps }> => {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/transactions/transaction/cancel`, { transaction_id, external_id })
        return resp.data
    },
    capturePayPalTransaction : async(external_id:string):Promise<{ item_order:ItemOrderProps, transaction:TransactionProps }> => {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/transactions/transaction/paypal/capture`, { external_id })
        return resp.data
    },
    createOrder: async(item_order:ItemOrderProps, transaction:TransactionProps):Promise<{ item_order:ItemOrderProps, transaction:TransactionProps }> => {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/orders/order/create`, { item_order, transaction })
        return { item_order: resp.data.item_order, transaction:resp.data.transaction }
    },
    getMyOrders: async(offset:number):Promise<{item_orders:ItemOrderProps[], items:ItemProps[]}> => {
        const resp = await axios.get(`${WALLET_SVC_API}/v1/orders/me?offset=${offset}`)
        return { item_orders: resp.data.item_orders, items: resp.data.items }
    }
}


const CheckoutHelpers = {
    filterMyPurchaseAccounts: (accounts:AccountProps[], items:ItemProps[]) => {
        let filtered_accounts = accounts.filter(a => a.status == 'active').sort((a,b) => moment(a.create_datetime).unix() - moment(b.create_datetime).unix());
        let wallet_excluded = items.find(i => !i.wallet_allowed) ? true : false
        if(wallet_excluded){
            filtered_accounts = filtered_accounts.filter(a => a.account_type != 'bettoredge');
        }
        let ach_only = items.find(i => i.identifier == 'withdraw') ? true : false
        if(ach_only){ filtered_accounts = filtered_accounts.filter(a => a.account_type=='ach' && !a.ibt_placeholder) }

        let items_restricting_accounts = items.find(i => i.restricted_account_types)
        if(items_restricting_accounts?.restricted_account_types && items_restricting_accounts.restricted_account_types.includes('card')){
            filtered_accounts = filtered_accounts.filter(a => a.account_type == 'card')
        }
        if(items_restricting_accounts?.restricted_account_types && items_restricting_accounts.restricted_account_types.includes('ach')){
            filtered_accounts = filtered_accounts.filter(a => a.account_type == 'ach')
        }

        return filtered_accounts.sort((a,b) => {
            if(b.default_account && !a.default_account){ return 2 }
            if(b.ibt_placeholder && !a.ibt_placeholder){ return -5 }
            if(b.account_type == 'ach' && a.account_type != 'ach'){ return 1 }
            if(b.account_type == 'card' && a.account_type != 'card'){ return -1 }
            if(b.account_type == 'paypal' && a.account_type != 'paypal'){ return -2 }
            return 0
        })
    },
    isShippingRequired: (items:ItemProps[]) => {
        return items.find(i => i.shipping_address_required) ? true : false
    },
    isBillingAddressRequired:(items:ItemProps[], account?:AccountProps) => {
        let item_decision = items.find(i => i.billing_address_required) ? true : false
        if(account && account.account_type == 'paypal'){ return false }
        return item_decision
    },
    isLocationRequired: (items:ItemProps[]) => {
        return items.find(i => i.location_required) ? true : false
    },
    isLocationRestrictedToLegalStates : (items:ItemProps[]) => {
        return items.find(i => i.location_restricted) ? true : false
    },
    isCVVValid: (account:AccountProps, cvv?:string):boolean => {
        if(!cvv){ return false }
        if(!account.card_detail){ return false }
        if(account.card_detail.card_brand?.toLowerCase() != 'amex' && cvv.length != 3){ return false }
        if(account.card_detail.card_brand?.toLocaleLowerCase() == 'amex' && cvv.length != 4){ return false }
        return true
    },
    isOrderValid: (draft_order:ItemOrderProps, items:ItemProps[], account?:AccountProps, cvv?:string, billing_address?:PlayerAddressProps, shipping_address?:PlayerAddressProps, player_location?:LocationProps, coords?:any):string[] => {
        let errors:string[] = []
        if(!account){ return ['Please select a pay with account or add a new account to complete order'] }
        const shipping_address_required = CheckoutHelpers.isShippingRequired(items);
        const billing_address_required = CheckoutHelpers.isBillingAddressRequired(items, account);
        if(billing_address_required && !billing_address){ errors.push('Billing address is required') }
        if(shipping_address_required && !shipping_address || (shipping_address_required && !draft_order.shipping_address_id)){ errors.push('Shipping address is required') }
        
        if(account.account_type == 'card' && !CheckoutHelpers.isCVVValid(account, cvv)){ errors.push('CVV is not valid') }
        let amount = parseFloat(draft_order.amount as string);
        if(isNaN(amount)){ errors.push('Amount is not valid') }
        if(draft_order.items.length == 0){ errors.push('Items are required') }

        if(CheckoutHelpers.isLocationRequired(items) && !coords?.latitude){ errors.push('Location is required for an item being purchased') }
        if(CheckoutHelpers.isLocationRestrictedToLegalStates(items) && player_location &&!player_location.legal_ind){ errors.push('Your current location is not eligible for this purchase') }
        return errors
    },
    getTransactionFromItemAndAccountId: (item:ItemProps, account_id:string):TransactionProps => {
        const be_client_id = Math.random().toString()//uuid.v4()
        return {
            transaction_id: '',
            transaction_type: item.transaction_type,
            account_id:account_id, 
            amount: item.price,
            currency: 'USD',
            description: item.description,
            external_id: '',
            session_id: '',
            be_client_id,
            player_id: '',
            status: 'pending',
            create_datetime: '',
            last_update_datetime:''
        }
    },
    genItemOrder: (draft_order:ItemOrderProps, items:ItemProps[], account:AccountProps):ItemOrderProps => {
        let amount = parseFloat(draft_order.amount as string);
        let fee_1 = 0
        let no_fee = items.find(i => i.no_fees) ? true : false
        if(!no_fee){ fee_1 = Math.round(((account.transaction_fee * amount) + Number.EPSILON) * 100) / 100 }
        let total_amount = amount + fee_1
        return {
            ...draft_order,
            fee_1_amount: fee_1,
            fee_1: 'Transaction Fee',
            total_amount
        }
    },
    genTransactionFromItemOrder : (item_order:ItemOrderProps, items:ItemProps[], account_id?:string, cvv?:string):TransactionProps => {
        const be_client_id = Math.random().toString()//uuid.v4()
        let credit_item = items.find(i => i.transaction_type == 'credit') ? true : false
        return {
            transaction_id: '',
            transaction_type: credit_item ? 'credit' : 'debit',
            account_id:account_id??'', 
            amount: item_order.total_amount,
            currency: 'USD',
            description: item_order.description,
            
            external_id: '',
            cvv,
            session_id: '',
            be_client_id,
            player_id: '',
            status: 'pending',
            create_datetime: '',
            last_update_datetime:''
        }
    }
}