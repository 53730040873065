import React from 'react';
import { View, Image, Linking } from 'react-native';
import { Button, Text } from '../Components';
import Colors from '../constants/colors';
import { isSafari, isChrome, isIOS } from 'react-device-detect';
import type { LocationProps, PlayerAddressProps } from '../types';

type LocationTrackerProps = {
    onClose: () => void,
    permission: {
        checked:boolean,
        permission?: 'granted'|'denied'|'prompt'
    },
    location: {
        loading: boolean,
        error?:string,
        coordinates:any,
        address_results:any[],
        player_address?:PlayerAddressProps,
        region?:string,
        location?:LocationProps
    }
    onGetLocation: () => void
}
const LocationTracker = ({ onClose, onGetLocation, permission, location }: LocationTrackerProps) => {

    const { loading, error } = location;

    const handleHelpMe = () => {
        if(isSafari && isIOS){ return Linking.openURL('https://support.bettoredge.com/hc/en-us/articles/1500010390982-How-do-I-turn-on-Location-Settings-for-Apple-Safari') }
        if(isChrome && isIOS){ return Linking.openURL('https://support.bettoredge.com/hc/en-us/articles/1500010423381-How-do-I-turn-on-Location-Settings-for-Android-Chrome') }
        return Linking.openURL('https://support.bettoredge.com/hc/en-us/articles/1500010423381-How-do-I-turn-on-Location-Settings-for-Android-Chrome')
    }

    return (
        <View>
            {permission.permission == 'prompt' ?
            <View style={{ padding:20 }}>
                <View style={{ padding:10 }}>
                    <Text size={18} color={Colors.brand.midnight} weight='semibold' textAlign='center'>ALL REAL-MONEY TRANSACTIONS REQUIRE LOCATION</Text>
                </View>
                <View style={{ justifyContent:'center', alignItems:'center', padding:15 }}>
                    <Image
                        source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1696434494/loc_icon_qdkurk.png' }}
                        style={{ width: 80, height: 80 * 1.232 }}
                    />
                </View>
                <View style={{ padding:10 }}>
                    <Text style={{ marginTop:5 }} color={Colors.brand.midnight} size={14} weight='regular' textAlign='center'>When using real money, either in the form of a bet, competition or deposit/withdrawal, BettorEdge requires location services turned on to verify you are in a location where betting is permitted.</Text>
                </View>
            </View>
            : permission.permission == 'denied' ?
            <View style={{ padding:20 }}>
                <View style={{ padding:10 }}>
                    <Text size={18} color={Colors.brand.midnight} weight='semibold' textAlign='center'>LOCATION SETTINGS ARE NOT ENABLED</Text>
                </View>
                <View style={{ justifyContent:'center', alignItems:'center', padding:15 }}>
                    <Image
                        source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1696434494/loc_icon_qdkurk.png' }}
                        style={{ width: 80, height: 80 * 1.232 }}
                    />
                </View>
                <View style={{ padding:10 }}>
                    <Text style={{ marginTop:5 }} color={Colors.brand.midnight} size={14} weight='regular' textAlign='center'>{error ?? `We were not able to get your location.  Please use the Help Me button below to troubleshoot enabling your device's location services`}</Text>
                </View>
            </View>
            : permission.permission == 'granted' || location.location ?
            <View style={{ padding:20 }}>
                <View style={{ padding:10 }}>
                    <Text size={18} color={Colors.brand.midnight} weight='semibold' textAlign='center'>ALL REAL-MONEY TRANSACTIONS REQUIRE LOCATION</Text>
                </View>
                <View style={{ justifyContent:'center', alignItems:'center', padding:15 }}>
                    <Image
                        source={{ uri: location.location?.location_image }}
                        style={{ width: 100, height: 100 }}
                    />
                </View>
                <View style={{ padding:10 }}>
                    <Text style={{ marginTop:5 }} color={Colors.brand.midnight} size={14} weight='regular' textAlign='center'>When using real money, either in the form of a bet, competition or deposit/withdrawal, BettorEdge requires location services turned on to verify you are in a location where betting is permitted.</Text>
                </View>
            </View>
            :<></>}
            { permission.permission != 'denied' ?
            <View nativeID='action_row' style={{ flexDirection:'row', padding:20 }}>
                <Button 
                    title='CLOSE'
                    style={{ flex:1, marginRight:5 }}
                    title_color={Colors.utility.error}
                    borderWidth={1}
                    padding={15}
                    borderColor={Colors.utility.error}
                    onPress={() => onClose()}
                />
                <Button
                    title={permission.permission == 'prompt' ? 'PROMPT FOR LOCATION' : 'UPDATE LOCATION'}
                    style={{ flex:3, opacity: loading ? 0.5 : 1 }}
                    loading={loading}
                    disabled={loading}
                    backgroundColor={Colors.brand.electric}
                    title_color={Colors.shades.white}
                    onPress={() => onGetLocation()}
                />
            </View>
            :
            <View nativeID='action_row' style={{ flexDirection:'row', padding:20 }}>
                <Button 
                    title='CLOSE'
                    style={{ flex:1, marginRight:5 }}
                    title_color={Colors.utility.error}
                    borderWidth={1}
                    padding={15}
                    borderColor={Colors.utility.error}
                    onPress={() => onClose()}
                />
                <Button 
                    title='HELP ME'
                    style={{ flex:3 }}
                    title_color={Colors.shades.white}
                    borderWidth={1}
                    padding={15}
                    backgroundColor={Colors.brand.electric}
                    onPress={() => handleHelpMe()}
                />
            </View>
            }
        </View>
    )
}

export default LocationTracker