import React, { useState } from 'react';
import { Image, View, ScrollView, TouchableOpacity } from "react-native"
import type { MyPlayerProps } from "../api/types"
import { Button, Icons, Text } from '../../Components';
import ImageUploader from '../../Components/ImageUploader';
import Colors from '../../constants/colors';
import { ProfileApi } from '../api';
import { button_styles } from '../../constants/styles';

type ProfilePicManagerProps = {
    player:MyPlayerProps, 
    walkthrough?:boolean,
    onClose:() => void,
    onNext:() => void,
    onPlayerUpdate:(player:MyPlayerProps) => void
}


const ProfilePicManager = ({ player, onPlayerUpdate, walkthrough, onNext, onClose }:ProfilePicManagerProps) => {
    const [ loading, setLoading ] = useState(false);

    const handleUpdatePic = async(profile_pic:string) => {
        const new_player = await ProfileApi.updatePlayerPic(profile_pic)
        setLoading(false);
        onPlayerUpdate(new_player)
    }


    return (
        <View style={{ flex:1 }}>
            <ScrollView style={{ flex:1 }}>
                {walkthrough ?
                <View style={{ padding:20 }}>
                    <Text size={30} color={Colors.brand.midnight} weight='bold'>Update Your Picture</Text>
                    <Text style={{ marginTop:15 }} size={16} color={Colors.brand.midnight} weight='regular'>Your profile picture is how users will see you in leaderboards and activity feeds.</Text>
                </View>
                :<></>}
                <View style={{ justifyContent:'center', alignItems:'center' }}>
                <Image
                    source={{ uri: player.profile_pic ? player.profile_pic :'https://res.cloudinary.com/hoabts6mc/image/upload/v1714408535/sports_avatar_wdzsxc.webp' }}
                    style={{ height:400, width:400, borderRadius:4 }}
                    resizeMode='cover'
                />
                <View style={{ position:'absolute', left:0, bottom:0, right: 0, padding:10 }}>
                    <ImageUploader
                        public_id={`${player.player_id}_${Math.random()}`}
                        onStartUpload={() => setLoading(true)}
                        onCancelUpload={() => setLoading(false)}
                        onFinishUpload={(obj) => handleUpdatePic(obj.secure_url)}
                    >
                        <Button
                            title={player.profile_pic ? 'CHANGE' : 'ADD PICTURE'}
                            disabled
                            loading={loading}
                            title_color={Colors.brand.electric}
                            borderWidth={1}
                            borderColor={Colors.brand.electric}
                            padding={15}
                            onPress={() => console.log('NA')}
                        />
                    </ImageUploader>
                </View>
                </View>
            </ScrollView>
            {walkthrough ?
            <View nativeID='action_row' style={{ flexDirection:'row', justifyContent:'space-between', padding:10 }}>
                <Button
                    title='EXIT SETUP'
                    title_color={Colors.utility.error}
                    backgroundColor='transparent'
                    onPress={() => onClose()}
                />
                <TouchableOpacity 
                    style={loading?button_styles.wizard_invalid:button_styles.wizard_valid}
                    onPress={() => onNext()}
                    disabled={loading}
                    >
                    <Icons.ChevronIcon size={20} direction='right' color={Colors.brand.midnight}/>
                </TouchableOpacity>
            </View>
            :
            <View nativeID='action_row' style={{ flexDirection:'row', justifyContent:'space-between', padding:10 }}>
                <Button
                    style={{ flex:1 }}
                    title='BACK'
                    padding={15}
                    title_color={Colors.brand.electric}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    onPress={() => onClose()}
                />
            </View>
            }
        </View>
    )
}

export default ProfilePicManager