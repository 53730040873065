import React from 'react';
import { Button } from "../Components"
import Colors from "../constants/colors"
import type { PlayerFollowerProps } from "../types"
import { SocialComponentApi } from './api';

type PlayerFollowButtonProps = {
    player_id:string,
    style?:any,
    loading?:boolean,
    follower?:PlayerFollowerProps,
    following?:PlayerFollowerProps
    onFollowingUpdate:(pf:PlayerFollowerProps) => void
}

const PlayerFollowButton = ({ player_id, loading, style, follower, following, onFollowingUpdate }:PlayerFollowButtonProps) => {


    const handleAction = async(status:'following'|'not_following', player_follower?:PlayerFollowerProps) => {
        if(!player_follower){
            player_follower = {
                player_follower_id:'',
                player_id: '',
                following_id: player_id,
                status,
                create_datetime:'', last_update_datetime:''
            }
        }
        player_follower.status = status
        const new_following = await SocialComponentApi.savePlayerFollower(player_follower)
        onFollowingUpdate(new_following)
    }


    const getButtonType = () => {
        let button_type = { type: 'follow', label: 'FOLLOW' };
        if(follower?.status == 'following'){ button_type = { type: 'follow', label: 'FOLLOW BACK' } }
        if(following?.status == 'following'){ button_type = { type: 'unfollow', label: 'UNFOLLOW' } }

        switch(button_type.type){
            case 'follow':
                return (
                    <Button
                        style={{ ...style }}
                        loading={loading}
                        padding={10}
                        title_size={12}
                        disabled={loading}
                        title={button_type.label}
                        title_color={Colors.shades.white}
                        backgroundColor={Colors.brand.electric}
                        onPress={() => handleAction('following', following)}
                    />
                )
            case 'unfollow':
                return (
                    <Button
                        style={{ ...style }}
                        title={button_type.label}
                        title_size={12}
                        padding={10}
                        loading={loading}
                        disabled={loading}
                        title_color={Colors.brand.electric}
                        borderWidth={1}
                        borderColor={Colors.brand.electric}
                        onPress={() => handleAction('not_following', following)}
                    />
                )
            default: return <></>
        }
        
    }

    return getButtonType()

    
}

export default PlayerFollowButton