import React, { useState } from 'react';
import { View, TouchableOpacity, FlatList, ScrollView } from "react-native";
import type { AccountActionProps, AccountProps, ItemOrderProps, PlayerDepositLimitProps, PlayerWithdrawLimitProps, WalletSettingsProps } from '../../types';
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import { view_styles } from '../../constants/styles';
import { WalletHelpers } from '../api';
import DepositCard from './DepositCard';
import type { MyPlayerProps, PlayerBalanceProps } from '../../ProfileManager/api/types';
import WithdrawCard from './WithdrawCard';
import AccountLimitManager from './AccountLimitManager';
import AccountSnoozeManager from './AccountSnoozeManager';
import RemoveAccountCard from './RemoveAccount';

type ManageAccountCardProps = {
    player:MyPlayerProps,
    wallet_settings:WalletSettingsProps,
    player_balance:PlayerBalanceProps,
    account:AccountProps,
    deposit_limit:PlayerDepositLimitProps,
    withdraw_limit:PlayerWithdrawLimitProps,
    onClose:() => void,
    onAccountUpdate:(acct:AccountProps) => void,
    onTransact:(draft_order:ItemOrderProps, account_id?:string) => void
}

const ManageAccountCard = ({ player, wallet_settings, player_balance, account, deposit_limit, withdraw_limit, onAccountUpdate, onTransact, onClose }:ManageAccountCardProps) => {
    const [ active_action, setActiveAction ] = useState('select');

    const account_actions = WalletHelpers.getAccountOptions(account)

    const renderIcon = (action:AccountActionProps) => {
        switch(action.action_type){
            case 'deposit':
                return <Icons.DepositIcon color={Colors.shades.white} size={20} />
            case 'withdraw':
                return <Icons.WithdrawIcon color={Colors.shades.white} size={20} />
            case 'limits':
                return <Icons.BankIcon color={Colors.shades.white} size={28}/>
            case 'snoozes':
                return <Icons.SnoozeIcon color={Colors.shades.white} size={18} />
            case 'remove':
                return <Icons.CloseIcon color={Colors.shades.white} size={14} />
            default: return <></>
        }
    }

    const renderAccountActions = (data: { item:AccountActionProps, index:number }) => {
        if(!account){ return <></> }
        if(account.ibt_placeholder && !['deposit'].includes(data.item.action_type)){ return <></> }
        if(data.item.action_type == 'withdraw' && account.account_type == 'paypal'){ return <></> }
        if(data.item.action_type == 'remove' && account.ibt_placeholder){ return <></> }
        if(data.item.action_type == 'withdraw' && account.account_type == 'card'){ return <></> }
        if(data.item.action_type == 'remove' && account.account_type === 'paypal'){ return <></> }

        //if(data.item.action_type == 'deposit' && account.account_type == 'card' && account?.card_detail?.card_type != 'debit'){ return <></> }
        return (
            <TouchableOpacity style={{ flex:1, minHeight:80, margin:8, backgroundColor:Colors.shades.white, borderRadius:8, ...view_styles.float, padding:10 }} onPress={() => setActiveAction(data.item.action_type)}>
                <View style={{flex:1, flexDirection:'row', alignItems:'center'}}>
                    <View style={{height:36, width:36, backgroundColor:Colors.brand.electric, justifyContent:'center', alignItems:'center', borderRadius:3.6 }}>
                        {renderIcon(data.item)}
                    </View>                            
                    <View style={{marginLeft:16, marginRight:10, flex:1, padding:2}}>
                        <Text size={14} weight='bold' color={Colors.brand.cobalt}>{data.item.action_title}</Text>
                        <Text style={{ marginTop:2 }} size={12} weight='regular' color={Colors.brand.cobalt}>{data.item.action_description}</Text>
                    </View>
                    <Icons.ChevronIcon direction='right' color={Colors.brand.cobalt} size={12} />                       
                </View>
            </TouchableOpacity>
        )
    }

    return (
        <View style={{ flex:1 }}>
            <View style={{ padding:20 }}>
                <Text size={30} color={Colors.brand.midnight} weight='bold'>{account.account_label}</Text>
                {active_action == 'select' ?
                <Text style={{ marginTop:15 }} size={16} color={Colors.brand.midnight} weight='regular'>Below are the available actions that can be performed on this account!</Text>
                :<></>}
            </View>
            <View style={{ flex:1 }}>

                {active_action == 'select' ?
                <View style={{flex:1}}>
                    <ScrollView style={{flex:1, padding:20}}>
                        <FlatList data={account_actions} renderItem={renderAccountActions} />
                    </ScrollView>
                    <View style={{ padding:20 }}>
                        <Button
                            title='BACK'
                            padding={15}
                            borderWidth={1}
                            borderColor={Colors.brand.electric}
                            title_color={Colors.brand.electric}
                            onPress={() => onClose()}
                        />
                    </View>
                </View>
                :active_action == 'deposit' ?
                    <DepositCard
                        player={player}
                        wallet_settings={wallet_settings}
                        account={account}
                        deposit_limit={deposit_limit}
                        onTransact={onTransact}
                        onCancel={() => setActiveAction('select')}
                    />
                :active_action == 'withdraw'?
                    <WithdrawCard
                        player={player}
                        deposit_limit={deposit_limit}
                        wallet_settings={wallet_settings}
                        player_balance={player_balance}
                        account={account}
                        withdraw_limit={withdraw_limit}
                        onTransact={onTransact}
                        onCancel={() => setActiveAction('select')}
                    />
                :active_action == 'limits'?
                    <AccountLimitManager
                        account={account}
                        onCancel={() => setActiveAction('select')}
                        onSetLimit={(al) => console.log(al)}
                    />
                :active_action == 'snoozes'?
                    <AccountSnoozeManager
                        account={account}
                        onCancel={() => setActiveAction('select')}
                        onSnooze={(snz) => console.log(snz)}
                    />
                :active_action == 'remove'?
                    <RemoveAccountCard
                        account={account}
                        onCancel={() => setActiveAction('select')}
                        onRemove={(acct) => {
                            onAccountUpdate(acct)
                            onClose()
                        }}
                    />
                :<></>}
            </View>
        </View>
    )
}

export default ManageAccountCard