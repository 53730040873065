import React from 'react';
import { useSpring, animated } from '@react-spring/web';

type SpringViewProps = {
    style?: any,
    slide: 'vertical'|'horizontal'
    from: number,
    onResolve?:() => void,
    to:number,
    children: any
}
const SpringView = ({ style, children, from, to, slide, onResolve }:SpringViewProps) => {

    let config:any = {
        from: { x: from},
        to: { x: to },
        friction: 120,
        tension: 280,
        //...style
    }
    if(slide == 'vertical'){
        config = {
            from: { y: from},
            to: { y: to },
            //delay: 500,
            onResolve: () => { if(onResolve){ onResolve() } },
            //...style
           // easing: easings.easeInBounce
        }
    }



    const props = useSpring(config)

    return (
        <animated.div
            onAnimationEnd={() => console.log('animation has ended')}
            style={{ ...props, ...style }}
            
        >
            {children}
        </animated.div>
    )
}

export default SpringView