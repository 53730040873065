import React from 'react';
import { View, Image, ScrollView } from 'react-native';
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import type { CodeRequestProps, PlayerReferralProps, PromoProps, PublicPlayerProps } from '../../types';
import { view_styles } from '../../constants/styles';
import type { MyPlayerProps } from '../api/types';

type BasicStatusProps = {
    player:MyPlayerProps,
    code_details: {
        code_request?:CodeRequestProps,
        referrer?:PublicPlayerProps,
        promo?:PromoProps,
        player_referral?:PlayerReferralProps
    }
    onClose:() => void,
    onVerify:() => void
}

const BasicStatus = ({ onVerify, code_details, player, onClose }:BasicStatusProps) => {

    const { code_request, promo } = code_details;

    const getStatus = (p:MyPlayerProps) => {
        let errors:string[] = []
        if(!p.first_name || !p.last_name){ errors.push('A first and last name are required to use real money') }
        if(p.no_password){ errors.push('A password is required to use real money') }
        return errors
    }

    const errors = getStatus(player)

    return (
        <View style={{ flex:1 }}>
            <ScrollView style={{ flex:1 }}>
               
                <View style={{ justifyContent:'center', alignItems:'center' }}>
                    <Image
                        source={{ uri:'https://res.cloudinary.com/hoabts6mc/image/upload/v1714410165/Welcome_banner_bqu6cc.webp' }}
                        style={{ width:400, height:400, borderRadius:4 }}
                        resizeMode='cover'
                    />
                </View>
                <View style={{ padding:10, backgroundColor:Colors.shades.black_faded_super_heavy, marginTop:-100 }}>
                    <View style={{ padding:10, flexDirection:'row' }}>
                        <Icons.CheckCirlceIcon color={Colors.utility.success} size={32}/>
                        <View style={{ flex:1, marginLeft:20 }}>
                            <Text weight='bold' size={16} color={Colors.shades.white}>FREE TO PLAY - ENGAGED!</Text>
                            <Text style={{ marginTop:5 }} color={Colors.shades.white} size={14} textAlign='left'>Your profile is ready to go for play money betting and all our FREE TO PLAY games.</Text>
                        </View>
                    </View> 
                    {player.vouched_status != 'verified' ? 
                    <View style={{ padding:10, flexDirection:'row' }}>
                        <Icons.AlertIcon color={Colors.utility.warning} size={32}/>
                        <View style={{ flex:1, marginLeft:20 }}>

                        <Text weight='bold' size={16} color={Colors.shades.white}>WANT TO USE REAL MONEY?</Text>
                        {errors.length == 0 ?
                        <Text style={{ marginTop:5 }} color={Colors.shades.white} size={14} textAlign='left'>Continue on your journey and complete the verification steps in just a couple minutes.</Text>
                        :
                        <View style={{ marginTop:5 }}>
                            <Text size={14} color={Colors.utility.warning} weight='bold'>FIX THE FOLLOWING BEFORE CONTINUING</Text>
                            <View style={{ padding:10 }}>
                                {errors.map((e,i) => {
                                    return (
                                        <Text style={{ padding:2 }} size={14} color={Colors.utility.warning} weight='bold'>{i+1} - {e}</Text>
                                    )
                                })}
                            </View>
                        </View>
                        }
                        </View>
                    </View>
                    :
                    <View style={{ padding:10, flexDirection:'row' }}>
                        <Icons.CheckCirlceIcon color={Colors.utility.success} size={32}/>
                        <View style={{ flex:1, marginLeft:20 }}>
                            <Text weight='bold' size={16} color={Colors.shades.white}>REAL MONEY - ENGAGED!</Text>
                            <Text style={{ marginTop:5 }} color={Colors.shades.white} size={14} textAlign='left'>Way to go! Your profile is ready to go for real-money activities!</Text>
                        </View>
                    </View> 
                    }
                </View>  
            </ScrollView>
            {code_request && !['closed','fulfilled'].includes(code_request.status) && promo ?
            <View style={{ margin:15, marginTop:-50, flexDirection:'row', backgroundColor:Colors.shades.white, borderRadius:8, ...view_styles.float }}>
                <View style={{ padding:10, justifyContent:'center' }}>
                    <Icons.GiftIcon size={40} color={Colors.incentive.gold} />
                </View>
                <View style={{flex:1, backgroundColor:Colors.incentive.gold_faded, padding:10 }}>
                    <Text style={{ paddingBottom:10, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }} size={16} color={Colors.brand.midnight} weight='bold'>Verify to claim your gift!</Text>
                    <Text style={{ paddingTop:5 }} size={12} color={Colors.brand.midnight} weight='semibold'>Did you know on average it only takes 3 minutes to complete the verification process?</Text>
                </View>
            </View>
            :<></>}
            {player.vouched_status != 'verified' ?
            <View nativeID='action_row' style={{ flexDirection:'row', padding:10 }}>
                <Button
                    title='DO IT LATER'
                    style={{ flex:3, marginRight:5 }}
                    padding={15}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    title_color={Colors.brand.electric}
                    onPress={() => onClose()}
                />
                <Button
                    title='VERIFY ACCOUNT'
                    style={{ flex:5, opacity: errors.length > 0 ? 0.5 : 1 }}
                    padding={15}
                    disabled={errors.length > 0}
                    backgroundColor={Colors.utility.success}
                    title_color={Colors.shades.white}
                    onPress={() => onVerify()}
                />
            </View>
            :
            <View nativeID='action_row' style={{ flexDirection:'row', padding:10 }}>
                <Button
                    title='CLOSE'
                    style={{ flex:3, marginRight:5 }}
                    padding={15}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    title_color={Colors.brand.electric}
                    onPress={() => onClose()}
                />
            </View>
            }
        </View>
    )
}

export default BasicStatus