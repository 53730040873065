import React from 'react';
import { View } from 'react-native';
import { Button, Text } from '../../Components';
import type { PlayerAddressProps } from '../../types';
import Colors from '../../constants/colors';

type ShippingSummaryCardProps = {
    onChangeAddress: () => void,
    player_address?:PlayerAddressProps
}

const ShippingSummaryCard = ({ player_address, onChangeAddress }:ShippingSummaryCardProps) => {

    return (
        <View>
            <View style={{ padding:5, flexDirection:'row', alignItems:'center' }}>
                <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='semibold'>Shipping Address</Text>
                <Button
                    title={player_address ? 'Change' : 'Select Address'}
                    title_color={Colors.brand.electric}
                    backgroundColor='transparent'
                    onPress={() => onChangeAddress()}
                />
            </View>
            {player_address ?
            <View style={{ padding:5, marginTop:10 }}>
                <Text size={12} color={Colors.brand.midnight}>{player_address.street}</Text>
                <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight}>{player_address.city}, {player_address.state} {player_address.zip}</Text>
            </View>
            :<></>}
        </View>
    )
}

export default ShippingSummaryCard