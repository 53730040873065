import React from 'react';
import { View, TouchableOpacity, Image, ScrollView } from 'react-native';
import type { MyPlayerProps } from '../../ProfileManager/api/types';
import type { AccountProps, CodeRequestProps, PlayerReferralProps, PromoProps, PublicPlayerProps, RewardOptionProps } from '../../types';
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import { button_styles, view_styles } from '../../constants/styles';

type FundAccountCardProps = {
    player:MyPlayerProps,
    accounts:AccountProps[],
    code_details: {
        code_request?:CodeRequestProps,
        referrer?:PublicPlayerProps,
        promo?:PromoProps,
        player_referral?:PlayerReferralProps,
        reward_option?:RewardOptionProps
    }
    walkthrough?:boolean,
    onManualACH: () => void,
    onCard:(account?:AccountProps) => void,
    onSelectAccount: (account:AccountProps) => void,
    onClose: () => void,
    onNext: () => void,
}

const FundAccountCard = ({ accounts, code_details, onClose, onCard, onNext, walkthrough, onManualACH, onSelectAccount }:FundAccountCardProps) => {
    
    const { code_request, promo, referrer } = code_details;

    const manual_account = accounts.find(a => a.ach_detail && a.ach_detail.ach_type == 'manual');
    const ibt_placeholder_account = accounts.find(a => a.ibt_placeholder);
    const ibt_account = accounts.find(a => a.ach_detail && a.ach_detail.ach_type == 'ibf' && !a.ibt_placeholder);
    const card_account = accounts.find(a => a.account_type == 'card');
    const paypal_account = accounts.find(a => a.account_type == 'paypal');

    return (
        <View style={{ flex:1 }}>
            <View style={{ padding:20 }}>
                <Text size={26} color={Colors.brand.midnight} weight='bold'>FUND YOUR BETTOREDGE WALLET</Text>
                <Text style={{ marginTop:10 }} size={16} color={Colors.brand.midnight} weight='regular'>In order to fund your real-money/USD ($) balance, simply make a deposit using the following options</Text>
            </View>
            <ScrollView style={{ flex:1, padding:20 }}>
                <View nativeID='fund_account_now'>
                <Text size={18} color={Colors.brand.midnight} weight='bold'>FUND YOUR ACCOUNT NOW</Text>
                {ibt_placeholder_account ?
                <View style={{ marginTop:15 }}>
                    <TouchableOpacity style={{padding:3, flexDirection:'row', alignItems:'center', borderRadius:30, backgroundColor:Colors.shades.white }}
                        onPress={() => onSelectAccount(ibt_placeholder_account)}>
                        <View style={{  height:50, width:50, borderRadius:100, justifyContent:'center', alignItems:'center', borderWidth:1, borderColor:Colors.brand.midnight }}>
                            <Icons.PlaidIcon size={22} color={Colors.brand.midnight} />
                        </View>
                        <View style={{ flex:1, marginLeft:10 }}>
                            <Text size={14} color={Colors.brand.midnight} weight='bold'>INSTANT BANK TRANSFER</Text>
                            <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular'>When you link an account you get access to both instant deposits and instant withdraws.</Text>
                        </View>
                    </TouchableOpacity>
                    {ibt_account ?
                    <View style={{ position:'absolute', top:5, right:5, bottom:0}}>
                        <Icons.TransactionSuccessIcon size={45}/>
                    </View>
                    :<></>}
                </View>
                :<></>}
                <View style={{ marginTop:10 }}>
                    <TouchableOpacity style={{ padding:3, flexDirection:'row', alignItems:'center', borderRadius:30, backgroundColor:Colors.brand.cyan }}
                        onPress={() => onCard(card_account)}>
                        <View style={{  height:50, width:50, borderRadius:100, justifyContent:'center', alignItems:'center', backgroundColor:Colors.shades.white }}>
                            <Icons.CreditCardIcon size={22} color={Colors.brand.cyan} />
                        </View>
                        <View style={{ flex:1, marginLeft:10 }}>
                            <Text size={14} color={Colors.shades.white} weight='bold'>DEBIT / CREDIT CARD</Text>
                            <Text style={{ marginTop:3 }} size={12} color={Colors.shades.white} weight='semibold'>Add a debit / credit card and deposit instantly.</Text>
                        </View>
                    </TouchableOpacity>
                    {card_account ?
                    <View style={{ position:'absolute', top:5, right:5, bottom:0}}>
                        <Icons.TransactionSuccessIcon size={45}/>
                    </View>
                    :<></>}
                </View>
                {paypal_account ?
                <View style={{ marginTop:10 }}>
                    <TouchableOpacity style={{ padding:3, flexDirection:'row', alignItems:'center', borderRadius:30, backgroundColor:Colors.company.paypal }}
                        onPress={() => onSelectAccount(paypal_account)}>
                        <View style={{  height:50, width:50, borderRadius:100, justifyContent:'center', alignItems:'center', backgroundColor:Colors.shades.white }}>
                            <Icons.PayPalIcon size={22}/>
                        </View>
                        <View style={{ flex:1, marginLeft:10 }}>
                            <Text size={14} color={Colors.company.paypal_blue} weight='bold'>PAYPAL</Text>
                            <Text style={{ marginTop:3 }} size={12} color={Colors.company.paypal_blue} weight='semibold'>Use your paypal account to deposit instantly.</Text>
                        </View>
                    </TouchableOpacity>
                </View>
                :<></>}
                </View>
                <View style={{ marginTop:20 }}>
                    <Text size={18} color={Colors.brand.midnight} weight='bold'>FUND LATER (~1-3 BUSINESS DAYS)</Text>
                    <View style={{ marginTop:15 }}>

                        <TouchableOpacity style={{ padding:3, flexDirection:'row', alignItems:'center', borderRadius:30, backgroundColor:Colors.brand.midnight }}
                        onPress={() => onManualACH()}>
                            <View style={{  height:50, width:50, borderRadius:100, justifyContent:'center', alignItems:'center', backgroundColor:Colors.shades.white }}>
                                <Icons.BankIcon size={22} color={Colors.brand.midnight} />
                            </View>
                            <View style={{ flex:1 }}>
                                <Text size={14} color={Colors.shades.white} textAlign='center' weight='bold'>ADD MANUAL ACH ACCOUNT</Text>
                            </View>
                        </TouchableOpacity>
                        {manual_account?
                        <View style={{ position:'absolute', top:5, right:5, bottom:0}}>
                            <Icons.TransactionSuccessIcon size={45}/>
                        </View>
                        :<></>}
                    </View>
                </View>
            </ScrollView>
            {code_request?.pending_deposit && promo && referrer && ['referral_code','referral_sweepstakes'].includes(promo.type) ?
            <View style={{ margin:15, flexDirection:'row', backgroundColor:Colors.shades.white, borderRadius:8, ...view_styles.float }}>
                <View style={{ justifyContent:'center' }}>
                    {referrer?.profile_pic?
                    <Image
                        source={{ uri: referrer.profile_pic }}
                        style={{ height: 60, width:60, borderRadius:4 }}
                        resizeMode='cover'
                    />
                    :
                    <Icons.GiftIcon size={40} color={Colors.incentive.gold} />
                    }
                </View>
                <View style={{flex:1, backgroundColor:Colors.incentive.gold_faded, padding:10 }}>
                    <Text style={{ paddingBottom:10, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }} size={14} color={Colors.brand.midnight} weight='bold'>Give back to {referrer?.username}</Text>
                    <Text style={{ paddingTop:5 }} size={12} color={Colors.brand.midnight} weight='semibold'>Make a qualifying deposit and {referrer.username} will receive a reward!</Text>
                </View>
            </View>
            :<></>}
            {walkthrough ?
            <View nativeID='action_row' style={{ flexDirection:'row', justifyContent:'space-between', padding:10 }}>
                <Button
                    title={!ibt_account && !manual_account ? 'DO THIS LATER' : 'All Set!'}
                    title_color={!ibt_account && !manual_account ? Colors.utility.error: Colors.utility.success}
                    backgroundColor='transparent'
                    onPress={() => onClose()}
                />
                <TouchableOpacity
                    style={manual_account || ibt_account ? button_styles.wizard_valid : button_styles.wizard_invalid}
                    disabled={!manual_account && !ibt_account ? true : false}
                    onPress={() => onNext()}
                >
                    <Icons.ChevronIcon size={20} direction='right' color={Colors.brand.midnight}/>
                </TouchableOpacity>
            </View>
            :<></>}
        </View>
    )
}

export default FundAccountCard