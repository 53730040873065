import React from 'react';
import { FlatList, View, TouchableOpacity, Image, ScrollView } from 'react-native';
import type { AccountProps } from '../../types';
import AccountCard from './AccountCard';
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';

type AccountManagerProps = {
    accounts:AccountProps[],
    onClose:() => void,
    onSelectAccount: (account:AccountProps) => void
    onSelectManualACH: () => void,
    onSelectManualCard: () => void,
    onSelectInstantBankTransfer: () => void
}

const AccountManager = ({ accounts,  onSelectAccount, onSelectManualACH, onSelectManualCard, onSelectInstantBankTransfer , onClose}:AccountManagerProps) => {

    const my_accounts = accounts.filter(a => !a.ibt_placeholder && !['bettoredge'].includes(a.account_type))
    //const ibt_placeholder = accounts.find(a => a.ibt_placeholder);

    const renderAccounts = (data:{item:AccountProps, index:number}) => {
        return (
            <View style={{ margin:5 }}>
                <AccountCard
                    account={data.item}
                    onSelectAccount={(account) => onSelectAccount(account)}
                />
            </View>
        )
    }

    return (
        <View style={{ flex:1 }}>
             <View style={{ padding:20 }}>
                <Text size={26} color={Colors.brand.midnight} weight='bold'>WALLET ACCOUNTS</Text>
                <Text style={{ marginTop:10 }} size={16} color={Colors.brand.midnight} weight='regular'>Below are the accounts we have on file for you.  Please select one to manage or transact!</Text>
            </View>
            <ScrollView style={{ flex:1, padding:10 }}>
                {my_accounts.length > 0 ?
                <View style={{ padding:10 }}>
                    <Text size={16} color={Colors.brand.midnight} weight='bold'>MY ACCOUNTS</Text>
                    <View style={{ marginTop:10 }}>
                        <FlatList
                            data={my_accounts}
                            renderItem={renderAccounts}
                            keyExtractor={(item) => item.account_id.toString()}
                        />
                    </View>
                </View>
                :<></>}
                <View style={{ padding:10, marginTop:20 }}>
                    <Text size={16} color={Colors.brand.midnight} weight='bold'>ADD NEW ACCOUNT</Text>
                    <TouchableOpacity style={{ marginTop:10, padding:3, flexDirection:'row', alignItems:'center', borderRadius:30, backgroundColor:Colors.shades.white }}
                    onPress={() => onSelectInstantBankTransfer()}>
                        <View style={{  height:50, width:110, borderRadius:100, justifyContent:'center', alignItems:'center', borderWidth:1, borderColor:Colors.brand.cobalt }}>
                            <Image
                                source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1695821528/1630344814441_wjq98b.png' }}
                                style={{ width:50, height:30 }}
                                resizeMode='cover'
                            />
                        </View>
                        <View style={{ flex:1, marginLeft:5 }}>
                            <Text size={10} color={Colors.utility.success} weight='bold'>RECOMMENDED</Text>
                            <Text size={14} color={Colors.brand.cobalt} textAlign='left' weight='bold'>INSTANT BANK TRANSFER</Text>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={{ marginTop:10, padding:3, flexDirection:'row', alignItems:'center', borderRadius:30, backgroundColor:Colors.brand.midnight }}
                    onPress={() => onSelectManualACH()}>
                        <View style={{  height:50, width:50, borderRadius:100, justifyContent:'center', alignItems:'center', backgroundColor:Colors.shades.white }}>
                            <Icons.BankIcon size={22} color={Colors.brand.midnight} />
                        </View>
                        <View style={{ flex:1 }}>
                            <Text size={14} color={Colors.shades.white} textAlign='center' weight='bold'>ADD MANUAL ACH ACCOUNT</Text>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={{ marginTop:10, padding:3, flexDirection:'row', alignItems:'center', borderRadius:30, backgroundColor:Colors.brand.cyan }}
                    onPress={() => onSelectManualCard()}>
                        <View style={{  height:50, width:50, borderRadius:100, justifyContent:'center', alignItems:'center', backgroundColor:Colors.shades.white }}>
                            <Icons.CreditCardIcon size={22} color={Colors.brand.cyan} />
                        </View>
                        <View style={{ flex:1 }}>
                            <Text size={14} color={Colors.shades.white} textAlign='center' weight='bold'>ADD NEW CARD ACCOUNT</Text>
                        </View>
                    </TouchableOpacity>
                
                </View>
            </ScrollView>
            <View nativeID='action_row' style={{ padding:20 }}>
                <Button
                    style={{ flex:1, opacity: 1 }}
                    title={'BACK'}
                    title_color={Colors.brand.electric}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    padding={15}
                    onPress={() => onClose()}
                />
            </View>
        </View>
    )
}

export default AccountManager