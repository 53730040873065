
import React from 'react';
import { TextInput as RNTextInput } from 'react-native';
import Colors from '../constants/colors';

type TextInputProps = {
    value?:string,
    key?:string,
    placeholder?:string,
    autoFocus?:boolean,
    keyboardType?:string,
    textContentType?:string,
    editable?:boolean,
    secureTextEntry?:boolean,
    onSubmitEditing?: (evt:any) => void,
    onBlur?: (evt:any) => void,
    placeholderTextColor?:string,
    onChangeText:(text:string) => void,
    style?:any
}

const TextInput = ({ onChangeText, key, placeholderTextColor, value, placeholder, style, autoFocus, keyboardType, textContentType, editable, secureTextEntry, onSubmitEditing, onBlur } : TextInputProps) => {

    return (
        <RNTextInput
            key={key}
            style={{ 
                padding:15, 
                borderRadius:22, 
                borderWidth:1,
                textAlign:'center', 
                fontFamily:'barlow-bold', 
                fontSize:16,
                borderColor:Colors.shades.shade600,
                backgroundColor:editable == false ? Colors.shades.shade100 : Colors.shades.white,
                ...style 
            }}
            editable={editable ?? true}
            placeholder={placeholder}
            value={value}
            onSubmitEditing={onSubmitEditing}
            onBlur={onBlur}
            secureTextEntry={secureTextEntry}
            placeholderTextColor={placeholderTextColor}
            autoFocus={autoFocus}
            textContentType={textContentType as any}
            keyboardType={keyboardType as any}
            onChangeText={onChangeText}
        />
    )
}

export default TextInput