
import { StyleSheet } from 'react-native';
import Colors from './colors';


export const view_styles = StyleSheet.create({
    container: {
        backgroundColor:Colors.shades.white,
        borderTopRightRadius:22,
        borderTopLeftRadius:22
    },
    float: {
        shadowColor: "rgba(0, 0, 0, 0.06)", shadowOffset: { width: 0, height: 10 }, shadowRadius: 10, shadowOpacity: 1
    }
})

export const button_styles = StyleSheet.create({
    wizard_valid: {
        backgroundColor:Colors.shades.white, height:50, width:50, justifyContent:'center', alignItems:'center', borderRadius:100, ...view_styles.float
    },
    wizard_invalid: {
        backgroundColor:Colors.shades.white, height:50, width:50, justifyContent:'center', alignItems:'center', borderRadius:100, opacity:0.5
    }
})


export const text_styles = StyleSheet.create({
    dark_heading_1: {
        fontSize: 16,
        color:Colors.brand.midnight
    },
    dark_heading_2: {
        fontSize: 14,
        color:Colors.brand.midnight
    },
    dark_heading_3: {
        fontSize: 12,
        color:Colors.brand.midnight
    },
    light_heading_1: {
        fontSize: 16,
        color:Colors.shades.white
    },
    light_heading_2: {
        fontSize: 14,
        color:Colors.shades.white
    },
    light_heading_3: {
        fontSize: 12,
        color:Colors.shades.white
    }
})