import React, { useEffect, useState } from 'react';
import { View, Platform, TouchableOpacity, Image, ScrollView } from 'react-native';
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import { ProfileApi, ProfileHelpers } from '../api';
import moment from 'moment-mini';
import type { CodeRequestProps, PlayerReferralProps, PromoProps, PublicPlayerProps } from '../../types';
import { view_styles } from '../../constants/styles';
import type { MyPlayerProps } from '../api/types';

type VouchCardProps = {
    player:MyPlayerProps,
    walkthrough?:boolean,
    onVouching:(active:boolean) => void,
    code_details: {
        code_request?:CodeRequestProps,
        referrer?:PublicPlayerProps,
        promo?:PromoProps,
        player_referral?:PlayerReferralProps
    }
    onPlayerUpdate:(player:MyPlayerProps) => void,
    onClaimGift:() => void,
    onClose:() => void
}

const VouchCard = ({ player, walkthrough, code_details, onVouching, onPlayerUpdate, onClaimGift, onClose }:VouchCardProps) => {
    const [ vouching, setVouching ] = useState(false);
    const [ view_height, setViewHeight ] = useState(0);
    const [ job_complete, setJobComplete ] = useState(false);
    const [ vouched, setVouched ] = useState<any | undefined>(undefined)
    const [ disclaimer_confirmed, setDisclaimerConfirmed ] = useState(false);
    const [ id_disclaimer_confirmed, setIDDisclaimerConfirmed ] = useState(false);
    const [ info_visible, setInfoVisible ] = useState(false);
    
    const { code_request, promo } = code_details;

    useEffect(() => {
        getPlayerFromServer()
    },[])

    const getPlayerFromServer = async() => {
        const me = await ProfileApi.getMyDetails();
        onPlayerUpdate(me)
    }

    useEffect(() => {
        if(onVouching){ onVouching(vouching) }
    },[vouching])

    useEffect(() => {
        initializeVouch()
        if(!vouched){ return }
        vouched.mount('#vouched-element')
        const tokens = ProfileApi.getVouchKeys()
        console.log(tokens)
    },[vouched])

    const initializeVouch = () => {
        if(Platform.OS != 'web' || !window || !document){ return }
        if(window.Vouched){ return }
        let script = document.createElement('script');
        script.id = 'vouch';
        script.src = 'https://static.vouched.id/plugin/releases/latest/index.js'
        script.async = true;
        script.defer = true;
        script.onload = () => console.log('loaded!!!')
        script.onerror = (e) => console.log(e);
        document.body.appendChild(script);
    }

    const checkPlayerStatus = async() => {
        const new_player = await ProfileApi.getMyDetails()
        onPlayerUpdate(new_player)
    }

    const verifyErrors = (p:MyPlayerProps) => {
        let errors:string[] = []
        if(!p.first_name || !p.last_name){ errors.push('Please include your name.  This must match your ID exactly.') }
        if(!p.dob || p.dob == ''){ errors.push('Please include your date of birth. This must match your ID exactly.') }
        if(p.dob){ 
            let age_diff = moment().diff(moment(p.dob), 'years')
            if(age_diff < 18){ errors.push('Must be over the age of 18 to participate in real money activities on BettorEdge') }
        }
        if(!p.verified){ errors.push('A valid and verified email must be associated with your account before we can verify') }
        if(!p.phone_verified){ errors.push('A valid and verified phone number must be associated with your account before we can verify') }
        if(p.no_password){ errors.push('Please add a password for your account before you continue to verify') }
        return errors
    }

    const startVouchingPlayer = async() => {
        if(vouching || !player){ return }
        //1) Lets make sure vouched js script has been initialized
        if(!window.Vouched){ return alert('not ready') }
        setVouching(true)


        setVouched(window.Vouched({
            verification: {
                // verify the user's information 
                firstName: player.first_name,
                lastName: player.last_name,
                // used for the crosscheck feature
                email: player.email,
                phone: player.phone,
                enableIPAddress: true
              },
              id: 'camera',
              face: 'camera',
              showUploadFirst: false,
              //token:player_vouch_token?.token,
              appId: ProfileApi.getVouchKeys().VOUCH_ID,
              //appId: "adqEZph8t*_NBFy6u_l4!t~Hin6kpK", //SANDBOX
              // your webhook for POST verification processing
              callbackURL: ProfileApi.getVouchKeys().VOUCHED_CALLBACK_URL,
              sandbox: ProfileApi.getVouchKeys().VOUCHED_SANDBOX,
              // mobile handoff
              crossDevice: true,
              crossDeviceQRCode: true,
              includeBackId: true,
              //includeBarcode:true,
              cameraScreenLabelBackId: 'Please turn ID over to capture back',
              onInit: async({token, job}:any) => {
                console.log(token, job)
                // If crossDevice is true, a web token is created during initialization 
                // Your backend will save and associate the token the user.
                //fetch(`/yourapi/idv?job_token=${job.token}&&user=${user.id}`);
                //let pvt:PlayerVouchTokenProps = {
                //    player_vouch_token_id:'', player_id:player.player_id, token, create_datetime:'', last_update_datetime:'', status:'active'
                //}
                //await player_saveVouchToken(pvt)
              },
      
              onDone: ()=> {

                console.log('vouch complete!')
                setVouched(undefined)
                setTimeout(() => {
                    setVouching(false)
                    setJobComplete(true);
                    checkPlayerStatus()
                }, 8000);
                // Redirect to the next page based on the job success
              },
              // theme
              theme: {
                name: 'verbose',
              },
          }));
    }

    if(Platform.OS != 'web'){ return <></> }

    if(vouched){
        return (
            <View style={{ flex:1, borderRadius:8, backgroundColor:Colors.shades.shade100, paddingBottom:10 }}>
                <View nativeID='vouch_header' style={{ padding:10 }}>
                    <Text size={16} color={Colors.brand.midnight} weight='semibold'>Verify your account</Text>
                    <Text style={{ marginTop:3 }} color={Colors.brand.midnight} size={14} weight='light'>Follow the prompts below to verify your identity instantly.</Text>
                </View>
                <ScrollView style={{ flex:1 }}>
                    <div style={{ height: view_height }} id='vouched-element'/>
                </ScrollView>
            </View>
            
        )
    }

    const verify_errors = verifyErrors(player)


    let scan_ready = disclaimer_confirmed && id_disclaimer_confirmed ? true : false
    let dob = player?.dob ?? ''
    let split_dob = dob.split('T')
    if(split_dob[0]){
        split_dob = split_dob[0].split('-')
    }
    let new_date = `${split_dob[1]}/${split_dob[2]}/${split_dob[0]}`

    return (
        <View style={{ flex:1 }} onLayout={(ev) => {
            const { height } = ev.nativeEvent.layout;
            setViewHeight(height)
            }}>
            <ScrollView style={{ flex:1 }}>
                <View style={{ padding:20 }}>
                    <Text size={30} color={Colors.brand.midnight} weight='bold'>Help us identify you</Text>
                    <Text style={{ marginTop:15 }} size={16} color={Colors.brand.midnight} weight='regular'>We need to take extra steps to verify your account. Scan your Driver's License or state issued ID and take a selfie for instant verification</Text>
                </View>
                <TouchableOpacity style={{padding:10, borderRadius:8, backgroundColor:Colors.highlights.highlight500Faded, margin:20}} onPress={() => setInfoVisible(!info_visible)}>
                    <View style={{ flexDirection:'row', alignItems:'center' }}>
                        <Text style={{ flex:1 }} size={14} weight='semibold' color={Colors.brand.midnight}>Why is this step required?</Text>
                        <Icons.ChevronIcon direction={info_visible?'up':'down'} size={10} color={Colors.brand.midnight} />
                    </View>
                    {info_visible ?
                    <Text size={12} weight='regular' color={Colors.brand.midnight} style={{ marginTop:4 }}>When betting with real money, legally, BettorEdge has to verify your identity to make sure you are old enough to bet and that you are who you say you are.</Text>
                    :<></>}
                </TouchableOpacity>
                <View style={{ alignSelf:'center' }}>
                    <Image 
                        source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1640215120/h4_2_media_fyiuoy.webp' }} 
                        style={{ height: 150, width:300 }}
                        resizeMode='contain'
                        />
                </View>
                {player.vouched_status == 'unverified' ? 
                <View style={{ padding:10 }}>
                    <TouchableOpacity 
                    style={{ flexDirection:'row', alignItems:'center', padding:10, marginLeft:15, marginRight:15, borderRadius:8, backgroundColor:Colors.shades.shade100, marginTop:5 }}
                    onPress={() => setDisclaimerConfirmed(!disclaimer_confirmed)}>
                        <View>
                        <View style={{ justifyContent:'center', alignItems:'center', borderWidth:1, borderColor:Colors.brand.midnight, borderRadius:4, height:20, width:20 }}>
                            {disclaimer_confirmed ?
                            <Icons.CheckIcon size={14} color={Colors.brand.midnight} />
                            :<></>}
                        </View>
                    </View>
                        <Text style={{ marginLeft:10 }} size={14} color={Colors.brand.midnight} weight='regular'>I confirm that my name is <Text size={12} weight='bold'>{player.first_name} {player.last_name}</Text> and that my date of birth is <Text size={12} weight='bold'>{new_date}</Text>. Mismatch will cause failure.</Text>
                    </TouchableOpacity>
                    <TouchableOpacity 
                    style={{ flexDirection:'row', marginTop:5, alignItems:'center', padding:10, marginLeft:15, marginRight:15, borderRadius:8, backgroundColor:Colors.shades.shade100 }}
                    onPress={() => setIDDisclaimerConfirmed(!id_disclaimer_confirmed)}
                    >
                    <View>
                        <View style={{ justifyContent:'center', alignItems:'center', borderWidth:1, borderColor:Colors.brand.midnight, borderRadius:4, height:20, width:20 }}>
                            {id_disclaimer_confirmed ?
                            <Icons.CheckIcon size={14} color={Colors.brand.midnight} />
                            :<></>}
                        </View>
                    </View>
                    <Text style={{ marginLeft:10 }} size={14} color={Colors.brand.midnight} weight='regular'>I confirm that I am using a valid <Text size={12} weight='bold'>(Not Expired)</Text> US government issued ID. Expired ID will cause failure.</Text>
                    </TouchableOpacity>
                </View>
                :<></>}
                {code_request && !['fulfilled','closed'].includes(code_request.status) && promo ?
                <TouchableOpacity 
                    disabled={player.vouched_status!='verified' ? true : false}
                    style={{ margin:15, backgroundColor:Colors.shades.white, borderRadius:8, ...view_styles.float }}
                    onPress={() => onClaimGift()}
                    >
                    <View style={{ flexDirection:'row' }}>
                        <View style={{ padding:10, justifyContent:'center', alignItems:'center' }}>
                            <Icons.GiftIcon size={40} color={Colors.incentive.gold} />
                            {player.vouched_status == 'verified' ? 
                            <Text style={{ marginTop:5 }} textAlign='center' size={12} color={Colors.incentive.gold}>CLAIM NOW</Text>
                            :<></>}
                        </View>
                        <View style={{flex:1, backgroundColor:Colors.incentive.gold_faded, padding:10 }}>
                            <Text style={{ paddingBottom:10, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600 }} size={16} color={Colors.brand.midnight} weight='bold'>{player.vouched_status == 'verified'?`Congrats! Press Here to claim your gift!`: 'Almost there! Last step to get your gift!'}</Text>
                            <Text style={{ paddingTop:5 }} size={12} color={Colors.brand.midnight} weight='semibold'>{ProfileHelpers.getReferralDescription(promo)}</Text>
                        </View>
                    </View>
                    {player.vouched_status == 'verified' ?
                    <View style={{ position:'absolute', top: -60, right:0, left:0, justifyContent:'center', alignItems:'center' }}>
                        <View style={{ borderRadius:22, padding:10, marginBottom:7, backgroundColor:Colors.shades.white }}>
                            <Text size={14} color={Colors.incentive.gold} weight='bold'>CLAIM ME NOW!</Text>
                        </View>
                        <Icons.ChevronIcon direction='down' size={8} color={Colors.incentive.gold} />
                    </View>
                    :<></>}
                </TouchableOpacity>
                :<></>}
            </ScrollView>
            
            {player.vouched_status == 'failed' ?
            <View style={{ margin:10, borderRadius:8, padding:10, backgroundColor:Colors.utility.warning }}>
                <Text  textAlign='center' size={14} weight='bold' color={Colors.shades.white}>We are currently reviewing your account.  If you do not hear from us within 48 hours, please reach out to support@bettoredge.com</Text>
            </View>
            :<></>}
            <View nativeID='action_row' style={{ flexDirection:'row', padding:10 }}>   
                {walkthrough ?
                <Button
                    title='EXIT SETUP'
                    title_color={Colors.utility.error}
                    backgroundColor='transparent'
                    onPress={() => onClose()}
                />
                :
                <Button
                    style={{ flex:1, marginRight:5 }}
                    title='BACK'
                    padding={15}
                    title_color={Colors.brand.electric}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    onPress={() => onClose()}
                />
                }
                {player.vouched_status == 'unverified' ?
                <Button
                    title={!job_complete ? 'SCAN MY ID' : 'REVIEWING ACCOUNT'}
                    title_color={Colors.shades.white}
                    loading={vouching}
                    disabled={!scan_ready || job_complete}
                    style={{ flex:3, opacity:scan_ready && scan_ready&&!vouching?1:0.5 }}
                    padding={15}
                    backgroundColor={Colors.utility.success}
                    onPress={() => startVouchingPlayer()}
                />

                : player.vouched_status == 'failed' ?
                <View style={{ flex:3, margin:4, borderRadius: 8, padding:10, backgroundColor:Colors.shades.shade100 }}>
                    <Text size={16} color={Colors.brand.midnight} textAlign='center' weight='semibold'>Account In Review</Text>
                </View>
                :
                <View style={{ flex:3, margin:4, borderRadius: 8, padding:10, backgroundColor:Colors.highlights.highlight500 }}>
                    <Text size={16} color={Colors.brand.midnight} textAlign='center' weight='semibold'>You are verified!</Text>
                </View>
                }
            </View>
            {verify_errors.length > 0 ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'center', alignItems:'center', padding:20 }}>
                <View style={{ backgroundColor:Colors.shades.white, padding:20, borderRadius:8 }}>
                    <View style={{ padding:10 }}>
                        <Text size={18} color={Colors.brand.midnight} weight='bold'>ERRORS:</Text>
                        <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight}>Please fix the following before continuing to verify</Text>
                    </View>
                    <View style={{ padding:10, backgroundColor:Colors.shades.shade100, borderRadius:8 }}>
                        {verify_errors.map((e,i) => {
                            return (
                                <View style={{ padding:5, flexDirection:'row', alignItems:'center' }}>
                                    <Text size={12} color={Colors.utility.warning} weight='bold'>{i+1} - {e}</Text>

                                </View>
                            )
                        })}
                    </View>
                </View>
            </View>
            :<></>}
        </View>
    )
}

export default VouchCard