import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View, ScrollView } from "react-native";
import type { AccountProps, ItemOrderProps, PlayerDepositLimitProps, PlayerWithdrawLimitProps, WalletSettingsProps } from '../../types';
import { ItemOrderApi, ItemOrderHelpers } from '../api';
import type { MyPlayerProps, PlayerBalanceProps } from '../../ProfileManager/api/types';
import Colors from '../../constants/colors';
import { Button, Text, TextInput } from '../../Components';
import { view_styles } from '../../constants/styles';


type WithdrawCardProps = {
    player:MyPlayerProps,
    wallet_settings:WalletSettingsProps,
    player_balance:PlayerBalanceProps,
    account:AccountProps,
    deposit_limit:PlayerDepositLimitProps,
    withdraw_limit:PlayerWithdrawLimitProps,
    onTransact:(draft_order:ItemOrderProps) => void,
    onCancel:() => void
}
const WithdrawCard = ({ wallet_settings, onTransact, player_balance, account, withdraw_limit, deposit_limit , onCancel}:WithdrawCardProps) => {
    const [ draft_order, setDraftOrder ] = useState<ItemOrderProps>();

    useEffect(() => {
        getItemFromServer()
    },[])

    const getItemFromServer = async() => {
        const item = await ItemOrderApi.getItemByIdentifier('withdraw')
        setDraftOrder(ItemOrderHelpers.genWithdrawOrder(item, account))
    }


    const { errors, available_for_withdraw } = ItemOrderHelpers.isWithdrawOrderValid(account, withdraw_limit, deposit_limit, player_balance, wallet_settings, draft_order)

    const handleTransact = () => {
        if(!draft_order){ return }
        if(errors.length > 0){ return }
        onTransact({ ...draft_order, total_amount: draft_order.amount as number })
    }


    if(!draft_order){
        return (
            <View style={{ padding:20 }}>
                <ActivityIndicator style={{ alignSelf:'center' }} size='large' color={Colors.brand.midnight} />
            </View>
        )
    }


    let balance = Math.floor(player_balance.balance * 100) / 100
    let limit_available = withdraw_limit.ach_daily_limit - withdraw_limit.last_days_ach_withdraws
    return (
        <View style={{ flex:1 }}>
            <View nativeID='deposit_rules' style={{ padding:10 }}>
                <Text size={28} textAlign='center' weight='bold' color={Colors.brand.midnight}>Withdraw to your bank</Text>
                <Text style={{ marginTop:10 }} size={14} textAlign='center' weight='regular' color={Colors.brand.midnight}>Withdraws can take up to 3 business days</Text>
            </View>
            <ScrollView style={{ flex:1 }}>
            <View style={{ flex:1 }}>
                <View nativeID='deposit_amount' style={{ justifyContent:'center', alignItems:'center', padding:20 }}>
                    <TextInput 
                        placeholder='0'
                        value={draft_order.amount as string}
                        onChangeText={(text) => setDraftOrder({ ...draft_order, amount:text })}
                    />
                </View>
            </View>
            <View nativeID='withdraw_limits' style={{ backgroundColor:Colors.shades.shade100, padding:20 }}>
                <View style={{ flexDirection:'row', alignItems:'center', padding:10 }}>
                    <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='semibold'>Weekly Withdraws Available</Text>
                    <Text size={14} color={Colors.brand.midnight} weight='bold'>{withdraw_limit.withdraws_per_week - withdraw_limit.week_withdraw_count}</Text>
                </View>
                <View style={{ flexDirection:'row', alignItems:'center', padding:10, paddingTop:0 }}>
                    <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='semibold'>Available For Withdraw</Text>
                    <Text size={14} color={Colors.brand.midnight} weight='bold'>{Math.min(available_for_withdraw, limit_available).toFixed(2)}</Text>
                </View>
                <View style={{ marginTop:5, borderRadius:8, padding:10, backgroundColor:Colors.shades.shade600 }}>
                    <Text size={14} color={Colors.brand.midnight} textAlign='left' weight='semibold'>Determined by the LESSER OF</Text>
                    <View nativeID='available_to_withdraw_calculation' style={{ margin:5,  marginTop:10, padding:10, backgroundColor:Colors.shades.white, borderRadius:4 }}>
                        <View style={{ padding:5, flexDirection:'row', alignItems:'center' }}>
                            <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='regular'>My Balance</Text>
                            <Text size={14} color={Colors.brand.midnight} weight='semibold'>${balance}</Text>
                        </View>
                        <View style={{ padding:5, flexDirection:'row', alignItems:'center', paddingLeft:15 }}>
                            <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='regular'>Instant Deposits (unsettled)</Text>
                            <Text size={14} color={Colors.utility.error} weight='semibold'>${deposit_limit.instant_deposits.toFixed(2)}</Text>
                        </View>
                        <View style={{ padding:5, flexDirection:'row', alignItems:'center', borderTopWidth:1, borderTopColor:Colors.shades.shade600 }}>
                            <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='bold'>Withdrawable Balance</Text>
                            <Text size={14} color={Colors.brand.midnight} weight='bold'>${available_for_withdraw.toFixed(2)}</Text>
                        </View>
                    </View>

                    <View nativeID='daily_limit' style={{ margin:5, padding:10, backgroundColor:Colors.shades.white, borderRadius:4 }}>
                        <View style={{ padding:5, flexDirection:'row', alignItems:'center' }}>
                            <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='regular'>Daily Limit</Text>
                            <Text size={14} color={Colors.brand.midnight} weight='semibold'>${withdraw_limit.ach_daily_limit.toFixed(2)}</Text>
                        </View>
                        <View style={{ padding:5, flexDirection:'row', alignItems:'center', paddingLeft:15}}>
                            <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='regular'>Last Days Withdraws</Text>
                            <Text size={14} color={Colors.utility.error} weight='semibold'>${withdraw_limit.last_days_ach_withdraws.toFixed(2)}</Text>
                        </View>
                        <View style={{ padding:5, flexDirection:'row', alignItems:'center' , borderTopWidth:1, borderTopColor:Colors.shades.shade600 }}>
                            <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='bold'>Limit Available</Text>
                            <Text size={14} color={Colors.brand.midnight} weight='bold'>${(limit_available).toFixed(2)}</Text>
                        </View>
                    </View>
                </View>
                {errors.length > 0 ?
                <View style={{ margin:20, borderRadius:8, padding:20, backgroundColor:Colors.shades.white, ...view_styles.float }}>
                    <Text style={{ marginBottom:10 }} size={14} color={Colors.brand.midnight}>Please fix the following before continuing</Text>
                    {errors.map(e => {
                        return (
                            <Text size={14} color={Colors.utility.warning}>{e}</Text>
                        )
                    })}
                </View>
                :<></>}
            </View>
            </ScrollView>
            <View nativeID='action_row' style={{ flexDirection:'row', padding:20 }}>
                <Button
                    style={{ flex:1, marginRight:5, opacity: 1 }}
                    title={'CANCEL'}
                    title_color={Colors.brand.electric}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    padding={15}
                    onPress={() => onCancel()}
                />
                <Button
                    style={{ flex:3, opacity: errors.length > 0 ? 0.5: 1 }}
                    disabled={errors.length > 0 ? true : false}
                    title={'REVIEW WITHDRAW'}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.brand.electric}
                    padding={15}
                    onPress={() => handleTransact()}
                />
            </View>
        </View>
    )
}

export default WithdrawCard