import axios from 'axios';
import { APIOverrides } from "../../ApiOverrides";
import type { EventProps, PublicPlayerProps, TournamentProps } from '../../types';

let AUTH_SVC_API = '';
let EVENT_SVC_API = '';

export { BettorSearchApi }

const BettorSearchApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
        EVENT_SVC_API = endpoints['EVENT_SVC_API'] as string;
    },
    searchPlayers: async(search_value:string, offset:number):Promise<PublicPlayerProps[]> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/search/value`, { search_value, offset })
        return resp.data.players
    },
    searchEvents: async(search_value:string, offset:number):Promise<EventProps[]> => {
        const resp = await axios.post(`${EVENT_SVC_API}/v1/events/search`, { search_value, offset })
        console.log(resp.data)
        return resp.data.events
    },
    searchTournaments: async(search_value:string, offset:number):Promise<TournamentProps[]> => {
        const resp = await axios.post(`${EVENT_SVC_API}/v1/tournaments/search/value`, { search_value, offset })
        console.log(resp.data)
        return resp.data.tournaments
    }
}