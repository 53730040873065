import React, { useState } from 'react';
import { View, StyleSheet, ScrollView } from "react-native";
import type { MyPlayerProps } from '../../ProfileManager/api/types';
import { WalletApi, WalletHelpers } from '../api';
import { Button, Icons, Text, TextInput } from '../../Components';
import Colors from '../../constants/colors';
import type { AccountProps } from '../../types';
import moment from 'moment-mini';

type ManualCardAddProps = {
    player:MyPlayerProps,
    onAddAccount:(acct:AccountProps) => void,
    onCancel:() => void
}

const ManualCardAdd = ({ player, onAddAccount, onCancel }:ManualCardAddProps) => {
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState<string>();
    const [ card_account, setCardAccount ] = useState({
        account: WalletHelpers.getEmptyCardAccount(),
        card_detail: { ...WalletHelpers.getEmptyCardDetails(), first_name: player.first_name, last_name: player.last_name }
    })


    const { account, card_detail } = card_account;
    const { valid, card_number, first_name, last_name, expiration_date } = WalletHelpers.isCardValid(card_detail);

    const handleExpiry = (text:string) => {
        setCardAccount({ ...card_account, card_detail: { ...card_detail, expiration_date: text } })
    }

    const handleCardNumber = (text:string) => {
        let unformated_number = WalletHelpers.unformatCardNumber(text)
        setCardAccount({ ...card_account, card_detail: { ...card_detail, card_number: unformated_number, card_brand: WalletHelpers.getCardBrandFromNumber(text) } })
    }

    const handleSaveCard = async() => {
        if(!valid || loading){ return }
        setLoading(true);
        try {
            const resp = await WalletApi.addCardAccount(account, { ...card_detail, expiration_date: moment(card_detail.expiration_date, 'MM/YYYY') })
            //setCardAccount(resp)
            onAddAccount({ ...resp.account, card_detail: resp.card_detail })
            setError(undefined)
        } catch (e) {
            setError('Invalid card.  Please try again')
        }
        setLoading(false)
    }

    const renderCardIcon = (brand?:string) => {
        switch(brand){
            case 'AMEX':
                return <Icons.AmexCardIcon size={30} />
            case 'VISA':
                return <Icons.VisaCardIcon size={30} />
            case 'MASTERCARD':
                return <Icons.MasterCardIcon size={30} />
            case 'DISCOVER':
                return <Icons.DiscoverCardIcon size={30} />
            default:
                return <Icons.CreditCardIcon size={22} color={Colors.brand.midnight}/>
        }
    }

    return (
        <View style={{ flex:1 }}>
            <View style={{ padding:20 }}>
                <Text size={26} color={Colors.brand.midnight} weight='bold'>NEW CARD ACCOUNT</Text>
                <Text style={{ marginTop:10 }} size={16} color={Colors.brand.midnight} weight='regular'>Complete the form below to add a new card account. Once successfully added, you can transact immediately.</Text>
            </View>
            <ScrollView style={{ flex:1, padding:10, margin:10 }}>
                <View nativeID='cc_input' style={{ marginTop:10 }}>
                    <View nativeID='card_number' style={{ padding:5 }}>
                        <View style={{ flexDirection:'row', alignItems:'center', padding:10 }}>
                            <Text style={{flex:1}} size={14} color={Colors.brand.midnight} weight='semibold'>Card Number</Text>
                            <View style={{ flexDirection:'row', alignItems:'center' }}>
                                <View style={local_styles.inactive_brand}>
                                    <Icons.CreditCardIcon size={24} color={Colors.brand.midnight}/>
                                </View>
                                <View style={card_detail.card_brand == 'VISA' ? local_styles.active_brand : local_styles.inactive_brand}>
                                    <Icons.VisaCardIcon size={30} />
                                </View>
                                <View style={card_detail.card_brand == 'MASTERCARD' ? local_styles.active_brand : local_styles.inactive_brand}>
                                    <Icons.MasterCardIcon size={30} />
                                </View>
                                <View style={card_detail.card_brand == 'AMEX' ? local_styles.active_brand : local_styles.inactive_brand}>
                                    <Icons.AmexCardIcon size={30} />
                                </View>
                                <View style={card_detail.card_brand == 'DISCOVER' ? local_styles.active_brand : local_styles.inactive_brand}>
                                    <Icons.DiscoverCardIcon size={30} />
                                </View>
                                
                            </View>
                        </View>
                        <View style={{ flexDirection:'row', borderRadius:22, backgroundColor:Colors.shades.white }}>
                        <View style={{ justifyContent:'center', alignItems:'center', paddingLeft:10, paddingRight:10 }}>
                            {renderCardIcon(card_detail.card_brand)}
                        </View>
                        <TextInput 
                            style={{ borderWidth:0, padding:15, flex:1 }}
                            placeholder='1234 5678 9876 5432'
                            placeholderTextColor={Colors.shades.shade600}
                            value={WalletHelpers.formatCardNumber(card_detail.card_number)}
                            onChangeText={(e) => handleCardNumber(e)}
                        />
                        </View>
                    </View>
                    {card_detail.card_number && !card_number ?
                        <Text size={14} color={Colors.utility.warning} weight='semibold'>Card number is not valid</Text>
                    :<></>}
                    <View nativeID='first_name' style={{ padding:5 }}>
                        <Text style={{ padding:10 }} size={14} color={Colors.brand.midnight} weight='semibold'>First Name</Text>
                            <TextInput 
                                placeholder='Jonathan'
                                placeholderTextColor={Colors.shades.shade600}
                                value={card_detail.first_name}
                                onChangeText={(e) => setCardAccount({ ...card_account, card_detail: { ...card_detail, first_name: e } })}
                            />
                    </View>
                    {!first_name ?
                        <Text size={14} color={Colors.utility.warning} weight='semibold'>First name is not valid</Text>
                    :<></>}
                    <View nativeID='last_name' style={{ padding:5 }}>
                        <Text size={14} color={Colors.brand.midnight} weight='semibold' style={{ padding:10 }}>Last Name</Text>
                        <TextInput 
                            placeholder='Smith'
                            placeholderTextColor={Colors.shades.shade600}
                            value={card_detail.last_name}
                            onChangeText={(e) => setCardAccount({ ...card_account, card_detail: { ...card_detail, last_name: e } })}
                        />
                    </View>
                    {!last_name ?
                        <Text size={14} color={Colors.utility.warning} weight='semibold'>Last name is not valid</Text>
                    :<></>}
                    <View nativeID='expiration_date' style={{ padding:5 }}>
                        <Text size={14} color={Colors.brand.midnight} weight='semibold' style={{ padding:10 }}>Expiration Date (MM/YYYY)</Text>
                        <TextInput
                            value={card_detail.expiration_date}
                            placeholder={'MM/YYYY'}
                            placeholderTextColor={Colors.shades.shade600}
                            onChangeText={(e) => handleExpiry(e)}
                        />
                    </View>
                    {card_detail.expiration_date && !expiration_date ?
                        <Text size={14} color={Colors.utility.warning} weight='semibold'>Expiration date is not valid</Text>
                    :<></>}
                </View>
            </ScrollView>
            {error ?
            <View style={{ padding:10 }}>
                <Text size={16} color={Colors.utility.warning} weight='bold' textAlign='center'>{error}</Text>
            </View>
            :<></>}
            <View style={{ flexDirection:'row', padding:20 }}>
                <Button
                    title='CANCEL'
                    style={{ flex:1, marginRight:5 }}
                    title_color={Colors.brand.electric}
                    padding={15}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    onPress={() => onCancel()}
                />
                <Button
                    title='ADD ACCOUNT'
                    style={{ flex:3, opacity:valid&&!loading?1:0.5 }}
                    disabled={!valid||loading}
                    loading={loading}
                    title_color={Colors.shades.white}
                    padding={15}
                    backgroundColor={Colors.utility.success}
                    onPress={() => handleSaveCard()}
                />
            </View>
        </View>
    )
}

const local_styles = StyleSheet.create({
    active_brand: { borderRadius:4, padding:3, borderWidth:2, borderColor:Colors.brand.midnight },
    inactive_brand: { padding:3, borderWidth:2, borderColor:'transparent' }
})

export default ManualCardAdd