import React from 'react';
import { View, TouchableOpacity } from "react-native";
import type { AccountProps } from '../../types';
import { Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import { view_styles } from '../../constants/styles';
import moment from 'moment-mini';

type AccountCardProps = {
    account:AccountProps,
    hide_status?:boolean,
    onSelectAccount: (account:AccountProps) => void
}

const AccountCard = ({ account, hide_status, onSelectAccount }: AccountCardProps) => {

    const getIcon = () => {
        switch(account.account_type){
            case 'ach':
                if(account.ach_detail?.payment_provider == 'nuvei'){ return <Icons.BankInstantIcon size={28} color={Colors.brand.midnight}/> }
                return <Icons.BankIcon size={28} color={Colors.utility.success} />
            case 'card':
                return renderCardIcon(account.card_detail?.card_brand)
            case 'paypal':
                return <Icons.PayPalIcon size={20}/>
            default: return <></>

        }
    }

    const renderCardIcon = (brand?:string) => {
        switch(brand?.toLowerCase()){
            case 'amex':
                return <Icons.AmexCardIcon size={28} />
            case 'visa':
                return <Icons.VisaCardIcon size={28} />
            case 'mastercard':
                return <Icons.MasterCardIcon size={28} />
            case 'discover':
                return <Icons.DiscoverCardIcon size={28} />
            default:
                return <Icons.CreditCardIcon size={22} color={Colors.brand.midnight}/>
        }
    }

    const getStatusColor = () => {
        switch(account.status){
            case 'active': return Colors.utility.success
            case 'inactive': return Colors.utility.error
            case 'suspended': return Colors.utility.warning
            case 'verify': return Colors.utility.warning
            default: return Colors.brand.midnight
        }
    }

    const getDescriptionLabel = () => {
        switch(account.account_type){
            case 'ach':
                if(!account.ach_detail){ return '' }
                if(account.ibt_placeholder){ return `Securely link a new account and transact instantly` }
                return account.ach_detail.account_type.toUpperCase()
            case 'card':
                if(!account.card_detail){ return '' }
                return `EXP ${moment(account.card_detail.expiration_date).format('MM/YYYY')}`
            case 'paypal':
                return `Login to your Paypal and transact`
            default: return ''
        }
    }

    return (
        <TouchableOpacity 
            style={{ borderRadius:8, backgroundColor:Colors.shades.white, ...view_styles.float }}
            onPress={() => onSelectAccount(account)}    
        >
            <View style={{ padding:10, flexDirection:'row' }}>
                <View style={{ marginRight:10, justifyContent:'center', alignItems:'center' }}>
                    {getIcon()}
                </View>
                <View style={{ flex:1 }}>
                    <Text size={16} color={Colors.brand.midnight} weight='bold'>{account.account_label}</Text>
                    <Text style={{ marginTop:4 }} size={14} color={Colors.brand.midnight} weight='regular'>{getDescriptionLabel()}</Text> 
                </View>
                {!hide_status ?
                <View>
                    <Text size={12} color={getStatusColor()} weight='bold'>{account.status.toUpperCase()}</Text>
                </View>
                :<></>}
            </View>
        </TouchableOpacity>
    )
}


export default AccountCard