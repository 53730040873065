import React, { useState } from 'react';
import { FlatList, TouchableOpacity, View, ScrollView } from 'react-native';
import type { LocationProps, PlayerAddressProps } from '../../types';
import type { MyPlayerProps } from '../../ProfileManager/api/types';
import { WalletApi, WalletHelpers } from '../api';
import AddressCard from './AddressCard';
import { Button, Text, TextInput } from '../../Components';
import Colors from '../../constants/colors';

type AddressManaertProps = {
    player?:MyPlayerProps
    location?: {
        loading: boolean,
        error?:string,
        coordinates:any,
        address_results:any[],
        player_address?:PlayerAddressProps,
        region?:string,
        location?:LocationProps
    }
    player_addresses:PlayerAddressProps[],
    onAddAddress:(address:PlayerAddressProps) => void,
    onRemoveAddress?:(address:PlayerAddressProps) => void,
    onSelect: (address:PlayerAddressProps) => void
    onCancel: () => void
}

const AddressManager = ({ location, player_addresses, onSelect, onCancel, onAddAddress, onRemoveAddress }:AddressManaertProps) => {
    const [ loading, setLoading ] = useState(false);
    const [ draft_address, setDraftAddress ] = useState<PlayerAddressProps|undefined>(undefined)

    const address_errors = WalletHelpers.isAddressValid(draft_address)


    const handleCreateAddress = async() => {
        if(!draft_address){ return } //
        if(loading){ return } //Prevent button mashing
        if(address_errors.length > 0){ return }
        setLoading(true)
        const new_addy = await WalletApi.createAddress(draft_address)
        onAddAddress(new_addy)
        setLoading(false)
        setDraftAddress(undefined)
    }

    const handleDeleteAddres = async(address:PlayerAddressProps) => {
        if(loading || !onRemoveAddress){ return } //No no button mash
        //if(!selected_address){ return } //No selected address
        setLoading(true)
        const removed_addy = await WalletApi.deleteAddress(address.player_address_id);
        onRemoveAddress(removed_addy)
        setLoading(false)
    }

    const tryAutoFill = async() => {
        if(!draft_address || !location?.player_address){ return }
        setDraftAddress({ ...location.player_address })
    }



    const renderAddresses = (data:{ item:PlayerAddressProps, index:number }) => {
        return (
            <View style={{ margin:3 }}>
                <AddressCard 
                    address={data.item} 
                    hide_delete={onRemoveAddress ? false : true}
                    onSelect={(a) => onSelect(a)}
                    onDelete={async(a) => {
                        let confirmed = await confirm('Are you sure you would like to remove this address?')
                        if(confirmed){ handleDeleteAddres(a) }
                    }}
                    />
            </View>
        )
    }
    if(draft_address){
        return (
            <View style={{ flex:1, minHeight:600 }}>
                <View style={{ padding:20 }}>
                    <Text size={30} color={Colors.brand.midnight} weight='bold'>CREATE NEW ADDRESS</Text>
                    <Text style={{ marginTop:15 }} size={16} color={Colors.brand.midnight} weight='regular'>Use the form below to create a new address.</Text>
                </View>
                <ScrollView style={{ flex:1, padding:20 }}>
                    <View nativeID='address_name' style={{ backgroundColor:Colors.shades.shade100, borderRadius:4, margin:5, padding:10 }}>
                        <Text size={16} color={Colors.brand.midnight} weight='bold' textAlign='center'>Address Name / Label</Text>
                        <Text style={{ margin:3 }} size={12} color={Colors.brand.midnight} weight='regular' textAlign='center'>Give address a name (i.e., Home) to make it easily identifiable.</Text>
                        <TextInput
                            value={draft_address.name}
                            placeholder='My Home'
                            placeholderTextColor={Colors.brand.slate}
                            onChangeText={(e) => setDraftAddress({ ...draft_address, name: e })}
                        />
                        {location?.player_address ?
                        <TouchableOpacity style={{ marginTop:5, padding:10 }} onPress={() => tryAutoFill()}>
                            <Text size={14} textAlign='center' weight='regular' color={Colors.brand.electric}>Autofill with Location</Text>
                        </TouchableOpacity>
                        :<></>}
                    </View>
                    <View nativeID='address_street' style={{ flexDirection:'row', alignItems:'center', margin:5 }}>
                        <Text style={{ flex:1/4 }} size={14} color={Colors.brand.midnight} weight='regular'>Street</Text>
                        <TextInput 
                            style={{ flex:1 }}
                            value={draft_address.street}
                            onChangeText={(e) => setDraftAddress({ ...draft_address, street: e })}
                        />
                    </View>
                    <View nativeID='address_city' style={{ flexDirection:'row', alignItems:'center', margin:5 }}>
                        <Text style={{ flex:1/4 }} size={14} color={Colors.brand.midnight} weight='regular'>City</Text>
                        <TextInput 
                            style={{ flex:1 }}
                            value={draft_address.city}
                            onChangeText={(e) => setDraftAddress({ ...draft_address, city: e })}
                        />
                    </View>
                    <View nativeID='address_state' style={{ flexDirection:'row', alignItems:'center', margin:5 }}>
                        <Text style={{ flex:1/4 }} size={14} color={Colors.brand.midnight} weight='regular'>State</Text>
                        <TextInput 
                            value={draft_address.state}
                            placeholder='MN'
                            style={{ flex:1 }}
                            placeholderTextColor={Colors.shades.shade600}
                            onChangeText={(e) => setDraftAddress({ ...draft_address, state: e })}
                        />
                    </View>
                    <Text size={12} color={Colors.utility.warning} textAlign='right'>Two letter codes only accepted</Text>
                    <View nativeID='address_zip' style={{ flexDirection:'row', alignItems:'center', margin:5 }}>
                        <Text style={{ flex:1/4 }} size={14} color={Colors.brand.midnight} weight='regular'>Zip</Text>
                        <TextInput 
                            style={{ flex:1 }}
                            value={draft_address.zip}
                            onChangeText={(e) => setDraftAddress({ ...draft_address, zip: e })}
                        />
                    </View>
                </ScrollView>
                <View style={{ flexDirection:'row', padding:20 }}>
                    <Button
                        style={{ flex:1, marginRight:5, opacity: 1 }}
                        title={'CANCEL'}
                        title_color={Colors.brand.electric}
                        borderWidth={1}
                        borderColor={Colors.brand.electric}
                        padding={15}
                        onPress={() => setDraftAddress(undefined)}
                    />
                    <Button
                        style={{ flex:3, opacity:address_errors.length == 0 && !loading ? 1 : 0.5 }}
                        title={'CREATE'}
                        disabled={address_errors.length > 0 || loading ? true : false}
                        title_color={Colors.shades.white}
                        loading={loading}
                        backgroundColor={Colors.utility.success}
                        padding={15}
                        onPress={() => handleCreateAddress()}
                    />
                </View>
                
            </View>
        )
    }


    return (
        <View style={{ flex:1, minHeight:600 }}>
            <View style={{ padding:20 }}>
                <Text size={30} color={Colors.brand.midnight} weight='bold'>MY ADDRESSES</Text>
                <Text style={{ marginTop:15 }} size={16} color={Colors.brand.midnight} weight='regular'>Below are the addresses we have on file. Manage or add a new one below</Text>
            </View>
            {player_addresses.length == 0 ?
            <View style={{ flex:1, padding:10, borderRadius:8, margin:10, backgroundColor:Colors.shades.shade600, justifyContent:'center', alignItems:'center' }}>
                <Text size={18} color={Colors.brand.midnight} weight='bold' textAlign='center'>Uh Oh!</Text>
                <Text style={{ marginTop:10 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign='center'>It looks like you dont have any saved addresses</Text>
                <TouchableOpacity style={{ marginTop:20, padding:10, backgroundColor:Colors.brand.electric, borderRadius:4 }} onPress={() => setDraftAddress(WalletHelpers.getEmptyAddress())}>
                    <Text size={14} color={Colors.shades.white} textAlign='center'>Create New Address</Text>
                </TouchableOpacity>
            </View>
            :<></>}
            <View style={{ flex:1, padding:20 }}>
                <FlatList data={player_addresses} renderItem={renderAddresses} />
            </View>
            <View nativeID='action_row' style={{ flexDirection:'row', padding:20 }}>
                <Button
                    style={{ flex:1, marginRight:5, opacity: 1 }}
                    title={'CLOSE'}
                    title_color={Colors.brand.electric}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    padding={15}
                    onPress={() => onCancel()}
                />
                <Button
                    style={{ flex:3 }}
                    title={'NEW ADDRESS'}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.brand.electric}
                    padding={15}
                    onPress={() => setDraftAddress(WalletHelpers.getEmptyAddress())}
                />
            </View>
        </View>
    )
}

export default AddressManager