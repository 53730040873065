import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import Colors from '../constants/colors';

type CheckBoxProps = {
    checked?:boolean,
    size?:number
    color?:string,
    disabled?:boolean,
    style?: any,
    fill_style?:any
    onSelect:(checked:boolean) => void
}

const Checkbox = ({ checked, size, disabled, color, onSelect, style, fill_style }:CheckBoxProps) => {
    const box_size = size ?? 20
    const box_color = color ?? Colors.brand.midnight
    return (
        <TouchableOpacity 
            disabled={disabled}
            style={{ height:box_size, width:box_size, borderWidth:1, borderRadius:4, borderColor:box_color, justifyContent:'center', alignItems:'center', ...style }}
            onPress={() => onSelect(checked ? false : true)}
            >
            {checked ?
            <View style={{ height:box_size*0.75, width:box_size*0.75, backgroundColor:box_color, ...fill_style }} />
            :<></>}
        </TouchableOpacity>
    )
}

export default Checkbox