import React from 'react';
import { View } from "react-native";
import type { ItemOrderProps } from "../../types";
import Colors from '../../constants/colors';
import { Text } from '../../Components';


type OrderSummaryCardProps = {
    item_order:ItemOrderProps
}

const OrderSummaryCard = ({ item_order }:OrderSummaryCardProps) => {

    return (
        <View>
            <View style={{ flexDirection:'row', padding:5, paddingRight:10, paddingLeft:10 }}>
                <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight}>Subtotal</Text>
                <Text size={14} color={Colors.brand.midnight}>${item_order.amount}</Text>
            </View>
            {item_order.fee_1 ?
            <View style={{ flexDirection:'row', padding:5, paddingRight:10, paddingLeft:10 }}>
                <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight}>{item_order.fee_1}</Text>
                <Text size={14} color={Colors.brand.midnight}>${item_order.fee_1_amount}</Text>
            </View>
            :<></>}
            {item_order.fee_2 ?
            <View style={{ flexDirection:'row', padding:5, paddingRight:10, paddingLeft:10 }}>
                <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight}>{item_order.fee_2}</Text>
                <Text size={14} color={Colors.brand.midnight}>${item_order.fee_2_amount}</Text>
            </View>
            :<></>}
            <View style={{ flexDirection:'row', padding:5, paddingRight:10, paddingLeft:10 }}>
                <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} weight='bold'>Total</Text>
                <Text size={14} color={Colors.brand.midnight} weight='bold'>${item_order.total_amount}</Text>
            </View>
        </View>
    )
}

export default OrderSummaryCard