import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Button, Icons, TextInput } from '../Components';
import Colors from '../constants/colors';
import { BettorSearchApi } from './api';
import type { EventProps, PublicPlayerProps, TournamentProps } from '../types';

type BettorSearchProps = {
    search_types:string[],
    init_search_value?:string,
    container_style?:any,
    search_style?:any,
    button_style?:any,
    onSearchResults:(results: {
        offset:number,
        players:PublicPlayerProps[],
        events:EventProps[],
        tournaments:TournamentProps[]
        competitions:any[],
        polls: any[]
    }) => void,
}

const BettorSearch = ({ init_search_value, search_types, container_style, search_style, button_style, onSearchResults }:BettorSearchProps) => {
    const [ search, setSearch ] = useState({
        search_value: '',
        offset:0
    });

    const { search_value, offset } = search;

    useEffect(() => { 
        BettorSearchApi.setEnvironment() 
        if(init_search_value){
            handleSearch(init_search_value)
        }
    },[init_search_value])

    const handleSearch = async(value?:string) => {
        if(!value){ value = search_value }
        let players:PublicPlayerProps[] = []
        let events:EventProps[] = []
        let tournaments:TournamentProps[] = []
        let competitions:any[] =[]
        let polls: any[] = []
        const performSearches = search_types.map(async t => {
            try {
                switch(t){
                    case 'players': 
                        console.log('in here!')
                        return players = await BettorSearchApi.searchPlayers(value, offset)
                    case 'events':
                        return events = await BettorSearchApi.searchEvents(value, offset);
                    case 'tournaments':
                        return tournaments = await BettorSearchApi.searchTournaments(value, offset);
                    default: return
                }
            } catch (e) {
                return
            }
        })
        await Promise.all(performSearches)

        onSearchResults({
            offset,
            players,
            events,
            tournaments,
            competitions,
            polls
        })
    }

    return (
        <View style={{ ...container_style }}>
            <View style={{ padding:10, flexDirection:'row' }}>
                <View style={search_style ?? { flexDirection:'row', alignItems:'center', flex:1, borderWidth:1, borderRadius:22, paddingLeft:10, marginRight:5, borderColor:Colors.shades.shade600 }}>
                    <View style={{ padding:5 }}>
                        <Icons.SearchIcon 
                            color={Colors.brand.midnight}
                            size={18}
                        />
                    </View>
                    <TextInput
                        style={{ borderWidth:0, borderRadius:0, borderTopRightRadius:22, borderBottomRightRadius:22, flex:1, fontSize:14 }}
                        placeholder='Search...'
                        placeholderTextColor={Colors.brand.slate}
                        value={search_value}
                        onChangeText={(text) => setSearch({ ...search, search_value:text })}
                    />
                </View>
                <Button
                    title='Search'
                    style={{ ...button_style }}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.brand.electric}
                    padding={15}
                    title_size={14}
                    onPress={() => handleSearch()}
                />
            </View>
        </View>
    )
}

export default BettorSearch