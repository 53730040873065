import React from 'react';
import { View, TouchableOpacity, Image, ScrollView } from 'react-native';
import type { MyPlayerProps } from '../../ProfileManager/api/types';
import type { AccountProps } from '../../types';
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import { button_styles } from '../../constants/styles';

type AccountAddProps = {
    player:MyPlayerProps,
    accounts:AccountProps[],
    walkthrough?:boolean,
    onManualACH: () => void,
    onLinkACH: () => void,
    onClose: () => void,
    onNext: (account:AccountProps) => void,
}

const ACHAddCard = ({ accounts, onClose, onNext, onManualACH, onLinkACH }:AccountAddProps) => {
    //const [ loading, setLoading ] = useState(false);
    //const [ add_type, setAddType ] = useState('manual');
    const manual_account = accounts.find(a => a.ach_detail && a.ach_detail.ach_type == 'manual');
    const ibt_account = accounts.find(a => a.ach_detail && a.ach_detail.ach_type == 'ibf' && !a.ibt_placeholder);

    return (
        <View style={{ flex:1 }}>
            <View style={{ padding:20 }}>
                <Text size={26} color={Colors.brand.midnight} weight='bold'>GET ACCESS TO YOUR WINNINGS</Text>
                <Text style={{ marginTop:10 }} size={16} color={Colors.brand.midnight} weight='regular'>At BettorEdge - we celebrate withdraws!  In order to move funds out of your BettorEdge account, you will need to add an eligible bank account.</Text>
            </View>
            <ScrollView style={{ flex:1, padding:20 }}>
                <Text style={{ marginBottom:20 }} size={18} color={Colors.brand.midnight} weight='bold'>ACH ACCOUNT OPTIONS</Text>
                <View style={{ padding:20, backgroundColor:Colors.shades.shade600, borderRadius:8 }}>
                    <View style={{ flexDirection:'row' }}>
                        <Icons.PlaidIcon size={35}/>
                        <View style={{ flex:1, marginLeft:10 }}>
                            <Text size={14} color={Colors.brand.midnight} weight='bold'>Link your account using PLAID</Text>
                            <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular'>When you link an account you get access to both instant deposits and instant withdraws.</Text>
                        </View>
                    </View>
                    {!ibt_account ?
                    <TouchableOpacity style={{ marginTop:20, padding:3, flexDirection:'row', alignItems:'center', borderRadius:30, backgroundColor:Colors.shades.white }}
                        onPress={() => onLinkACH()}>
                        <View style={{  height:50, width:110, borderRadius:100, justifyContent:'center', alignItems:'center', borderWidth:1, borderColor:Colors.brand.cobalt }}>
                            <Image
                                source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1695821528/1630344814441_wjq98b.png' }}
                                style={{ width:50, height:30 }}
                                resizeMode='cover'
                            />
                        </View>
                        <View style={{ flex:1 }}>
                            <Text size={14} color={Colors.brand.cobalt} textAlign='center' weight='bold'>LINK ACH ACCOUNT</Text>
                        </View>
                    </TouchableOpacity>
                    :
                    <View style={{ position:'absolute', top:5, right:5, bottom:0}}>
                        <Icons.TransactionSuccessIcon size={45}/>
                    </View>
                    }
                </View>
                <View style={{ marginTop:20, padding:20, backgroundColor:Colors.shades.shade600, borderRadius:8 }}>
                    <View style={{ flexDirection:'row' }}>
                        <Icons.BankIcon color={Colors.brand.midnight} size={34}/>
                        <View style={{ flex:1, marginLeft:10 }}>
                            <Text size={14} color={Colors.brand.midnight} weight='bold'>Manually add an account</Text>
                            <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular'>Verify the account in 1-3 business days and start transacting.</Text>
                        </View>
                        
                    </View>
                    {!manual_account ?
                    <TouchableOpacity style={{ marginTop:20, padding:3, flexDirection:'row', alignItems:'center', borderRadius:30, backgroundColor:Colors.brand.midnight }}
                    onPress={() => onManualACH()}>
                        <View style={{  height:50, width:50, borderRadius:100, justifyContent:'center', alignItems:'center', backgroundColor:Colors.shades.white }}>
                            <Icons.BankIcon size={22} color={Colors.brand.midnight} />
                        </View>
                        <View style={{ flex:1 }}>
                            <Text size={14} color={Colors.shades.white} textAlign='center' weight='bold'>ADD MANUAL ACH ACCOUNT</Text>
                        </View>
                    </TouchableOpacity>
                    :
                    <View style={{ position:'absolute', top:5, right:5, bottom:0, alignItems:'flex-end'}}>
                        <Icons.TransactionSuccessIcon size={45}/>
                        {manual_account?.status == 'verify' ?
                        <View style={{ marginTop:-10, marginRight:20, padding:10, borderRadius:8, backgroundColor:Colors.highlights.highlight200 }}>
                            <Text size={14} color={Colors.brand.midnight}>Manual ACH account is pending verification</Text>
                        </View>
                        :<></>}
                    </View>
                    }
                </View>
            </ScrollView>
            <View nativeID='action_row' style={{ flexDirection:'row', justifyContent:'space-between', padding:10 }}>
                <Button
                    title={!ibt_account && !manual_account ? 'DO THIS LATER' : 'All Set!'}
                    title_color={!ibt_account && !manual_account ? Colors.utility.error: Colors.utility.success}
                    backgroundColor='transparent'
                    onPress={() => onClose()}
                />
                <TouchableOpacity
                    style={manual_account || ibt_account ? button_styles.wizard_valid : button_styles.wizard_invalid}
                    onPress={() => {
                        if(ibt_account) { return onNext(ibt_account) }
                        if(manual_account){ return onNext(manual_account) }
                        return
                    }}
                    disabled={manual_account || ibt_account ? false : true}
                    >
                    <Icons.ChevronIcon size={20} direction='right' color={Colors.brand.midnight}/>
                </TouchableOpacity>
            </View>
        </View>
    )
}

export default ACHAddCard