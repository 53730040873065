import React, { useEffect, useState } from 'react';
import { FlatList, TouchableOpacity, View, ScrollView } from 'react-native';
import type { AccountLimitProps, AccountProps } from '../../types';
import { WalletApi, WalletHelpers } from '../api';
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';

const limit_amount_options = [ 50, 100, 250, 500, 1000]
const frequency_options = ['Daily', 'Weekly', 'Monthly']

type AccountLimitManagerProps = {
    account: AccountProps,
    onCancel: () => void,
    onSetLimit: (account_limit:AccountLimitProps) => void
}

const AccountLimitManager = ({ account, onCancel, onSetLimit }: AccountLimitManagerProps ) => {
    const [ loading, setLoading ] = useState(false);
    const [ freq_width, setFreqWidth ] = useState(0);
    const [ disclaimer, setDisclaimer ] = useState(false);
    const [ dropdown_visible, setDropdownVisible ] = useState(false);
    const [ active_frequency, setActiveFrequency ] = useState('Daily')
    const [ active_amount, setActiveAmount ] = useState<number | undefined>(undefined);
    const [ account_limit, setAccountLimit ] = useState<AccountLimitProps>();

    //Filter to ensure only this player and not inactive accounts

    //Account Limits

    /**
     * Effect whenever the account_id changes. Need to get the data from server
     */
    useEffect(() => {
        if(!account){ return }
        getDataFromServer(account.account_id)
    },[account])

    useEffect(() => {
        if(!account_limit){ return }
        if(account_limit.status == 'active'){
            setActiveFrequency(WalletHelpers.getFrequencyFromDays(account_limit.limit_days))
            setActiveAmount(account_limit.limit_amount)
        }
    },[account_limit])    

    /**
     * Get data from the server
     * @param account_id 
     */
    const getDataFromServer = async(account_id:string) => {
        const al = await WalletApi.getLimitByAccountId(account_id)
        setAccountLimit(al)
        if(al){ onSetLimit(al) }
    }

    const handleSetLimit = async() => {
        if(loading){ return alert('Hold your horses please.  Still updating') };
        if(!active_amount || !active_frequency){ return alert('Invalid limit') };
        let days = 0;
        switch(active_frequency){
            case 'Daily': 
                days = 1
                break
            case 'Weekly':
                days = 7
                break
            case 'Monthly':
                days = 30
                break
            default: break
        }
        if(days == 0){ return alert('Invalid limit') }

        let account_limit:AccountLimitProps = {
            account_id:account.account_id,
            account_limit_id: '',
            limit_amount: active_amount,
            limit_days: days,
            create_datetime: '',
            last_update_datetime: '',
            status: 'active',
            current_amount: 0,
            reset_datetime: ''
        }

        setLoading(true)
        const al = await WalletApi.createAccountLimit(account_limit)
        setAccountLimit(al)
        setLoading(false)
    }

    const handleRemoveAccountLimit = async() => {
        if(!account_limit){ return }//There is no account limit
        if(loading){ return alert('Still processing') }
        setLoading(true)
        const al = await WalletApi.removeAccountLimit(account_limit.account_limit_id)
        setAccountLimit(al)
        onSetLimit(al)
        setLoading(false)
    }

    const renderOptions = (data: { item:number, index:number }) => {
        //const selected = data.item == active_amount ? true : false
        return (
            <TouchableOpacity style={{ padding:10 }} onPress={() => {
                setActiveAmount(data.item);
                setDropdownVisible(false);
            }}>
                <Text color={Colors.brand.midnight} weight='semibold' size={14}>${data.item}</Text>
            </TouchableOpacity>
        )
    }

    const renderFrequencies = (data: {item:string, index:number}) => {
        const selected = data.item == active_frequency ? true : false
        return (
            <TouchableOpacity style={{ width:freq_width/3, backgroundColor:selected?Colors.shades.white:'transparent', borderRadius:4, padding:10 }} onPress={() => setActiveFrequency(data.item)}>
                <Text size={14} color={Colors.brand.midnight} weight={selected?'bold':'regular'} textAlign='center'>{data.item}</Text>
            </TouchableOpacity>
        )
    }
    const valid = active_amount && active_frequency ? true : false
    return (
        <View style={{ flex:1 }}>
            <View style={{ padding:20 }}>
                <Text size={30} color={Colors.brand.midnight} weight='bold'>{account_limit?.status == 'active'?'Update Deposit Limits':'Deposit Limits'}</Text>
                <Text style={{ marginTop:15 }} size={16} color={Colors.brand.midnight} weight='regular'>Set your own limit for depositing.</Text>
            </View>
            <ScrollView style={{ flex:1 }}>
                <View style={{padding:16}}>
                    <Text size={16} color={Colors.brand.cobalt} weight='semibold'>Amount</Text>
                    <TouchableOpacity 
                    onPress={() => setDropdownVisible(!dropdown_visible)}
                    style={{marginTop:8, flexDirection:'row', alignItems:'center', justifyContent:'space-between', padding:15, backgroundColor:Colors.shades.shade100}}>
                        <Text color={Colors.brand.cobalt} size={14} weight='semibold'>{active_amount?`$${active_amount}`:'Select Limit'}</Text>
                        <Icons.ChevronIcon direction='down' color={Colors.brand.cobalt} size={8} />
                    </TouchableOpacity>
                    {dropdown_visible ?
                    <View style={{ borderBottomRightRadius:8, borderBottomLeftRadius:8, padding:10, borderWidth:1, borderTopWidth:1, borderColor:Colors.shades.shade600 }}>
                        <FlatList
                            data={limit_amount_options}
                            renderItem={renderOptions}
                        />
                    </View>
                    :<></>}
                </View>
                <View style={{ padding:16 }}>
                    <Text size={16} color={Colors.brand.cobalt} weight='semibold'>Frequency</Text>
                    <View style={{ marginTop:8, backgroundColor: '#e9e9e9', padding:2, borderRadius:4 }} onLayout={(ev) => {
                        setFreqWidth(ev.nativeEvent.layout.width)
                    }}>
                        <FlatList
                            data={frequency_options}
                            renderItem={renderFrequencies}
                            horizontal
                            showsHorizontalScrollIndicator={false}
                        />
                    </View>
                </View>
                {account_limit?.status == 'active' ?
                    <View style={{ margin:10, borderRadius:8, backgroundColor:Colors.shades.shade600, padding:10 }}>
                        <Text size={14} color={Colors.brand.midnight} weight='semibold' textAlign='center'>There is an active limit on this account of ${account_limit.limit_amount} every {account_limit.limit_days} days</Text>
                        <Button
                            style={{ marginTop:10 }}
                            title='REMOVE'
                            title_color={Colors.shades.white}
                            padding={15}
                            backgroundColor={Colors.utility.error}
                            onPress={() => handleRemoveAccountLimit()}
                        />
                    </View>
                    :<></>}
            </ScrollView>
            
            { valid ?
            <View nativeID='limit_disclaimer' style={{ margin:10 }}>
                <TouchableOpacity style={{ flexDirection:'row', padding:5 }} onPress={() => setDisclaimer(!disclaimer)}>
                    <View style={{ justifyContent:'center', marginRight:10, alignItems:'center', height:25, width:25, borderRadius:4, backgroundColor: disclaimer ? Colors.brand.midnight : 'transparent', borderWidth:1, borderColor:Colors.brand.midnight }}>
                        {disclaimer ?
                        <Icons.CheckIcon color={Colors.shades.white} size={14} />
                        :<></>}
                    </View>
                    <Text size={14} color={Colors.brand.midnight} weight='regular'>I confirm I would like to the my deposit limit to {active_amount}, and deposit frequency to {active_frequency}</Text>
                </TouchableOpacity>
            </View>
            :<></>}
            <View nativeID='action_row' style={{ flexDirection:'row', padding:20 }}>
                <Button
                    style={{ flex:1, marginRight:5, opacity: 1 }}
                    title={'CLOSE'}
                    title_color={Colors.brand.electric}
                    borderColor={Colors.brand.electric}
                    borderWidth={1}
                    padding={15}
                    onPress={() => onCancel()}
                />
                <Button
                    style={{ flex:3, opacity: valid&&disclaimer?1:0.5 }}
                    title={'SAVE LIMIT'}
                    disabled={!valid||!disclaimer}
                    loading={loading}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.brand.electric}
                    padding={15}
                    onPress={() => handleSetLimit()}
                />
            </View>
        </View>
    )
}

export default AccountLimitManager