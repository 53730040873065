
const Colors = {
    brand: {
      cobalt: '#003566',
      midnight: '#002A51',
      midnightTopGradient: '#003a85',
      electric: '#005FB7',
      cyan: '#08A4BD',
      slate: '#6E8894',
      mint: '#B2F2D6',
      yellow: '#FFD60A',
      facebook: '#1877F2',
      new:'#264A6B'
    },
    chart:{
      cyan: '#52BCD1',
      electric:'#4D8FCD'
    },
    company: {
      paypal: '#FCBB32',
      paypal_blue:'#012169'
    },
    shades: {
      white: '#FFFFFF',
      shade100: '#F2F5F7',
      shade200: '#F8F8F8',
      shade300: '#EEEEEE',
      shade400: '#DAE1E4',
      shade500: '#4A4A4A',
      shade600: '#DBE9F5',
      black: '#111111',
      black_faded: 'rgba(0,0,0,0.25)',
      black_faded_heavy: 'rgba(0,0,0,0.5)',
      black_faded_super_heavy: 'rgba(0,0,0,0.9)',

    },
    accents: {
      accent100: '#ADD7FF',
      accent200: '#7F9AB2'
    },
    highlights: {
      highlight100: '#620AFF',
      highlight100Faded: `rgba(98,10,255,0.4)`,
      highlight200: '#FCA311',
      highlight200Faded: 'rgba(252,163,17,0.4)',
      highlight300: '#FF4747',
      highlight300Faded: 'rgba(255,71,71,0.4)',
      highlight400: '#4DBA58',
      highlight400Faded: 'rgba(125,205,133,0.4)',
      highlight500: '#05A4BD',
      highlight500Faded: 'rgba(5,164,189,0.25)'
    },
    utility: {
      success: '#7DCD85',
      error: '#D5573B',
      warning: '#FCA311'
    },
    incentive: {
      gold: '#AB9F5E',
      gold_faded: 'rgba(171,159,94, 0.1)',
      silver: '#C0C0C0',
      bronze: '#997840'
    }
  }
  export default Colors