import React, { useEffect, useState } from 'react';
import { View, Image, ActivityIndicator } from 'react-native';
import Colors from '../constants/colors';
import { Text } from '../Components';
import ProfileWizard from './Components/ProfileWizard';
import { ProfileApi } from './api';
import type { MyPlayerProps, PlayerBalanceProps, SettingProps } from './api/types';
import type { PlayerSettingProps } from '../types';

type ProfileOverviewProps = {
    width:number,
    show_welcome?:boolean,
    //access_token:string,
    hide_powered_by?:boolean,
    hide_profile_header?:boolean,
    player?:MyPlayerProps,
    hidden_groups?:string[],
    hidden_steps?:string[],
    hidden_levels?:string[],
    onClose:() => void,
    onVouchStart?:() => void,
    onLogout:() => void,
    onNextStep?:(step:string) => void,
    onPlayerUpdate?:(p:MyPlayerProps, attribute?:string) => void,
    profile_attribute?:'basic'|'phone'|'email'|'dob'|'password'|'vouch'|'notification'|'preferences',
    walkthrough?: {
        level: 'verify' | 'basic'
    }
}

const ProfileManager = ({ profile_attribute, hide_profile_header, player, hide_powered_by, onPlayerUpdate, onNextStep, onVouchStart, show_welcome, hidden_groups, hidden_steps, hidden_levels, walkthrough, width, onClose, onLogout }: ProfileOverviewProps ) => {
    const [ loading, setLoading ] = useState(false);
    const [ vouching, setVouching ] = useState(false);
    const [ draft_player, setDraftPlayer ] = useState<MyPlayerProps>();
    const [ player_balance, setPlayerBalance ] = useState<PlayerBalanceProps>();
    const [ settings, setSettings ] = useState<SettingProps[]>([]);
    const [ player_settings, setPlayerSettings ] = useState<PlayerSettingProps[]>([]);

    useEffect(() => {
        getPlayerFromServer()
    },[])

    useEffect(() => {
        if(!player){ return }
        if(!draft_player){ return } //Dont load over the initial api call
        setDraftPlayer(player); //If we get an update from the player - then update it in our UX
    },[player])

    const getPlayerFromServer = async() => {
        setLoading(true);
        ProfileApi.setEnvironment();
        //APIOverrides.setBearerToken(access_token)
        try {
            const player = await ProfileApi.getMyDetails();
            const pb = await ProfileApi.getMyBalance();
            const settings_response = await ProfileApi.getMySettings();
            setSettings(settings_response.settings);
            setPlayerSettings(settings_response.player_settings);
            setPlayerBalance(pb);
            setDraftPlayer(player);
        } catch (e) {
            console.log(e)
        }

        setLoading(false);
    }


    const handleLogout = () => {
        setDraftPlayer(undefined);
        setPlayerBalance(undefined);
        onLogout()
        onClose()
    }

    if(!draft_player || loading){
        return (
            <View style={{ flex:1, width }}>
                <ActivityIndicator size={'large'} color={Colors.brand.midnight} />
            </View>
        )
    }

    return (
        <View style={{ flex:1, width, minHeight:650 }}>
            <View style={{ flex:1 }}>
                {!hide_profile_header && !walkthrough && !vouching ?
                <View style={{ flexDirection:'row', alignItems:'center' }}>
                    <View nativeID='profile_picture' style={{ padding:10 }}>
                        <Image
                            source={{ uri: draft_player.profile_pic }}
                            style={{ height:50, width:50, borderRadius:100 }}
                            resizeMode='cover'
                        />
                    </View>
                    <View style={{ flex:1 }}>
                        <Text size={20} color={Colors.brand.midnight} weight='bold'>{draft_player.first_name} {draft_player.last_name}</Text>
                        <Text style={{ marginTop:5 }} size={16} color={Colors.brand.slate} weight='bold'>@{draft_player.username}</Text>
                    </View>
                    {player_balance ?
                    <View style={{ paddingRight:10 }}>
                        <Text size={16} color={Colors.brand.midnight} weight='bold' textAlign='right'>${player_balance.balance.toFixed(2)}</Text>
                        <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight} weight='regular' textAlign='right'>Promo {player_balance.promo_balance.toFixed(2)}</Text>
                    </View>
                    :<></>}
                </View>
                :<></>}
                <View style={{ marginTop:10, flex:1 }}>
                        <ProfileWizard
                            player={draft_player}
                            player_settings={player_settings}
                            onVouching={(active) => {
                                if(active && onVouchStart){ onVouchStart() }
                                setVouching(active)
                            }}
                            settings={settings}
                            show_welcome={show_welcome}
                            hide_powered_by={hide_powered_by}
                            width={width}
                            hidden_groups={hidden_groups}
                            hidden_steps={hidden_steps}
                            hidden_levels={hidden_levels}
                            init_walkthrough={walkthrough}
                            onNextStep={(step:string) => onNextStep ? onNextStep(step) : console.log()}
                            onClose={() => onClose()}
                            onLogout={() => handleLogout()}
                            profile_attribute={profile_attribute}
                            onSelectLevel={(level) => console.log(level)}
                            onUpdatePlayerSetting={(new_ps) => setPlayerSettings(player_settings.filter(ps => ps.player_setting_id != new_ps.player_setting_id).concat(new_ps))}
                            onPlayerUpdate={(player, attribute) => {
                                setDraftPlayer(player)
                                if(onPlayerUpdate){ onPlayerUpdate(player, attribute) }
                            }}
                        />
                </View>
            </View>
        </View>
    )
}

export default ProfileManager