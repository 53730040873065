import React, { useEffect, useState } from 'react';
import { View, FlatList, TouchableOpacity } from 'react-native';
import { Button, Icons, Text, TextInput } from '../../Components';
import type { MyPlayerProps, PasswordStateProps } from '../api/types';
import Colors from '../../constants/colors';
import { ProfileApi, ProfileHelpers } from '../api';
import { button_styles } from '../../constants/styles';

type PasswordManagerProps = {
    player:MyPlayerProps,
    walkthrough?:boolean,
    onNext:() => void,
    onClose:() => void,
    onPlayerUpdate:(player:MyPlayerProps) => void
}

const PasswordManager = ({ player, walkthrough, onPlayerUpdate, onNext, onClose }:PasswordManagerProps) => {
    const [ draft_player, setDraftPlayer ] = useState<MyPlayerProps>(player);
    const [ draft_password, setDraftPassword ] = useState({
        change_visible:false,
        loading:false,
        current_password: '',
        password: '',
        re_password: ''
    })
 
    const { change_visible, password, re_password, current_password } = draft_password;

    useEffect(() => {
        setDraftPlayer(player)
    },[player])


    let password_states:PasswordStateProps[] = [ 
        { attribute:'password', type:'min_character', state: 'not_started', label:'At least 8 characters' }, 
        { attribute:'password', type: 'case', state:'not_started', label:'Uppercase and lowercase character' }, 
        { attribute:'password', type:'number', state:'not_started', label: 'Must use a number' }, 
        { attribute:'password', type:'special', state:'not_started', label: 'Includes at least 1 special character' },
        { attribute:'rePassword', type:'special', state:'not_started', label: 'Passwords do not match' },
    ]

    const validatePassword = () => {
        let valid_states:PasswordStateProps[] = []
        password_states.map(ps => {
            if(ps.attribute == 'rePassword'){
                if(re_password != password){
                    valid_states.push({ ...ps, state: 'invalid' })
                } else {
                    valid_states.push({ ...ps, state: 'valid' })
                }
            } else {
                valid_states.push(ProfileHelpers.isPasswordValid(password, ps))
            }
        })
        return valid_states
    }



    /**
     * Flatlist render function to render all the password statuses and their states
     * @param data 
     * @returns 
     */
    const renderPasswordStatuses = (data: { item:PasswordStateProps, index:number }) => {
        return (
            <View style={{flex:1, flexDirection:'row', alignItems:'center', padding:8}}>
                <Text style={{ flex:1}} size={14} color={Colors.brand.midnight}>{data.item.label}</Text>
                {data.item.state === 'valid' ?
                <Icons.CheckCirlceIcon size={14} color={Colors.utility.success} />
                :
                <Icons.AlertIcon size={14} color={Colors.utility.warning} />
                }
            </View>
        )
    }

    const valid_states = validatePassword()
    const is_valid = !valid_states.find(vs => ['invalid'].includes(vs.state)) ? true : false


    const handleSetPassword = async() => {
        if(!password){ return onNext() }
        setDraftPassword({ ...draft_password, loading:true })
        const player = await ProfileApi.setPassword(password, re_password, current_password)
        onPlayerUpdate(player);
        setTimeout(() => {
            setDraftPassword({ current_password:'', change_visible: false, loading:false, password: '', re_password: '' })
            if(walkthrough){ onNext() } else { onClose() }
        }, 500);
    }

    return (
        <View style={{ flex:1 }}>
            {walkthrough ?
            <View style={{ padding:20 }}>
                <Text size={30} color={Colors.brand.midnight} weight='bold'>Secure Your Account</Text>
                <Text style={{ marginTop:15 }} size={16} color={Colors.brand.midnight} weight='regular'>Help up secure your account with a password. Once set, you can login using your username and password</Text>
            </View>
            :<></>}
            {draft_player.no_password || change_visible ?
            <View style={{ flex:1, padding:20 }}>
                {!draft_player.no_password ?
                <View style={{ margin:10, backgroundColor:Colors.shades.white, borderRadius:22, flexDirection:'row', alignItems:'center' }}>
                    <View style={{ paddingLeft:15, paddingRight:15 }}>
                        <Icons.LockClosedIcon
                            color={Colors.brand.midnight}
                            size={18}
                        />
                    </View>
                    <TextInput
                        value={draft_password.current_password}
                        placeholder='My Current Password'
                        secureTextEntry
                        placeholderTextColor={Colors.brand.slate}
                        style={{ flex:1, borderTopLeftRadius:0, borderBottomLeftRadius:0 }}
                        onChangeText={(text) => setDraftPassword({ ...draft_password, current_password : text })}
                    />
                </View>
                :<></>}
                <View style={{ margin:10, marginTop:0, backgroundColor:Colors.shades.white, borderRadius:22, flexDirection:'row', alignItems:'center' }}>
                    <View style={{ paddingLeft:15, paddingRight:15 }}>
                        <Icons.LockClosedIcon
                            color={Colors.brand.midnight}
                            size={18}
                        />
                    </View>
                    <TextInput
                        value={draft_password.password}
                        placeholder='Enter Password'
                        secureTextEntry
                        placeholderTextColor={Colors.brand.slate}
                        style={{ flex:1, borderTopLeftRadius:0, borderBottomLeftRadius:0 }}
                        onChangeText={(text) => setDraftPassword({ ...draft_password, password : text })}
                    />
                </View>
                <View style={{ margin:10, marginTop:0, backgroundColor:Colors.shades.white, borderRadius:22, flexDirection:'row', alignItems:'center' }}>
                    <View style={{ paddingLeft:15, paddingRight:15 }}>
                        <Icons.LockClosedIcon
                            color={Colors.brand.midnight}
                            size={18}
                        />
                    </View>
                    <TextInput
                        value={draft_password.re_password}
                        placeholder='Re-Enter Password'
                        secureTextEntry
                        placeholderTextColor={Colors.brand.slate}
                        style={{ flex:1, borderTopLeftRadius:0, borderBottomLeftRadius:0 }}
                        onChangeText={(text) => setDraftPassword({ ...draft_password, re_password : text })}
                    />
                </View>
                <View style={{ padding:10 }}>
                    <FlatList data={valid_states} renderItem={renderPasswordStatuses} keyExtractor={(item) => item.type??item.attribute}/>
                </View>

            </View>
            :
            <View style={{ flex:1, justifyContent:'center', alignItems:'center' }}>
                <View style={{ borderRadius:8, backgroundColor:Colors.shades.shade600, justifyContent:'center', alignItems:'center', padding:30 }}>
                    
                    <Text style={{ marginBottom:30 }} size={24} color={Colors.brand.midnight} weight='bold'>Password Set</Text>
                    
                    <Icons.LockClosedIcon size={40} color={Colors.utility.success} />
                    <Button
                        title='CHANGE'
                        style={{ marginTop:20, padding:15, paddingLeft:40, paddingRight:40 }}
                        
                        title_color={Colors.shades.white}
                        backgroundColor={Colors.brand.electric}
                        onPress={() => setDraftPassword({ ...draft_password, change_visible: true })}
                    />
                </View>
            </View>
            }
            {walkthrough ?
            <View nativeID='action_row' style={{ flexDirection:'row', justifyContent:'space-between', padding:10 }}>
                <Button
                    title='EXIT SETUP'
                    title_color={Colors.utility.error}
                    backgroundColor='transparent'
                    onPress={() => onClose()}
                />
                <TouchableOpacity 
                    disabled={!is_valid}
                    style={is_valid?button_styles.wizard_valid:button_styles.wizard_invalid}
                    onPress={() => handleSetPassword()}
                >
                    <Icons.ChevronIcon size={20} direction='right' color={Colors.brand.midnight}/>
                </TouchableOpacity>
            </View>
            :
            <View nativeID='action_row' style={{ flexDirection:'row', justifyContent:'space-between', padding:10 }}>
                <Button
                    style={{ flex:1, marginRight:5 }}
                    title='BACK'
                    padding={15}
                    title_color={Colors.brand.electric}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    onPress={() => onClose()}
                />
                <Button
                    style={{ flex:3, opacity:is_valid && password?1:0.5 }}
                    disabled={!is_valid || !password}
                    title='UPDATE'
                    padding={15}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    onPress={() => handleSetPassword()}
                />
            </View>
            }
        </View>
    )
}

export default PasswordManager