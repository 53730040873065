import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Linking, ScrollView, ActivityIndicator } from "react-native";
import type { AccountProps, ItemOrderProps, ItemProps, PlayerAddressProps, TransactionProps } from '../types';
import { Button, Icons, Text } from '../Components';
import { CheckoutApi, CheckoutHelpers } from './api';
import ItemSummaryCard from './components/ItemSummaryCard';
import OrderSummaryCard from './components/OrderSummaryCard';
import AccountSummaryCard from './components/AccountSummaryCard';
import Colors from '../constants/colors';
import AddressManager from '../Wallet/components/AddressManager';
import type { MyPlayerProps } from '../ProfileManager/api/types';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import PayPalOrderCapture from './components/PayPalOrderCapture';
import SpringView from '../Components/Spring';
import { usePlayerLocation } from '../LocationTracker/LocationStatus';
import LocationTracker from '../LocationTracker';
import { view_styles } from '../constants/styles';
import { WalletApi } from '../Wallet/api';
import ShippingSummaryCard from './components/ShippingSummaryCard';

type CheckoutProps = {
    item_order:ItemOrderProps,
    account_id?:string,
    height?:number,
    onRequestNewAccount:() => void,
    onCompleteOrder:(item_order:ItemOrderProps, transaction:TransactionProps) => void,
    onCancel:() => void
}
const Checkout = ({ item_order, height, account_id, onCancel, onCompleteOrder, onRequestNewAccount }:CheckoutProps) => {
    const [ loading, setLoading ] = useState(false);
    const [ completing, setCompleting ] = useState(false);
    const [ error, setError ] = useState<string|undefined>();
    const [ show_addresses, setShowAddresses ] = useState<{
        visible:boolean,
        address_type?: 'billing'|'shipping'
    }>({
        visible:false
    });
    const [ cvv, setCVV ] = useState<string>();
    const [ show_location, setShowLocation ] = useState(false);
    const { permission, location, getLocation, checkPermissions } = usePlayerLocation();
    const [ checkout_data, setCheckoutData ] = useState<{
        loaded:boolean,
        player?:MyPlayerProps,
        accounts:AccountProps[],
        player_addresses:PlayerAddressProps[],
        selected_account?:AccountProps
        billing_address?:PlayerAddressProps,
        shipping_address?:PlayerAddressProps,
        items:ItemProps[]
    }>({
        loaded:false,
        items:[],
        player_addresses: [],
        accounts:[]
    })
    const [ checkout, setCheckout ] = useState<{
        ip_item_order?:ItemOrderProps,
        ip_transaction?:TransactionProps
    }>({});
    const [ draft_order, setDraftOrder ] = useState<ItemOrderProps>();
    const { loaded, player, accounts, items, player_addresses , selected_account, billing_address, shipping_address } = checkout_data;
    const { ip_item_order, ip_transaction } = checkout;
    
    const shipping_address_required = CheckoutHelpers.isShippingRequired(items);
    const billing_address_required = CheckoutHelpers.isBillingAddressRequired(items, selected_account);

    //const isLocationRequired = CheckoutHelpers.isLocationRequired(items);

    const will_redirect = selected_account?.account_type == 'ach' && selected_account?.ach_detail?.ach_type == 'ibf' ? true : false

    useEffect(() => {
        CheckoutApi.setEnvironment();
        WalletApi.setEnvironment();
        if(!item_order){ return setDraftOrder(undefined) }
        getDetailsFromServer(item_order, account_id);
    },[item_order])

    useEffect(() => {
        if(!permission.checked){ return }
        if(permission.permission != 'granted'){ return setShowLocation(true) }
    },[permission])

    /**
     * Get all necessary checkout data from the server
     * @param item_order 
     * @param account_id 
     */
    const getDetailsFromServer = async(item_order:ItemOrderProps, account_id?:string) => {
        checkPermissions({ auto_on_granted:true });
        const player = await CheckoutApi.getMyDetails();
        const order_items = await CheckoutApi.getItemsByIds(item_order.items.map(i => i.item_id));
        const accounts = await CheckoutApi.getMyAccounts();
        let eligible_accounts = CheckoutHelpers.filterMyPurchaseAccounts(accounts, order_items)
        const addresses = await CheckoutApi.getMyAddresses();
        let a = eligible_accounts.find(a => a.account_id == account_id)
        if(!a){
            a = eligible_accounts.find(a => a.default_account);
        }
        let ba:PlayerAddressProps | undefined = addresses.find(addy => addy.player_address_id == a?.default_billing_address_id);
        
        if(a){ item_order = CheckoutHelpers.genItemOrder(item_order, order_items, a) }
        
        setDraftOrder(item_order);

        setCheckoutData({
            loaded:true,
            player,
            selected_account:a,
            player_addresses: addresses,
            billing_address: ba,
            items: order_items,
            accounts: accounts
        })
    }

    const order_errors = !draft_order ? [] : CheckoutHelpers.isOrderValid(draft_order, items, selected_account, cvv, billing_address, shipping_address, location.location, location.coordinates);
    const handleConfirm = async() => {
        if(!draft_order || order_errors.length > 0){ return }
        if(error){ return onCancel()}
        setLoading(true);
        const draft_transaction = CheckoutHelpers.genTransactionFromItemOrder(draft_order, items, selected_account?.account_id, cvv);
        try {
            const resp = await CheckoutApi.createOrder({ ...draft_order, region:location.region, location: location.coordinates }, { ...draft_transaction, billing_address_id: billing_address?.player_address_id });
            setCheckout({
                ip_item_order: resp.item_order,
                ip_transaction: resp.transaction
            })
        } catch (e) {
            setError('Unable to complete your order. Please try again or reach out to support if the issue persists')
            setLoading(false);
        }

    }

    const handleCancel = async() => {
        if(!ip_transaction){ return onCancel() }
        await CheckoutApi.cancelTransaction(ip_transaction.transaction_id);
        setCheckout({});
        onCancel();
    }

    const checkTransactionStatus = async() => {
        if(!ip_transaction){ return }
        const resp = await CheckoutApi.getTransactionById(ip_transaction.transaction_id);
        setCheckout({ ...checkout, ip_transaction:resp.transaction })
        if(resp.transaction.status == 'redirect'){
            setTimeout(() => {
                console.log('checking tran status')
                checkTransactionStatus()
            }, 2000);
        }

    }

    const handleChangeAccount = async(account:AccountProps) => {
        let new_address = player_addresses.find(pa => pa.player_address_id == account.default_billing_address_id);
        setCheckoutData({
            ...checkout_data,
            selected_account: account,
            billing_address: new_address ? new_address : billing_address
            
        })
        if(draft_order){
            let new_draft_order = CheckoutHelpers.genItemOrder(draft_order, items, account)
            setDraftOrder(new_draft_order)
        }
    }

    const handleChangeBillingAddress = async(address:PlayerAddressProps, address_type?:'shipping'|'billing') => {
        if(selected_account && address_type == 'billing'){ 
            await CheckoutApi.updateAccountDefaultAddress(selected_account.account_id, address.player_address_id) 
        }
        if(address_type == 'billing'){
            setCheckoutData({ ...checkout_data, billing_address: address, player_addresses: player_addresses.filter(pa => pa.player_address_id != address.player_address_id).concat(address) })
        } else {
            setCheckoutData({ ...checkout_data, shipping_address: address, player_addresses: player_addresses.filter(pa => pa.player_address_id != address.player_address_id).concat(address) })
            if(draft_order){ setDraftOrder({ ...draft_order, shipping_address_id: address.player_address_id }) }
        }
        setShowAddresses({ visible:false })
    }

    const handleRedirect = async() => {
        if(!ip_transaction?.redirect_url){ return }
        if(completing){ return }
        setCompleting(true);
        Linking.openURL(ip_transaction.redirect_url)
        setTimeout(() => {
            console.log('checking tran status!')
            checkTransactionStatus()
        }, 5000);
    }

    if(!draft_order || !player || !loaded){
        return (
            <View style={{ flex:1, height, padding:20 }}>
                <Text size={16} color={Colors.brand.midnight} weight='bold' textAlign='center'>LOADING YOUR CART</Text>
                <ActivityIndicator size='large' color={Colors.brand.midnight} style={{ alignSelf:'center', marginTop:30 }} />
            </View>
        )
    }

    return (
        
        <View style={{ flex:1, height }}>
            <ScrollView style={{ flex:1 }}>
                <View nativeID='items' style={{ ...local_styles.section, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600}}>
                    <ItemSummaryCard
                        items={items}
                    />
                </View>
                <View nativeID='order_summary' style={{ ...local_styles.section, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600}}>
                    <OrderSummaryCard
                        item_order={draft_order}
                    />
                </View>
                {shipping_address_required ?
                <View nativeID='shipping_summary' style={{ ...local_styles.section, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600}}>
                    <ShippingSummaryCard
                        player_address={shipping_address}
                        onChangeAddress={() => setShowAddresses({ visible: true, address_type: 'shipping' })}
                    />
                </View>
                :<></>}
                <View nativeID='account_summary' style={{ ...local_styles.section, borderBottomWidth:1, borderBottomColor:Colors.shades.shade600}}>
                    <AccountSummaryCard
                        accounts={accounts}
                        items={items}
                        selected_account={selected_account}
                        player_address={billing_address}
                        billing_address_required={billing_address_required}
                        cvv={cvv}
                        onRequestNewAccount={onRequestNewAccount}
                        onCVVChange={(new_cvv) => setCVV(new_cvv)}
                        onAccountsUpdate={(accts) => {
                            setCheckoutData({
                                ...checkout_data,
                                selected_account: accts.find(a => a.account_id == selected_account?.account_id),
                                accounts: accounts.filter(a => !accts.find(na => na.account_id == a.account_id)).concat(accts)
                            })
                        }}
                        onChangeAddress={() => setShowAddresses({
                            visible:true,
                            address_type:'billing'
                        })}
                        onSelectAccount={(account) => handleChangeAccount(account)}
                    />
                </View>
            </ScrollView>
            {order_errors.length > 0 ?
            <View style={{ margin:10, borderRadius:4,  padding:10, backgroundColor:Colors.shades.white, ...view_styles.float }}>
                <Text style={{ marginBottom:5 }} size={14} color={Colors.brand.midnight} weight='semibold'>Please fix the following before continuing</Text>
                {order_errors.map(e => {
                    return (
                        <Text style={{ padding:4 }} size={12} color={Colors.utility.warning} weight='bold'>{e}</Text>
                    )
                })}
            </View>
            :<></>}
            {error?
            <View style={{ padding:10 }}>
                <Text size={14} color={Colors.utility.warning} weight='bold' textAlign='center'>{error}</Text>
            </View>  
            :<></>}
            <PayPalScriptProvider  
              options={{ 
                  clientId: CheckoutApi.getPayPalClientId(),
              }}> 
                <View style={{ padding:20 }}>
                {!ip_transaction ?
                <View nativeID='action_row' style={{ flexDirection:'row', alignItems:'center' }}>
                    <Button
                        style={{ flex:1, marginRight:5, opacity:loading?0.5:1 }}
                        title='CANCEL'
                        disabled={loading}
                        title_color={Colors.utility.error}
                        borderWidth={1}
                        borderColor={Colors.utility.error}
                        padding={15}
                        onPress={() => handleCancel()}
                    />
                    {selected_account?.account_type == 'paypal' ?
                    <PayPalOrderCapture
                        draft_item_order={draft_order}
                        paypal_account={selected_account}
                        region={location.region}
                        coordinates={location.coordinates}
                        button_height={48}
                        items={items}
                        order_valid={order_errors.length == 0 ? true : false}
                        onOrderUpdate={(item_order, transaction) => setCheckout({ ip_item_order: item_order, ip_transaction: transaction })}
                        onCancel={() => console.log('DONE!')}
                    />
                    :
                    <Button
                        style={{ flex:3, opacity:loading || order_errors.length > 0 || error ? 0.5 : 1 }}
                        loading={loading || location.loading}
                        disabled={loading || order_errors.length > 0}
                        title={location.loading ?'VERIFYING LOCATION': will_redirect ? 'CONFIRM & CONTINUE' : 'SUBMIT PURCHASE'}
                        title_color={Colors.shades.white}
                        backgroundColor={ will_redirect ? Colors.brand.electric : Colors.utility.success }
                        padding={15}
                        onPress={() => handleConfirm()}
                    />
                    }
                </View>
                : <></>}
                </View>
            </PayPalScriptProvider>
            
            {show_addresses.visible ?
            <View style={{ position:'absolute', top:0, bottom:0, left:0, right:0, backgroundColor:Colors.shades.black_faded, justifyContent:'flex-end' }}>
            <SpringView
                to={0}
                from={500}
                slide='vertical'
                style={{ backgroundColor:Colors.shades.white,  borderTopRightRadius:40, borderTopLeftRadius:40 }}
            >
                <AddressManager
                    player={player}
                    player_addresses={player_addresses}
                    location={location}
                    onCancel={() => setShowAddresses({ visible:false })}
                    onRemoveAddress={(addy) => {
                        let selected_addy = billing_address && billing_address.player_address_id == addy.player_address_id ? undefined : billing_address
                        if(show_addresses.address_type == 'billing'){
                            setCheckoutData({ ...checkout_data, billing_address: selected_addy, player_addresses: player_addresses.filter(pa => pa.player_address_id != addy.player_address_id) })
                        } else {
                            setCheckoutData({ ...checkout_data, shipping_address: selected_addy, player_addresses: player_addresses.filter(pa => pa.player_address_id != addy.player_address_id) })
                        }
                    }}
                    onAddAddress={(addy) => handleChangeBillingAddress(addy, show_addresses.address_type)}
                    onSelect={(addy) => handleChangeBillingAddress(addy, show_addresses.address_type)}
                />
                </SpringView>
            </View>
            :<></>}
            
            {ip_item_order && ip_transaction ?
                <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'flex-end' }}>
                    <SpringView 
                        slide='vertical'
                        from={500}
                        to={0}
                        style={{ borderTopRightRadius:40, borderTopLeftRadius:40, backgroundColor:Colors.shades.white }}>
                        {ip_transaction.status == 'posted' ?
                        <View style={{ flex:1, padding:10, paddingTop:30, paddingBottom:30, justifyContent:'center', alignItems:'center' }}>
                            <Icons.TransactionSuccessIcon  size={150} />
                            <View style={{ padding:20, borderRadius:8, borderWidth:1, borderColor:Colors.utility.success, marginTop:20 }}>
                                <Text size={20} color={Colors.utility.success} weight='bold' textAlign='center'>Order Successful</Text>
                                <Text style={{ marginTop:10 }} size={16} color={Colors.brand.cobalt} weight='regular' textAlign='center'>Your order was successfully processed. An email confirmation has been sent. Please select close below to continue.</Text>
                            </View>
                        </View>
                        : ip_transaction.status == 'failed' ?
                        <View style={{ flex:1, padding:10, paddingTop:30, paddingBottom:30, justifyContent:'center', alignItems:'center' }}>
                            <Icons.TransactionErrorIcon  size={150} />
                            <View style={{ padding:20, borderRadius:8, borderWidth:1, borderColor:Colors.utility.error, marginTop:20 }}>
                                <Text size={20} color={Colors.utility.error} weight='bold' textAlign='center'>Order Failed</Text>
                                <Text style={{ marginTop:10 }} size={16} color={Colors.brand.cobalt} weight='regular' textAlign='center'>Sorry, we were unable to process your order at this time.  Please try again or reach out to support for help.</Text>
                            </View>
                        </View>
                        :ip_transaction.status == 'cancelled' ?
                        <View style={{ flex:1, padding:10, paddingTop:30, paddingBottom:30, justifyContent:'center', alignItems:'center' }}>
                            <Icons.TransactionErrorIcon  size={150} />
                            <View style={{ padding:20, borderRadius:8, borderWidth:1, borderColor:Colors.utility.error, marginTop:20 }}>
                                <Text size={20} color={Colors.utility.error} weight='bold' textAlign='center'>Order Cancelled</Text>
                                <Text style={{ marginTop:10 }} size={16} color={Colors.brand.cobalt} weight='regular' textAlign='center'>Your order was cancelled.  Please try again.</Text>
                            </View>
                        </View>
                        : ip_transaction.status == 'redirect' ?
                        <View style={{ flex:1, padding:10, paddingTop:30, paddingBottom:30, justifyContent:'center', alignItems:'center' }}>
                            <Icons.IBTFLowIcon  size={300} />
                            <View style={{ padding:20, borderRadius:8, borderWidth:1, borderColor:Colors.shades.shade600, marginTop:20 }}>
                                <Text size={20} color={Colors.brand.cobalt} weight='bold' textAlign='center'>Finish With Plaid</Text>
                                <Text style={{ marginTop:10 }} size={16} color={Colors.brand.cobalt} weight='regular' textAlign='center'>Select Complete Order below to complete the transaction through Plaid</Text>
                            </View>
                        </View>
                        :<></>
                        }
                        <View nativeID='action_row' style={{ flexDirection:'row', padding:20 }}>
                            {ip_transaction.status == 'redirect' || ip_transaction.status == 'pending' ?
                            <Button
                                style={{ flex:1, marginRight:5, opacity:1 }}
                                title='CANCEL'
                                //disabled={loading}
                                title_color={Colors.utility.error}
                                borderWidth={1}
                                borderColor={Colors.utility.error}
                                padding={15}
                                onPress={() => handleCancel()}
                            />
                            :
                            <Button
                                style={{ flex:1, opacity:1 }}
                                title='CLOSE'
                                //disabled={loading}
                                title_color={Colors.utility.error}
                                borderWidth={1}
                                borderColor={Colors.utility.error}
                                padding={15}
                                onPress={() => onCompleteOrder(ip_item_order, ip_transaction)}
                            />
                            }
                            {ip_transaction.status == 'redirect' ?
                            <Button
                                style={{ flex:3, opacity:completing?0.5:1 }}
                                loading={completing}
                                disabled={completing}
                                title='COMPLETE ORDER'
                                title_color={Colors.shades.white}
                                backgroundColor={Colors.brand.electric}
                                padding={15}
                                onPress={() => handleRedirect()}
                            />
                            :<></>}
                        </View>
                    </SpringView>
                </View>
            :<></>}
            {show_location ?
            <View style={{ position:'absolute', top:0, bottom:0, left:0, right:0, backgroundColor:Colors.shades.black_faded, justifyContent:'flex-end' }}>
                <SpringView
                    to={0}
                    from={500}
                    slide='vertical'
                    style={{ backgroundColor:Colors.shades.white, borderTopRightRadius:40, borderTopLeftRadius:40 }}
                >
                    <LocationTracker 
                        location={location}
                        permission={permission}
                        onGetLocation={getLocation}
                        onClose={() => setShowLocation(false)}
                    />
                </SpringView>
            </View>
            :<></>}
        </View>
    )
}

const local_styles = StyleSheet.create({
    section: { 
        padding: 10,
        margin:5
    }
})

export default Checkout