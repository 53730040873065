import React, { useEffect, useState } from 'react';
import type { MyPlayerProps } from '../api/types';
import moment from 'moment-mini';
import { View, ActivityIndicator, TouchableOpacity } from 'react-native';
import { Button, Icons, Text, TextInput } from '../../Components';
import Colors from '../../constants/colors';
import { ProfileApi } from '../api';
import { button_styles } from '../../constants/styles';

type DOBManagerProps = {
    player:MyPlayerProps,
    walkthrough?:boolean,
    onNext:() => void,
    onClose:() => void,
    onPlayerUpdate:(player:MyPlayerProps) => void
}

const DOBManager = ({ player, walkthrough, onPlayerUpdate, onNext, onClose }: DOBManagerProps ) => {
    const [ loading, setLoading ] = useState(false);
    const [ dob, setDob ] = useState<string>('');
    const [ error, setError ] = useState<undefined | string>(undefined)
    const [ age_error, setAgeError ] = useState<undefined | string>(undefined)


    useEffect(() => {
        if(player.dob){ 
            let split_dob = player.dob.split('T')
            if(!split_dob[0]){ return }
            split_dob = split_dob[0].split('-')
            let new_date = `${split_dob[1]}/${split_dob[2]}/${split_dob[0]}`
            let valid = moment(new_date, 'MM/DD/YYYY', true).isValid()
            if(valid){ setDob(new_date) }
            else { setDob(moment(player.dob).format('MM/DD/YYYY')) }
            let age_diff = moment().diff(moment(player.dob), 'years')
            if(age_diff < 18){
                setAgeError('Must be over the age of 18 to participate in real money activities on BettorEdge')
            } else {
                setAgeError(undefined)
            }
        }
    },[player])

    const isUpdateNeeded = () => {
        let new_dob = moment(dob).format('MM/DD/YYYY');
        let old_dob = moment(player.dob).format('MM/DD/YYYY');
        if(new_dob != old_dob){ return true }
        return false
    }

    const handleDOBChange = (val:string) => {
        let dob_moment = moment(val, 'MM/DD/YYYY', true)
        if(val != '' && !dob_moment.isValid()){ 
            setError('Date must be MM/DD/YYYY format') 
        } else { 
            setError(undefined) 
            let age_diff = moment().diff(dob_moment, 'years')
            if(age_diff < 18){
                setAgeError('Must be over the age of 18 to participate in real money activities on BettorEdge')
            } else {
                setAgeError(undefined)
            }
        }
        setDob(val)
    }

    const update_allowed = isUpdateNeeded() && !error

    const handleUpdatePlayerDOB = async() => {
        setLoading(true);
        const new_player = await ProfileApi.updatePlayerDOB(dob);
        onPlayerUpdate(new_player)
        setLoading(false);
    }

    const handleNext = async() => {
        if(!error && !isUpdateNeeded()){ return onNext() }
        if(!update_allowed){ return }
        await handleUpdatePlayerDOB()
        return onNext()
    }


    return (
        <View style={{ flex:1 }}>
            <View style={{ flex:1, justifyContent:'center'}}>
                <View style={{ padding:20 }}>
                    <Text size={30} color={Colors.brand.midnight} weight='bold'>Date Of Birth</Text>
                    <Text style={{ marginTop:15 }} size={16} color={Colors.brand.midnight} weight='regular'>In order to participate in real-money gaming you must be over 18.</Text>
                </View>
                <View style={{ padding:30, flex:1 }}>
                    <View style={{ backgroundColor:Colors.shades.white, borderRadius:22, flexDirection:'row', alignItems:'center' }}>
                        <View style={{ paddingLeft:15, paddingRight:15 }}>
                            <Icons.BirthdayIcon
                                color={Colors.brand.midnight}
                                size={18}
                            />
                        </View>
                        <TextInput
                            value={dob}
                            placeholder='01/01/2005'
                            editable={player.vouched_status == 'verified' ? false : true}
                            placeholderTextColor={Colors.brand.slate}
                            style={{ flex:1, borderRadius:0 }}
                            onChangeText={(text) => handleDOBChange(text)}
                        />
                        <View style={{ paddingLeft:15, paddingRight:15 }}>
                            {loading ?
                            <ActivityIndicator size='small' color={Colors.brand.midnight}/>
                            :
                            <View>
                                { player.dob && !error ?
                                <Icons.CheckIcon size={16} color={Colors.utility.success} />
                                :
                                <Icons.AlertIcon size={16} color={Colors.utility.warning} />
                                }
                            </View>
                            }
                        </View>
                    </View>
                    {error ?
                    <Text size={14} color={Colors.utility.warning}>{error}</Text>
                    : age_error ?
                    <Text size={14} color={Colors.utility.warning}>{age_error}</Text>
                    :<></>}
                </View>
            </View>
            {walkthrough ?
            <View nativeID='action_row' style={{ flexDirection:'row', justifyContent:'space-between', padding:10 }}>
                <Button
                    title='EXIT SETUP'
                    title_color={Colors.utility.error}
                    backgroundColor='transparent'
                    onPress={() => onClose()}
                />
                <TouchableOpacity 
                    style={!error && !loading ? button_styles.wizard_valid : button_styles.wizard_invalid}
                    disabled={error || loading ? true : false}
                    onPress={() => handleNext()}
                    >
                    <Icons.ChevronIcon size={20} direction='right' color={Colors.brand.midnight}/>
                </TouchableOpacity>
            </View>
            :
            <View nativeID='action_row' style={{ flexDirection:'row', justifyContent:'space-between', padding:10 }}>
                <Button
                    style={{ flex:1, marginRight:5 }}
                    title='BACK'
                    padding={15}
                    title_color={Colors.brand.electric}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    onPress={() => onClose()}
                />
                <Button
                    style={{ flex:3, opacity:update_allowed?1:0.5 }}
                    disabled={!update_allowed}
                    loading={loading}
                    title='UPDATE'
                    padding={15}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    onPress={async() => {
                        await handleUpdatePlayerDOB()
                        //onClose()
                    }}
                />
            </View>
            }
        </View>
    )
}

export default DOBManager