import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import type { PlayerAddressProps } from '../../types';
import { Button, Text } from '../../Components';
import Colors from '../../constants/colors';

type AddressCardProps = {
    address:PlayerAddressProps,
    hide_delete?:boolean,
    onDelete:(address:PlayerAddressProps) => void,
    onSelect:(address:PlayerAddressProps) => void
}

const AddressCard = ({ hide_delete, address, onSelect, onDelete }:AddressCardProps) => {
    return (
        <View nativeID='address_info' style={{ padding:10, flexDirection:'row', alignItems:'center', borderWidth:1, borderColor:Colors.shades.shade600, borderRadius:8 }} >
            <TouchableOpacity style={{ flex:1 }} onPress={() => onSelect(address)}>
                <Text color={Colors.brand.midnight} weight='bold' size={16}>{address.name}</Text>
                <Text style={{ marginTop:4 }} size={14} weight='regular'>{address.street}</Text>
                <Text style={{ marginTop:2 }} size={14} weight='regular'>{address.city} {address.state} {address.zip}</Text>
            </TouchableOpacity>
            {!hide_delete ?
            <Button
                title='DELETE'
                title_color={Colors.shades.white}
                backgroundColor={Colors.utility.error}
                onPress={() => onDelete(address)}
            />
            :<></>}
        </View>
    )
}

export default AddressCard