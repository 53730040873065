import axios from "axios"
import type { AuthCheckResponseProps, AuthExecutionRequestProps, AuthStrategyIdentifierProps, AuthStrategyProps, CompanyMemberProps, CompanyProps, PlayerTokenProps } from "./types";
import { APIOverrides } from "../../ApiOverrides";
import type { PlayerReferralProps, PromoProps, PublicPlayerProps } from "../../types";


let AUTH_SVC_API = '';

export {
    AuthenticateApi,
    AuthenticateHelpers
}

const AuthenticateApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        AUTH_SVC_API = endpoints['AUTH_SVC_API'] as string;
    },
    authCheck: async(attribute:'email'|'phone'|'username', value:string):Promise<AuthCheckResponseProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/authenticate/check`, { attribute, value })
        return resp.data.auth_response
    },
    authForgot: async(attribute:'email'|'phone'|'username', value:string):Promise<AuthCheckResponseProps> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/authenticate/forgot`, { attribute, value })
        return resp.data.auth_response
    },
    authLogin: async(attribute:'email'|'phone'|'username', value:string, token:string, verification_session_id?:string):Promise<{ result:'success'|'fail', new_player?:boolean, message:string, player_id?:string, access_token?:string, refresh_token?:string, expire_datetime?:any }> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/authenticate/login`, { attribute, verification_session_id }, {
            auth: {
              username: value,
              password: token
            }
          })
          return resp.data.login_response
    },
    authenticateLocal : async(username:string, password:string, device_id:string):Promise<{ access_token:string, refresh_token:string, expire_datetime:string }> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/authenticate`, { username, password, device_id })
        return resp.data
    },
    refreshToken : async(refresh_token:string, device_id:string):Promise<{ access_token:string, refresh_token:string, expire_datetime:string }> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/tokens/token/refresh`, { refresh_token, device_id })
        return resp.data
    },
    authenticatePhone : async(phone:string, device_id:string, referral_code?:string):Promise<void> => {
        await axios.post(`${AUTH_SVC_API}/v1/players/player/phone/authenticate`, { phone, device_id, referral_code })
    },
    verifyPhoneAuthenticate : async(token:string, device_id:string):Promise<{ access_token:string, refresh_token:string, expire_datetime:string }> => {
        let resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/phone/verify`, { token, device_id })
        return resp.data
    }, 
    getReferralByCode: async(code:string):Promise<{ player_referral?:PlayerReferralProps, promo?:PromoProps, referrer?:PublicPlayerProps }> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/promos/referral/code/${code}`)
        return resp.data
    },
    getShortToken : async():Promise<PlayerTokenProps> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/tokens/short`)
        return resp.data.player_token
    },
    getAuthStrategyById: async(auth_strategy_id:string):Promise<{ auth_strategy:AuthStrategyProps, auth_strategy_identifiers:AuthStrategyIdentifierProps[] }> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/authenticate/strategy/id/${auth_strategy_id}`)
        return resp.data
    },
    executeAuthStrategy: async(auth_execution_request:AuthExecutionRequestProps):Promise<{ result:'success'|'fail', new_player?:boolean, device_id:string, message:string, auth_strategy_id:string, verification_session_id:string, session_expire_datetime:any, auth_strategy_identifier_id:string }> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/authenticate/strategy/try`, { auth_execution_request })
        console.log(resp.data)
        return resp.data
    },
    verifyAuthStrategyIdentifier: async(auth_strategy_identifier_id:string, verification_session_id:string, token:string, device_id:string):Promise<{ result: 'success'|'fail', player_id?:string, access_token?:string, refresh_token?:string, expire_datetime?:any }> => {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/authenticate/strategy/identifier/verify`, { auth_strategy_identifier_id, verification_session_id, token, device_id });
        console.log(resp.data)
        return resp.data
    },
    getCompanyById: async(company_id:string):Promise<{ company:CompanyProps, company_members:CompanyMemberProps[] }> => {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/companies/company/id/${company_id}`)
        return resp.data
    }
}

const AuthenticateHelpers = {
/**
 * Takes a phone number string and returns a formatted verison of the phone number with - between numbers
 * @param phone 
 * @returns formatted phone string
 */
formatPhone : (phone?:string) => {
    if(!phone){ return '' }
    let splitPhone = phone.split('')
    if(splitPhone.length === 0){ return '' }
    if(splitPhone.length > 10 && splitPhone[0] == '1'){ splitPhone.splice(0,1) }
    let newPhone = ''
    splitPhone.map((c, i) => {
        newPhone += c
        if(i === 2 && splitPhone.length > 3){ newPhone += '-' }
        if(i === 5 && splitPhone.length > 6){ newPhone += '-' }
    })
    return newPhone
},
isEmailValid : (email:string):{ valid:boolean, error?:string } => {
    if(email === ''){ return { valid:false, error:'Please enter valid email' } }
    // function that returns true if value is email, false otherwise
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRex.test(email)) {
        return { valid:true };
      }
      return { valid:false, error:'Must be a valid email' };
},
/**
 * Removes - from a formatted phone string
 * @param formatted_phone 
 * @returns unformatted phone string
 */
unformatPhone : (formatted_phone:string) => {
    let splitPhone = formatted_phone.split('-')
    let newPhone = ''
    splitPhone.map(c => {
        newPhone += c
    })
    return newPhone
},
 fullUnformatPhone : (formatted_phone:string) => {
    let unformatted_phone = AuthenticateHelpers.unformatPhone(formatted_phone)
    let no_par = unformatted_phone.split('(')
    unformatted_phone = no_par.join('')
    no_par = unformatted_phone.split(')')
    unformatted_phone = no_par.join('')
    let no_plus = unformatted_phone.split('+1')
    unformatted_phone = no_plus.join('')
    let no_space = unformatted_phone.split(' ')
    unformatted_phone = no_space.join('')
    return unformatted_phone
},
 isStrategyValid : (auth_execution_request:AuthExecutionRequestProps) => {
    let request_valid = true;
    auth_execution_request.attributes.map(a => {
        if(!request_valid){ return }
        const { valid } = AuthenticateHelpers.isAttributeValid(a.attribute, a.value)
        if(!valid){ request_valid = false }
    })
    return request_valid
},
isAttributeValid : (attribute:string, value:string, optional?:boolean):{ valid:boolean, error?:string } => {
    if(optional){ return { valid:true } }
    switch(attribute){
        case 'phone':
            return AuthenticateHelpers.isPhoneValid(value)
        case 'email':
            return AuthenticateHelpers.isEmailValid(value)
        default:
            if(!value || value == ''){ return { valid:false, error: 'Attribute must not be blank' } }
            return { valid:true }
    }
},
/**
 * Checks if phone number is a vali US number
 * @param phone 
 * @returns 
 */
isPhoneValid : (phone:string):{ valid: boolean, error?:string } => {
    if(!phone || phone === '') { return { valid: false, error:'Phone number must be entered' } }
    let phoneasnum = parseInt(phone)
    if(isNaN(phoneasnum)){ return { valid:false, error:'Phone number must be a US 10 digit number' } }
    if(phone.length === 10){ return { valid:true } }
    return { valid:false, error: 'Phone number must be a US 10 digit number' }
}
}