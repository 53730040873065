import { useEffect, useState } from 'react';
import { LocationApi, LocationHelpers } from './api';
import type { LocationProps, PlayerAddressProps } from '../types';

export const usePlayerLocation = () => {
    const [ location, setLocation ] = useState<{
        loading: boolean,
        error?:string,
        coordinates:any,
        address_results:any[],
        player_address?:PlayerAddressProps,
        region?:string,
        location?:LocationProps
    }>({
        loading:false,
        coordinates: {},
        address_results:[]
    })
    const [ permission, setPermission ] = useState<{
        checked:boolean,
        permission?: 'granted'|'denied'|'prompt'
    }>({
        checked:false
    })
       
    useEffect(() => {
        LocationApi.setEnvironment();
    },[])

    const checkPermissions = async(options?: { auto_on_granted?:boolean }) => {
        try {
           const permission = await LocationApi.getPermission();
           setPermission({
            checked: true,
            permission: permission
           })
           if(permission == 'granted' && options?.auto_on_granted){
            getLocation()
           }
        } catch (e) {
            console.log('error') 
            console.log(e)  
        }
       
    }

    const getLocation = async() => {
        if(location.loading){ return } //No mashy mashy
        setLocation({ ...location, loading: true })
        try {

            let cached_loc = await LocationApi.getCachedLocation();
            if(cached_loc){
                setLocation(cached_loc)
                setPermission({ checked: true, permission: 'granted' });
            } else {
                const coordinates = await LocationApi.getPosition();
                setPermission({
                    checked:true,
                    permission: 'granted'
                })
                const addresses = await LocationApi.reverseCoordLookup(coordinates.latitude, coordinates.longitude);
                const locations = await LocationApi.getLocations();
                const generated_address = LocationHelpers.getAddressFromGeoCodeResults(addresses, locations);
    
                let new_loc = {
                    loading:false,
                    coordinates: coordinates,
                    address_results: addresses,
                    player_address: generated_address?.player_address,
                    region: generated_address?.region,
                    location: generated_address?.location
                }
                LocationApi.cache_location(new_loc)
                setLocation(new_loc)
            }
            
        } catch (e) {
            setPermission({
                checked:true,
                permission: 'denied'
            })
            setLocation({
                loading:false,
                error:'Failed to identify your location.  Please try again later',
                coordinates: {},
                address_results: []
            })
        }
    }

    return { permission, location, getLocation, checkPermissions }
}

