import React from 'react';
import type { PlayerFollowerProps, PublicPlayerProps } from "../types";
import { Image, View, TouchableOpacity, StyleSheet } from 'react-native';
import Colors from '../constants/colors';
import { Text } from '../Components';
import { view_styles } from '../constants/styles';
import PlayerFollowButton from './PlayerFollowButton';

type PlayerCardProps = {
    player:PublicPlayerProps,
    width:number,
    follow_loading?:boolean,
    alignment?:'vertical'|'horizontal',
    player_follower?:PlayerFollowerProps,
    player_following?:PlayerFollowerProps,
    show_follow?:boolean,
    style?:any,
    float?:boolean,
    onSelectPlayer:(p:PublicPlayerProps) => void,
    onFollowingUpdate:(pf:PlayerFollowerProps) => void
}

const PlayerCard = ({ player, width, follow_loading, alignment, style, float, show_follow, player_follower, player_following, onSelectPlayer, onFollowingUpdate }:PlayerCardProps) => {

    let player_style = alignment == 'vertical' ? local_styles.player_vertical : local_styles.player_horizontal;
    let container_style = { ...local_styles.container }
    if(float){ container_style = { ...container_style, ...view_styles.float } }
    return (
        <View style={{ ...container_style, ...style, width }}>
            <TouchableOpacity style={player_style} onPress={() => onSelectPlayer(player)}>
                {alignment == 'vertical' ? 
                <View>
                    <Image
                        source={{ uri: player.profile_pic }}
                        style={{ height:width * 0.5, width:width, borderTopRightRadius:8, borderTopLeftRadius:8 }}
                        resizeMode='cover'
                    />
                </View>
                :
                <View style={{  }}>
                    <Image
                        source={{ uri: player.profile_pic }}
                        style={{ height:55, width:55, borderTopLeftRadius:8, borderBottomLeftRadius:8}}
                        resizeMode='cover'
                    />
                </View>
                }
                <View style={{ flex:1, padding:10 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='bold' textAlign={alignment == 'vertical' ? 'center' : 'left'}>{player.first_name} {player.last_name}</Text>
                    <Text style={{ marginTop:4 }} size={14} color={Colors.brand.midnight} weight='regular' textAlign={alignment == 'vertical' ? 'center' : 'left'}>@{player.username}</Text>
                </View>
                {show_follow ?
                <View style={{ padding:10, paddingTop:alignment=='vertical'?0:10 }}>
                    <PlayerFollowButton
                        loading={follow_loading}
                        player_id={player.player_id}
                        follower={player_follower}
                        following={player_following}
                        onFollowingUpdate={onFollowingUpdate}
                    />
                </View>
                :<></>}
            </TouchableOpacity>
        </View>
    )
}

const local_styles = StyleSheet.create({
    container: {
        flex:1,
        backgroundColor:Colors.shades.white,
        borderRadius:8
    },
    player_vertical: {
        flex:1,
        flexDirection:'column',
        //alignItems:'center'
    },
    player_horizontal: {
        flex:1,
        flexDirection: 'row',
        alignItems:'center'
    }
})

export default PlayerCard