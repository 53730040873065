import React, { useState } from 'react';
import { TouchableOpacity, View, Linking } from "react-native"
import { Button, Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import { WalletApi } from '../api';
import type { AccountProps, PlayerAddressProps } from '../../types';
import SpringView from '../../Components/Spring';
import AddressManager from './AddressManager';
import AddressCard from './AddressCard';

type LinkAccountManagerProps = {
    ibt_placeholer_account:AccountProps,
    player_addresses:PlayerAddressProps[],
    onCancel: () => void
    onComplete: (account:AccountProps) => void
}

const LinkAccountManager = ({ player_addresses, ibt_placeholer_account, onCancel, onComplete }: LinkAccountManagerProps) => {
    const [ loading, setLoading ] = useState(false);
    const [ try_counter, setTryCounter ] = useState(0);
    const [ show_addresses, setShowAddresses ] = useState<{
        visible:boolean,
        selected_address?:PlayerAddressProps
    }>({
        visible: false
    });
    const [ linking, setLinking ] = useState(false);
    const [ account, setAccount ] = useState<AccountProps>();
    const [ error, setError ] = useState<string>();
    const [ redirect_url, setRedirectUrl ] = useState<string>();

    
    const handleStartLink = async() => {
        if(loading || !show_addresses.selected_address){ return }//Prevent button mashing
        setLoading(true);
        Linking.addEventListener('url', handler)
        const a = await WalletApi.linkACHAccount(ibt_placeholer_account.account_id, show_addresses.selected_address.player_address_id)
        setAccount(a);
        if(a.status != 'redirect' || !a.redirect_url){ setError('Unable to process') }
        else { setRedirectUrl(a.redirect_url) }
        setLoading(false);
    }

    const handler = (ev:any) => {
        console.log(ev)
    }

    const tryGetAccount = async() => {
        if(!account){ return }
        if(try_counter > 60){ return } //Stop trying after 75 attempts
        const a = await WalletApi.getAccountById(account.account_id)
        setTryCounter(try_counter + 1)
        setAccount(a);
        if(a.status == 'redirect'){
            setTimeout(() => {
                console.log('trying again!')
                tryGetAccount()
            }, 4000);
        } else {
            setTryCounter(0);
            onComplete(a)
        }
    }

    const handleNavOut = async() => {
        if(!redirect_url){ return }
        setLinking(true);
        Linking.openURL(redirect_url)
        setTimeout(() => {
            tryGetAccount()
        }, 18000);
    }

    const valid = show_addresses.selected_address ? true : false

    return (
        <View style={{ flex:1 }}>
            <View style={{ padding:20 }}>
                <Text size={26} color={Colors.brand.midnight} weight='bold'>LINK NEW ACCOUNT</Text>
                <Text style={{ marginTop:10 }} size={16} color={Colors.brand.midnight} weight='regular'>Link an account via instant bank transfer and get access to instant deposits and withdraws.</Text>
            </View>
            <View style={{ flex:1, justifyContent:'center', alignItems:'center' }}>
                <Icons.IBTFLowIcon size={350} />
                <View style={{backgroundColor:Colors.shades.shade100, padding:20, marginTop:20}}>
                    <Text size={16} color={Colors.brand.cobalt} weight='light' textAlign='center' style={{marginBottom:2}}>Using <TouchableOpacity onPress={() => window.open('https://plaid.com/safety', '_blank')}><Text weight='bold' color={Colors.brand.electric} size={18}>Plaid</Text></TouchableOpacity>, you will be asked to securly login to your bank and select the account you wish to link and transact from.</Text>
                    {show_addresses.selected_address ?
                    <View style={{ padding:20 }}>
                        <Text style={{ marginBottom:10 }} textAlign='center' weight='bold' size={16} color={Colors.brand.midnight}>Billing Address</Text>
                        <AddressCard
                            address={show_addresses.selected_address}
                            hide_delete
                            onDelete={() => console.log('')}
                            onSelect={() => setShowAddresses({ ...show_addresses, visible:true })}
                        />  
                    </View>

                    :<></>}
                    </View>
            </View>
            
            {error?
            <Text size={14} color={Colors.utility.warning}>{error}</Text>
            :<></>}
            {show_addresses.selected_address && (!account || account.status == 'redirect') ?
            <View nativeID='link_actions' style={{ flexDirection:'row', padding:20  }}>
                <Button
                    title='CANCEL'
                    style={{ flex:1, marginRight:5 }}
                    title_color={Colors.brand.electric}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    onPress={() => onCancel()}
                />
                {!redirect_url ?
                <Button
                    style={{ flex:3, opacity:loading || !valid?0.5:1 }}
                    disabled={loading || !valid}
                    title='START ACCOUNT LINK'
                    loading={loading}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.brand.electric}
                    padding={15}
                    onPress={() => handleStartLink()}
                />
                :
                <Button
                    style={{ flex:3, opacity:linking?0.5:1 }}
                    disabled={linking}
                    title='FINISH WITH PLAID'
                    loading={linking}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    padding={15}
                    onPress={() => handleNavOut()}
                />
                }
            </View>
            :!show_addresses.selected_address ?
            <View style={{ marginTop:10, borderRadius:8, backgroundColor:Colors.shades.shade600, padding:20 }}>
                <Text size={14} color={Colors.brand.midnight} textAlign='center'>Before we continue, we will need get the billing address associated with the account to be linked</Text>
                <Button
                    style={{ marginTop:10 }}
                    title={show_addresses.selected_address ?'CHANGE':'ADD BILLING ADDRESS'}
                    title_color={Colors.shades.white}
                    padding={15}
                    backgroundColor={Colors.brand.electric}
                    onPress={() => setShowAddresses({ ...show_addresses, visible:true })}
                />
            </View>
            :<></>}
            {show_addresses.visible ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'flex-end' }}>
                <SpringView 
                    slide='vertical'
                    from={500}
                    to={0}
                    style={{ borderTopRightRadius:40, borderTopLeftRadius:40, backgroundColor:Colors.shades.white }}>
                    <AddressManager
                        player_addresses={player_addresses}
                        onSelect={(addy) => setShowAddresses({ visible:false, selected_address:addy })}
                        onAddAddress={(addy) => setShowAddresses({ visible:false, selected_address:addy })}
                        onCancel={() => setShowAddresses({ ...show_addresses, visible:false })}
                    />
                </SpringView>
            </View>
            :<></>}
            {account && account.status != 'redirect' ?
            <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'flex-end' }}>
                <SpringView 
                    slide='vertical'
                    from={500}
                    to={0}
                    style={{ borderTopRightRadius:40, borderTopLeftRadius:40, backgroundColor:Colors.shades.white }}>
                    {account.status == 'active' ?
                    <View style={{ flex:1, padding:10, paddingTop:30, paddingBottom:30, justifyContent:'center', alignItems:'center' }}>
                        <Icons.TransactionSuccessIcon  size={150} />
                        <View style={{ padding:20, borderRadius:8, borderWidth:1, borderColor:Colors.utility.success, marginTop:20 }}>
                            <Text size={20} color={Colors.utility.success} weight='bold' textAlign='center'>Account Linked Successfully</Text>
                            <Text style={{ marginTop:10 }} size={16} color={Colors.brand.cobalt} weight='regular' textAlign='center'>Your account has successfully been added. Please select close below to continue.</Text>
                        </View>
                    </View>
                    : 
                    <View style={{ flex:1, padding:10, paddingTop:30, paddingBottom:30, justifyContent:'center', alignItems:'center' }}>
                        <Icons.TransactionErrorIcon  size={150} />
                        <View style={{ padding:20, borderRadius:8, borderWidth:1, borderColor:Colors.utility.error, marginTop:20 }}>
                            <Text size={20} color={Colors.utility.error} weight='bold' textAlign='center'>Failed to Link Account</Text>
                            <Text style={{ marginTop:10 }} size={16} color={Colors.brand.cobalt} weight='regular' textAlign='center'>Sorry, we were unable to link your account at this time.  Please try again or reach out to support for help.</Text>
                        </View>
                    </View>
                    }
                    <View nativeID='action_row' style={{ flexDirection:'row', padding:20 }}>
                        <Button
                            style={{ flex:1, opacity:1 }}
                            title='CLOSE'
                            //disabled={loading}
                            title_color={Colors.utility.error}
                            borderWidth={1}
                            borderColor={Colors.utility.error}
                            padding={15}
                            onPress={() => {
                                if(account.status == 'active'){ return onComplete(account) }
                                return onCancel()
                            }}
                        />
                    </View>
                </SpringView>
            </View>
            :<></>}
        </View>
    )
}

export default LinkAccountManager