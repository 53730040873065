import React from 'react';
import { Text as RNText } from 'react-native';
import '../Font.css';
import Colors from '../constants/colors';


export interface TextProps { 
    weight?:'bold'|'semibold'|'regular'|'light', 
    color?:string, 
    size?: number, 
    children:any, 
    textAlign?:'left'|'center'|'right', 
    style?:any 
}

const Text = ({ weight, color, size, children, textAlign, style }: TextProps) => {

    const getFontFamily = (weight:string) => {
        switch(weight){
            case 'bold': return 'barlow-bold'
            case 'semibold': return 'barlow-semibold'
            case 'regular': return 'barlow-regular'
            case 'light': return 'barlow-light'
            default: return 'barlow-regular'
        }
    }

    return (
        <RNText style={[{ color:color??Colors.shades.black, fontSize: size??14, fontFamily: weight?getFontFamily(weight):'barlow-regular', textAlign:textAlign??'left' }, style]}>
            {children}
        </RNText>
    )
}

export default Text