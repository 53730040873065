import React, { useState } from 'react';
import { View, ScrollView } from 'react-native';
import { Button, Text, TextInput } from '../../Components';
import Colors from '../../constants/colors';
import { WalletApi, WalletHelpers } from '../api';
import moment from 'moment-mini';
import type { AccountProps } from '../../types';

type VerifyACHWidgetProps = {
    account:AccountProps,
    onUpdateAccount:(account:AccountProps) => void,
    onClose: () => void,
}

const VerifyACHAccount = ({ account, onClose, onUpdateAccount }:VerifyACHWidgetProps) => {
    const [ loading, setLoading ] = useState(false);
    const [ verify_amount_1, setVerify1Amount ] = useState('')
    const [ verify_amount_2, setVerify2Amount ] = useState('')
    const [ error, setError ] = useState<string>();


    let next_allowed = moment().subtract(1, 'minute')
    if(account?.last_verify_attempt){
        next_allowed = moment(account.last_verify_attempt).add(10, 'minutes');
    }

    const timedout = moment().isBefore(next_allowed)
    let verify_allowed = verify_amount_1 && verify_amount_2 && !timedout ? true : false
    

    /**
     * Try to verify the account
     * @returns void
     */
    const handleVerify = async() => {
        if(verify_amount_1 == '0' || verify_amount_2 == '0'){ return alert('Please enter verification amounts') }
        if(account.status != 'verify'){ return } //Dont do anything
        if(!verify_allowed){ return }
        if(!WalletHelpers.isNumber(verify_amount_1)){ return alert('Amount 1 is not a valid number') }
        if(!WalletHelpers.isNumber(verify_amount_2)){ return alert('Amount 2 is not a valid number') }
        let amount_1 = parseFloat(verify_amount_1)
        let amount_2 = parseFloat(verify_amount_2)
        setLoading(true);
        const resp = await WalletApi.verifyACHAccount(account.account_id, amount_1, amount_2);
        if(resp.status == 'fail'){ 
            setError('Entered amounts are invalid') 
        }
        onUpdateAccount(resp.account)
        setLoading(false);

    }


    if(!account){ return <></> }

    return (
        <View style={{ flex:1 }}>
            <View style={{ padding:20 }}>
                <Text size={26} color={Colors.brand.midnight} weight='bold'>VERIFY ACCOUNT</Text>
                <Text style={{ marginTop:10 }} size={16} color={Colors.brand.midnight} weight='regular'>Verify this account by entering the two deposit amounts received from Bettoredge when setting up the account.</Text>
            </View>
            <ScrollView nativeID='verify_inputs' style={{ flex:1, margin:20 }}>
                <View style={{marginTop:30}}>
                    <Text textAlign='center' size={16} color={Colors.brand.midnight} weight='semibold'>Deposit 1</Text>
                    <TextInput 
                        style={{ marginTop:5 }}
                        value={verify_amount_1}
                        editable={account.status == 'verify' ? true : false}
                        placeholderTextColor={Colors.brand.slate}
                        keyboardType='decimal-pad' 
                        placeholder='0.00' 
                        onChangeText={(e) => setVerify1Amount(e)} 
                    />
                </View>
                <View style={{marginTop:20}}>
                    <Text textAlign='center' size={16} color={Colors.brand.midnight} weight='semibold'>Deposit 2</Text>
                    <TextInput 
                        style={{ marginTop:5 }}
                        editable={account.status == 'verify' ? true : false}
                        value={verify_amount_2}
                        placeholderTextColor={Colors.brand.slate}
                        keyboardType='decimal-pad' 
                        placeholder='0.00' 
                        onChangeText={(e) => setVerify2Amount(e)} 
                    />
                </View>
                {error ?
                <Text style={{ marginTop:10 }} textAlign='center' size={14} color={Colors.utility.warning} weight='bold'>{error}</Text>
                :<></>}

            </ScrollView>
            {account.status == 'verify' ?
            <View nativeID='verify_actions' style={{ flexDirection:'row', padding:20 }}>
                <Button
                    style={{ flex:1, marginRight:5 }}
                    title='CANCEL'
                    title_color={Colors.brand.electric}
                    padding={15}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    onPress={() => onClose()}
                />
                <Button
                    style={{ flex:3, opacity:verify_allowed?1:0.5 }}
                    disabled={!verify_allowed}
                    loading={loading}
                    title={timedout?`Try Again ${moment(next_allowed).fromNow()}`:'VERIFY ACCOUNT'}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    padding={15}
                    onPress={() => handleVerify()}
                />
            </View>
            :
            <View nativeID='verify_actions' style={{ flexDirection:'row', padding:20 }}>
                <Button
                    style={{ flex:1, marginRight:5 }}
                    title='CLOSE'
                    title_color={Colors.brand.electric}
                    padding={15}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    onPress={() => onClose()}
                />
                <Button
                    style={{ flex:3, opacity:0.5 }}
                    title='ACCOUNT VERIFIED!'
                    title_color={Colors.shades.white}
                    title_weight='bold'
                    padding={15}
                    backgroundColor={Colors.utility.success}
                    onPress={() => onClose()}
                />
            </View>
            }
        </View>
    )
}

export default VerifyACHAccount