import React, { useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Icons, Text } from '../../Components';
import Colors from '../../constants/colors';
import moment from 'moment-mini';
import { ItemOrderHelpers } from '../api';
import type { ItemOrderProps } from '../../types';
import ItemOrderDetails from './ItemOrderDetailCard';

type ItemOrderCardProps = {
    item_order:ItemOrderProps
}
const ItemOrderCard = ({ item_order }:ItemOrderCardProps) => {
    const [ expanded, setExpanded ] = useState(false);

    const status_label = ItemOrderHelpers.getStatusLabel(item_order);

    return (
        <View style={{ backgroundColor:expanded?Colors.shades.shade600:undefined, borderRadius:8 }}>
            <TouchableOpacity style={{ flex:1, flexDirection:'row', alignItems:'center', padding:10, borderBottomWidth:1, borderBottomColor:Colors.shades.shade100 }} onPress={() => setExpanded(!expanded)}>
                <View nativeID='item_order_info' style={{ flex:3, borderRightWidth:1, borderColor:Colors.shades.shade600 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='semibold'>{item_order.description}</Text>
                    <Text style={{ marginTop:4 }} size={12} color={Colors.brand.midnight} weight='regular'>Created at {moment(item_order.create_datetime).format('YYYY-MM-DD hh:mm a')}</Text>
                </View>
                <View nativeID='item_order_amount' style={{ flex:1, padding:5 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='bold' textAlign='center'>${item_order.total_amount.toFixed(2)}</Text>
                    <View style={{ marginTop:3, padding:3, paddingRight:10, paddingLeft:10, backgroundColor:status_label.color, borderRadius:8}}>
                        <Text color={Colors.shades.white} weight='regular' textAlign='center'>{status_label.text}</Text>
                    </View>
                </View>
                <View style={{ padding:10 }}>
                    <Icons.ChevronIcon direction={expanded?'up':'down'} size={10} color={Colors.brand.midnight}/>
                </View>
            </TouchableOpacity>
            {expanded ?
            <View style={{ padding:20 }}>
                <ItemOrderDetails
                    item_order={item_order}
                />
            </View>
            :<></>}
        </View>
    )
}

export default ItemOrderCard