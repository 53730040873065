
import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator, TouchableOpacity } from 'react-native';
import { Button, Icons, Text, TextInput } from '../../Components';
import Colors from '../../constants/colors';
import type { MyPlayerProps } from '../api/types';
import { button_styles, view_styles } from '../../constants/styles';
import { ProfileApi, ProfileHelpers } from '../api';


type EmailManagerProps = {
    player:MyPlayerProps,
    walkthrough?:boolean,
    onClose:() => void,
    onNext:() => void,
    onPlayerUpdate:(player:MyPlayerProps) => void
}

const EmailManager = ({ player, walkthrough, onPlayerUpdate, onNext, onClose }:EmailManagerProps) => {
    const [ loading, setLoading ] = useState(false);
    const [ verify_token, setVerifyToken ] = useState({
        loading:false,
        visible: false,
        token: ''
    })
    const [ draft_player, setDraftPlayer ] = useState<MyPlayerProps>(player)
    const [ update_error, setUpdateError ] = useState<string>();
    const [ verify_error, setVerifyError ] = useState<string>();

    useEffect(() => {
        setDraftPlayer(player);
    },[player])

    const { valid, error } = ProfileHelpers.isEmailValid(draft_player.email)

    const is_changed = draft_player.email?.toLowerCase() != player.email?.toLowerCase() ? true : false
    const token_valid = verify_token.token != '' ? true : false
    const update_allowed = valid && is_changed ? true : false
    
    const handleUpdatePlayer = async() => {
        try {
            setLoading(true);
            await ProfileApi.updatePlayerEmail(draft_player.email);
            setUpdateError(undefined)
            setTimeout(() => {
                setLoading(false);
                setVerifyToken({
                    loading:false,
                    visible:true,
                    token: ''
                })
            }, 1000);
        } catch (e) {
            setLoading(false);
            setUpdateError('Unable to use this email.  Please try another.')
        }
    }

    const handleNext = async() => {
        if(valid && !update_allowed){ return onNext() }
        if(!update_allowed){ return }
        await handleUpdatePlayer()
    }

    const handleVerifyEmail = async() => {
        try {
            setVerifyToken({ ...verify_token, loading:true });
            const player = await ProfileApi.confirmPlayerEmail(verify_token.token);
            setTimeout(() => {
                setLoading(false);
                setVerifyToken({
                    loading:false,
                    visible:false,
                    token: ''
                })
                if(walkthrough){ onNext() } else { onClose() }
            }, 1000);
            onPlayerUpdate(player)
        } catch (e) {
            setVerifyToken({ ...verify_token, loading:false });
            setVerifyError('Invalid Token.  Please try again')
        }
    }

    const handleCancelUpdate = () => {
        setVerifyToken({ visible:false, loading:false, token: '' })
        setDraftPlayer(player)
    }

    return (
        <View style={{ flex:1 }}>
            <View style={{ flex:1 }}>
                <View style={{ padding:20 }}>
                    <Text size={30} color={Colors.brand.midnight} weight='bold'>Confirm Your Email</Text>
                    <Text style={{ marginTop:15 }} size={16} color={Colors.brand.midnight} weight='regular'>Confirming your email will help secure your account and is required for real-money activities</Text>
                </View>
                <View style={{ padding:30 }}>
                    <View style={{ backgroundColor:Colors.shades.white, borderRadius:22, flexDirection:'row', alignItems:'center' }}>
                        <View style={{ paddingLeft:15, paddingRight:15 }}>
                            <Icons.EmailIcon
                                color={Colors.brand.midnight}
                                size={18}
                            />
                        </View>
                        <TextInput
                            value={draft_player.email}
                            keyboardType='email-address'
                            style={{ flex:1, borderRadius:0 }}
                            onChangeText={(text) => {
                                if(update_error){ setUpdateError(undefined) }
                                setDraftPlayer({ ...draft_player, email: text })
                            }}
                        />
                        <View style={{ paddingLeft:15, paddingRight:15 }}>
                            {loading ?
                            <ActivityIndicator size='small' color={Colors.brand.midnight}/>
                            :
                            <View>
                                { valid ?
                                <Icons.CheckIcon size={16} color={Colors.utility.success} />
                                : verify_token.visible ?
                                <Icons.AlertIcon size={16} color={Colors.utility.warning} />
                                :
                                <Icons.CloseIcon size={14} color={Colors.utility.error} />
                                }
                            </View>
                            }
                        </View>
                    </View>
                    {error ?
                    <Text style={{ marginTop:10 }} size={14} color={Colors.utility.warning} weight='semibold' textAlign='center'>{error}</Text>
                    :<></>}
                    {update_error ?
                    <Text style={{ marginTop:10 }} size={14} color={Colors.utility.warning} weight='semibold' textAlign='center'>{update_error}</Text>
                    :<></>}
                </View>
            </View>
            {walkthrough ?
            <View nativeID='action_row' style={{ flexDirection:'row', justifyContent:'space-between', padding:10 }}>
                <Button
                    title='EXIT SETUP'
                    title_color={Colors.utility.error}
                    backgroundColor='transparent'
                    onPress={() => onClose()}
                />
                <TouchableOpacity 
                    style={valid && !loading ? button_styles.wizard_valid : button_styles.wizard_invalid}
                    disabled={!valid || loading}
                    onPress={() => handleNext()}
                    >
                    <Icons.ChevronIcon size={20} direction='right' color={Colors.brand.midnight}/>
                </TouchableOpacity>
            </View>
            :
            <View nativeID='action_row' style={{ flexDirection:'row', justifyContent:'space-between', padding:10 }}>
                <Button
                    style={{ flex:1, marginRight:5 }}
                    title='BACK'
                    padding={15}
                    title_color={Colors.brand.electric}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    onPress={() => onClose()}
                />
                <Button
                    style={{ flex:3, opacity:valid && !loading && update_allowed ? 1:0.5 }}
                    disabled={!valid || loading || !update_allowed}
                    loading={loading}
                    title='UPDATE'
                    padding={15}
                    title_color={Colors.shades.white}
                    backgroundColor={Colors.utility.success}
                    onPress={async() => {
                        await handleUpdatePlayer()
                    }}
                />
            </View>
            }
            {verify_token.visible ?
                <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'center', alignItems:'center' }}>
                    <View style={{ padding:20, backgroundColor:Colors.shades.white, borderRadius:8, ...view_styles.float }}>
                        <View style={{ padding:10 }}>
                            <Text size={14} weight='semibold' color={Colors.brand.midnight} textAlign='center'>Enter Code Sent To</Text>
                            <Text size={18} style={{ marginTop:10 }} weight='bold' color={Colors.brand.midnight} textAlign='center'>{draft_player.email}</Text>
                        </View>
                        <View style={{ backgroundColor:Colors.shades.white, borderRadius:22, flexDirection:'row', alignItems:'center' }}>
                            
                            <TextInput
                                value={verify_token.token}
                                textContentType='oneTimeCode'
                                style={{ flex:1}}
                                placeholder='00000'
                                placeholderTextColor={Colors.brand.slate}
                                onChangeText={(text) => setVerifyToken({ ...verify_token, token: text })}
                            />
                        </View>
                        {verify_error ?
                            <Text size={14} color={Colors.utility.warning}>{error}</Text>
                        :<></>}
                        <Button
                            title='CONFIRM'
                            style={{ marginTop:20, opacity:token_valid && !loading ? 1 : 0.5 }}
                            disabled={!token_valid || loading}
                            padding={15}
                            loading={verify_token.loading}
                            title_color={Colors.shades.white}
                            backgroundColor={Colors.utility.success}
                            onPress={() => handleVerifyEmail()}
                        />
                        <Button
                            title='CANCEL'
                            style={{ marginTop:10 }}
                            disabled={loading}
                            padding={15}
                            loading={verify_token.loading}
                            title_color={Colors.brand.electric}
                            borderWidth={1}
                            borderColor={Colors.brand.electric}
                            onPress={() => handleCancelUpdate()}
                        />
                    </View>
                </View>
                :<></>}
        </View>
    )
}

export default EmailManager