import React from 'react';
import { Image, View } from "react-native";
import type { ItemProps } from "../../types";
import { Text } from '../../Components';
import Colors from '../../constants/colors';

type ItemSummaryCardProps = {
    items:ItemProps[]
}
const ItemSummaryCard = ({ items }:ItemSummaryCardProps) => {

    const default_item = items[0]

    if(!default_item){ return <></> }
    return (
        <View>
            <View style={{ flexDirection:'row', alignItems:'center' }}>
                <View style={{ padding:5, marginRight:10 }}>
                    <Image
                        source={{ uri: default_item.images.find(i => i.default)?.url }}
                        style={{ height:50, width:50 }}
                        resizeMode='cover'
                    />
                </View>
                <View style={{ flex:1 }}>
                    <Text size={16} color={Colors.brand.midnight} weight='bold'>{default_item.name}</Text>
                    <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight} weight='regular'>{default_item.description}</Text>
                </View>
            </View>
        </View>
    )
}

export default ItemSummaryCard