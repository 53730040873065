import React, { useEffect, useState } from 'react';
import { FlatList, View, ActivityIndicator, ScrollView } from 'react-native';
import { ItemOrderApi } from '../api';
import type { ItemOrderProps, ItemProps } from '../../types';
import ItemOrderCard from './ItemOrderCard';
import { Button, Text } from '../../Components';
import Colors from '../../constants/colors';

type OrderManagerProps = {
    onClose: () => void
}

const OrderManager = ({ onClose }:OrderManagerProps) => {
    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState<{
        offset: number,
        items:ItemProps[],
        item_orders:ItemOrderProps[]
    }>({
        offset:0,
        items:[],
        item_orders:[]
    })

    const { item_orders, offset } = data;

    useEffect(() => {
        getDataFromServer(0)
    },[])

    const getDataFromServer = async(offset:number) => {
        setLoading(true);
        const my_orders = await ItemOrderApi.getMyItemOrders(offset);
        setData({
            items:my_orders.items,
            item_orders: my_orders.item_orders,
            offset
        })
        setLoading(false)
    }

    const renderItemOrders = (data: { item:ItemOrderProps, index:number }) => {
        return (
            <ItemOrderCard
                item_order={data.item}
            />
        )
    }


    return (
        <View style={{ flex:1 }}>
           <View style={{ padding:20 }}>
                <Text size={26} color={Colors.brand.midnight} weight='bold'>ORDER HISTORY</Text>
                <Text style={{ marginTop:10 }} size={16} color={Colors.brand.midnight} weight='regular'>Review your order history below.  Select an item to see the transaction details.</Text>
            </View>
            <ScrollView style={{ flex:1, padding:10 }}>
                {loading ?
                <ActivityIndicator size='large' color={Colors.brand.midnight} />
                :<></>}
                <FlatList
                    data={item_orders}
                    renderItem={renderItemOrders}
                    keyExtractor={(item) => item.item_order_id.toString()}
                />
            </ScrollView>
            <View style={{ flexDirection:'row', alignItems:'center', padding:20, paddingBottom:0 }}>
                <Button
                    style={{ opacity: offset > 0 ? 1 : 0.5 }}
                    title='PREV'
                    disabled={offset == 0 ? true : false}
                    title_color={Colors.brand.electric}
                    backgroundColor='transparent'
                    onPress={() => getDataFromServer(offset - 1)}
                />
                <View style={{ flex:1 }} />
                <Button
                    title='NEXT'
                    title_color={Colors.brand.electric}
                    backgroundColor='transparent'
                    onPress={() => getDataFromServer(offset + 1)}
                />
            </View>
            <View style={{ padding:20 }}>
                <Button
                    title='BACK'
                    title_color={Colors.brand.electric}
                    borderWidth={1}
                    borderColor={Colors.brand.electric}
                    padding={15}
                    onPress={() => onClose()}
                />
            </View>
        </View>
    )
}

export default OrderManager