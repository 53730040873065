import React from 'react';
import { ActivityIndicator, Image, TouchableOpacity, View } from "react-native"
import { Text } from '.';
import Colors from '../constants/colors';


type ButtonProps = {
    borderRadiusOverride?: { borderTopRightRadius:number, borderBottomRightRadius:number, borderTopLeftRadius:number, borderBottomLeftRadius:number }
    title?:string, 
    title_weight?:'bold'|'semibold'|'regular'|'light',
    avatar_url?:string, 
    icon_size?:number, 
    icon_side?:'left'|'right', 
    icon_name?:'chevron'|'list'|'trophy', 
    loading?:boolean,
    icon_direction?:'up'|'down'|'right'|'left'
    borderRadius?:number, 
    padding?:number,
    title_color?:string, 
    disabled?:boolean,
    title_size?:number,
    style?:any,
    backgroundColor?:string, 
    borderWidth?:number, 
    borderColor?:string, 
    onPress:any 
}


const Button = ({ title, style, title_weight, disabled, loading, title_size, padding, borderRadiusOverride, avatar_url, title_color, borderWidth, borderColor, backgroundColor, borderRadius, onPress }:ButtonProps) => {
    let input_style = style ?? {}
    const getBorderRadiusOverride = () => {
        if(!borderRadiusOverride){ return { borderRadius: borderRadius??22 } }
        return { ...borderRadiusOverride }
    }

    return (
        <TouchableOpacity 
            disabled={disabled}
            style={{ ...getBorderRadiusOverride(), flexDirection:'row', alignItems:'center', padding:padding??8, backgroundColor:backgroundColor??Colors.shades.white, borderWidth:borderWidth??0, borderColor:borderColor??'transparent', ...input_style }} onPress={(ev) => onPress(ev)}>
            {avatar_url ?
            <Image 
                source={{ uri: avatar_url }} 
                style={{ height:28, width:28, borderRadius:100, marginRight:12 }} 
                resizeMode='cover'
            />
            :<></>}
            
            {title ?
            <View style={{ flex:1, marginLeft:5, marginRight:5 }}>
                <Text size={title_size??14} weight={title_weight ?? 'bold'} textAlign="center" color={title_color??Colors.brand.midnight}>{title}</Text>
                {loading ?
                <View style={{ position:'absolute', top:0, bottom:0, left:0, right:0 }}>
                    <ActivityIndicator style={{ alignSelf:'center' }} size='small' color={title_color??Colors.brand.midnight} />
                </View>
                :<></>}
            </View>
            :<></>}

        </TouchableOpacity>
    )
}

export default Button