import axios from "axios";
import { APIOverrides } from "../../ApiOverrides";
import type { PlayerFollowerProps } from "../../types";

let SOCIAL_SVC_API = '';

export { SocialComponentApi }

const SocialComponentApi = {
    setEnvironment: () => {
        const endpoints = APIOverrides.getEndpoints();
        SOCIAL_SVC_API = endpoints['SOCIAL_SVC_API'] as string;

    },
    getBulkPlayerfollowers: async(player_ids:string[]) => {
        const resp = await axios.post(`${SOCIAL_SVC_API}/v1/followers/bulk/get`, { player_ids })
        return resp.data.player_followers
    },
    savePlayerFollower: async(player_follower:PlayerFollowerProps):Promise<PlayerFollowerProps> => {
        const resp = await axios.post(`${SOCIAL_SVC_API}/v1/followers/player/create`, { player_follower })
        return resp.data.player_follower
    }
}